import styled from 'styled-components'

export const Container = styled.div`
  display: inline-flex;

  &:first-child {
    margin-left: 10px;
  }

  &:nth-child(2) {
    margin-left: 0px;
  }
`
export const Span = styled.span`
  border: 1px solid #fd4646;
  box-sizing: border-box;
  border-radius: 4px;
  padding: 7px;

  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  color: #fd4646;
`
