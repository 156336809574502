import styled from 'styled-components'
import { Column as Col } from 'components'

export const Column = styled(Col)`
  padding-top: 120px;

  @media (max-width: 500px) {
    padding-top: 160px;
  }
`
