import client from '../../providers/fetchClient'

export const getGuarantors = id => client.get(`/v3/guarantors?campaign_id=${id}`)

export const getGuarantorsById = id => client.get(`/v3/guarantors/${id}`)

export const createGuarantors = data => client.post('/v3/guarantors', data)

export const updateGuarantors = (id, data) => client.put(`/v3/guarantors/${id}`, data)

export const deleteGuarantors = id => client.delete(`/v3/guarantors/${id}`)
