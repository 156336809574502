import client from '../../providers/fetchClient'

export const getMyInvestments = id => client.get(`/v3/my-investments?investor_id=${id}`)

export const getMyInvestmentsCSV = (id, configs) =>
  client.get(`/v3/my-investments?export_as_csv=true&investor_id=${id}`, configs)

export const getMyInvestmentsShow = (campaignId, investorId) =>
  client.get(`/v3/my-investments/${campaignId}?investor_id=${investorId}`)

export const getMyInvestmentsShowCSV = (campaignId, investorId, configs) =>
  client.get(`/v3/my-investments/${campaignId}?export_as_csv=true&investor_id=${investorId}`, configs)
