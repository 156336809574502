const checkRole = (currentUser, role) => {
  return currentUser.roles && currentUser.roles.some(({ name }) => name === role)
}

export const checkIsAdmin = currentUser => {
  return checkRole(currentUser, 'Administrator')
}

export const checkIsSigner = currentUser => {
  return checkRole(currentUser, 'Signer')
}

export const checkIsPartner = currentUser => {
  return checkRole(currentUser, 'Partner')
}
