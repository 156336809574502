import styled from 'styled-components'

export const Container = styled.main`
  padding: 20px;
  width: 100%;
  max-width: 1800px;

  @media only screen and (max-width: 730px) {
    padding: 16px;
  }
`
