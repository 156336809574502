import styled from 'styled-components'
import { palette, spacing, typography, borders, flexbox, shadows, sizing } from '@material-ui/system'

import { IconAttachFileIcon } from 'components/icons'
import { Row, Button } from 'components'

export const StyledRow = styled(Row)`
  color: ${({ theme }) => theme.palette.secondary[700]};
  align-items: center;
  justify-content: space-between;
  height: 40px;
  ${palette};
  ${spacing};
  ${typography};
  ${borders};
  ${flexbox};
  ${shadows};
  ${sizing};
`

export const StyledButton = styled(Button)`
  &&.MuiButton-root {
    text-transform: capitalize;
  }
  &&.MuiButton-textPrimary {
    color: ${props => props.buttoncolor};
  }
  &&.MuiButton-text {
    padding: 0px;
  }
  width: 1px;
`
export const AcceptFile = styled.span`
  color: ${({ theme }) => theme.palette.secondary[700]};
  max-width: 15ch;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`

export const AttachFileIcon = styled(IconAttachFileIcon)`
  transform: rotate(45deg);
  margin-right: 5px;
`

export const ErrorFile = styled.span`
  color: ${({ theme }) => theme.palette.error.main};
  display: flex;
  flex-direction: Row;
  justify-content: space-between;
  ${spacing};
  ${sizing};
  height: 40px;
`

export const ErrorMessage = styled.p`
  font-size: 10px;
  line-height: 14px;
`

export const FileWithError = styled.span`
  max-width: 25ch;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`

export const Container = styled.div`
  display: flex;
  justify-content: center;
`
