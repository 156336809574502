import React, { useMemo, useCallback, Fragment } from 'react'
import PropTypes from 'prop-types'

import { IconChevronLeft, IconChevronRight } from 'components/icons'

import { WrapperPagination, PaginationButton, Control } from './styles'

const Pagination = ({ pagination, search, isLoading, maxPageNumber }) => {
  const getLowerValue = (a, b) => (a > b ? b : a)

  const currentPage = useMemo(() => pagination.offset / pagination.limit + 1, [pagination.limit, pagination.offset])

  const numberOfPages = useMemo(
    () => getLowerValue(maxPageNumber, pagination.pageCount),
    [maxPageNumber, pagination.pageCount]
  )

  const parsePage = (command, page = 1) => {
    switch (command) {
      case 'next':
        return parseInt(page) + 1
      case 'prev':
        return parseInt(page) - 1
      default:
        return command
    }
  }

  const changePage = command => {
    search({
      page: parsePage(command, currentPage)
    })
  }

  const getPages = useCallback(
    state => {
      const middle = Math.trunc(numberOfPages / 2)
      const prevLength = getLowerValue(middle, currentPage - 1)
      const nextLength = getLowerValue(middle, pagination.pageCount - currentPage)
      if (state === 'prev') {
        return getLowerValue(middle + middle - nextLength, currentPage - 1)
      }
      return getLowerValue(middle + middle - prevLength, pagination.pageCount - currentPage)
    },
    [currentPage, pagination.pageCount, numberOfPages]
  )

  const renderPage = useCallback(
    page => (
      <PaginationButton
        variant='regular'
        as='button'
        isSelected={currentPage === page}
        key={page}
        disabled={currentPage === page}
        isLoading={isLoading}
        onClick={() => changePage(page)}
      >
        {page}
      </PaginationButton>
    ),
    [currentPage, changePage, isLoading]
  )

  const renderPages = useMemo(() => {
    const prevLength = getPages('prev')
    const nextLength = getPages('next')

    return (
      currentPage && (
        <Fragment>
          {Array.from({ length: prevLength }).map((_, index) => renderPage(currentPage - (prevLength - index)))}
          {renderPage(currentPage)}
          {Array.from({ length: nextLength }).map((_, index) => renderPage(currentPage + index + 1))}
        </Fragment>
      )
    )
  }, [getPages, renderPage, currentPage])

  return (
    <WrapperPagination isLoading={isLoading} justifyContent='center'>
      <Control disabled={currentPage === 1 || isLoading} onClick={() => changePage('prev')}>
        <IconChevronLeft style={{ color: '#616161' }} />
      </Control>
      {renderPages}
      <Control disabled={currentPage === pagination.pageCount || isLoading} onClick={() => changePage('next')}>
        <IconChevronRight style={{ color: '#616161' }} />
      </Control>
    </WrapperPagination>
  )
}

Pagination.propTypes = {
  pagination: PropTypes.exact({
    offset: PropTypes.number,
    limit: PropTypes.number,
    rowCount: PropTypes.number,
    pageCount: PropTypes.number
  }),
  search: PropTypes.func,
  isLoading: PropTypes.bool,
  maxPageNumber: PropTypes.number
}

Pagination.defaultProps = {
  maxPageNumber: 5
}

export default Pagination
