import React from 'react'
import PropTypes from 'prop-types'

import { Container } from './styles'

const Option = ({ imgUrl, name, onPress }) => {
  return (
    <Container onClick={onPress}>
      <div>
        <img src={imgUrl} alt={name} height='20' />
        <span>{name}</span>
      </div>
    </Container>
  )
}

Option.propTypes = {
  name: PropTypes.string,
  imgUrl: PropTypes.string,
  onPress: PropTypes.func
}

export default Option
