import React, { useRef, useState } from 'react'
import {
  FileUploadContainer,
  FormField,
  DragDropText,
  UploadFileBtn,
  FilePreviewContainer,
  PreviewContainer,
  PreviewList,
  FileMetaData,
  FileMetaDataError,
  RemoveFileIcon,
  StyledUpload
} from './style'

const KILO_BYTES_PER_BYTE = 1000
const DEFAULT_MAX_FILE_SIZE_IN_BYTES = 10485760

const convertNestedObjectToArray = nestedObj => Object.keys(nestedObj).map(key => nestedObj[key])

const convertBytesToKB = bytes => Math.round(bytes / KILO_BYTES_PER_BYTE)

const DropzoneDoc = ({ label, updateFilesCb, maxFileSizeInBytes = DEFAULT_MAX_FILE_SIZE_IN_BYTES, ...otherProps }) => {
  const fileInputField = useRef(null)
  const [files, setFiles] = useState({})
  const [errorFiles, setErrorFiles] = useState([])

  const handleUploadBtnClick = () => {
    fileInputField.current.click()
  }

  const addNewFiles = newFiles => {
    for (let file of newFiles) {
      if (file.size <= maxFileSizeInBytes) {
        if (!otherProps.multiple) {
          return { file }
        }
        files[file.name] = file
      } else {
        setErrorFiles([...errorFiles, file])
      }
    }
    return { ...files }
  }

  const callUpdateFilesCb = files => {
    const filesAsArray = convertNestedObjectToArray(files)
    updateFilesCb(filesAsArray)
  }

  const handleNewFileUpload = e => {
    const { files: newFiles } = e.target
    if (newFiles.length) {
      let updatedFiles = addNewFiles(newFiles)
      setFiles(updatedFiles)
      callUpdateFilesCb(updatedFiles)
    }
  }

  const removeFile = fileName => {
    delete files[fileName]
    setFiles({ ...files })
    callUpdateFilesCb({ ...files })
  }

  const removeFileError = file => {
    const filtered = errorFiles.filter(item => item.name !== file.name)
    setErrorFiles(filtered)
  }

  return (
    <>
      <FileUploadContainer>
        <DragDropText>Arraste e solte seus arquivos ou clique</DragDropText>
        <UploadFileBtn type='button' onClick={handleUploadBtnClick}>
          <StyledUpload />
          <span> Enviar {otherProps.multiple ? 'arquivos' : 'arquivo'}</span>
        </UploadFileBtn>
        <FormField type='file' ref={fileInputField} onChange={handleNewFileUpload} title='' value='' {...otherProps} />
      </FileUploadContainer>
      <FilePreviewContainer>
        <span>Enviado</span>
        <PreviewList>
          {Object.keys(files).map((fileName, index) => {
            let file = files[fileName]
            let isImageFile = file.type.split('/')[0] === 'image'
            return (
              <PreviewContainer key={fileName}>
                <div className='contentPrev'>
                  <FileMetaData>
                    <span onClick={() => window.open(URL.createObjectURL(file))}>{file.name}</span>
                    <aside>
                      <span>{convertBytesToKB(file.size)} kb</span>
                      <RemoveFileIcon className='fas fa-trash-alt' onClick={() => removeFile(fileName)}>
                        x
                      </RemoveFileIcon>
                    </aside>
                  </FileMetaData>
                </div>
              </PreviewContainer>
            )
          })}
        </PreviewList>

        {errorFiles.length > 0 && (
          <>
            <span style={{ marginTop: '10px' }}>Arquivo maior que 5MB ou formato não suportado.</span>
            <PreviewList>
              {errorFiles.map(item => {
                return (
                  <PreviewContainer key={item.name}>
                    <div className='contentPrev'>
                      <FileMetaDataError>
                        <span onClick={() => window.open(URL.createObjectURL(item))}>{item.name}</span>
                        <aside>
                          <span>{convertBytesToKB(item.size)} kb</span>
                          <RemoveFileIcon className='fas fa-trash-alt' onClick={() => removeFileError(item)}>
                            x
                          </RemoveFileIcon>
                        </aside>
                      </FileMetaDataError>
                    </div>
                  </PreviewContainer>
                )
              })}
            </PreviewList>
          </>
        )}
      </FilePreviewContainer>
    </>
  )
}

export default DropzoneDoc
