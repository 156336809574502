import React from 'react'

import { Container } from './styled'

const GlobalFilter = ({ filter, setFilter }) => {
  return (
    <Container>
      <input placeholder='Pesquisar' value={filter || ''} onChange={e => setFilter(e.target.value)} />
      <img src='/img/search.svg' alt='Ícone Lupa' />
    </Container>
  )
}

export default GlobalFilter
