import React, { useState, useCallback, useEffect } from 'react'
import PropTypes from 'prop-types'
import { Container, Option, Main } from './styles'

const TabMenu = ({ options, children, behavior, campaing, sel = 0, name = '' }) => {
  const [selected, setSelected] = useState(0)

  const showScroll = id => {
    try {
      var elmnt = document.getElementById(name + '=' + id)
      elmnt.scrollIntoView()
    } catch (e) {}
  }

  const select = useCallback((index, option) => {
    setSelected(Number(index))
    option.handleClick()
  }, [])

  useEffect(() => {
    setSelected(sel)
    showScroll(String(sel))
  }, [sel, options])

  return (
    <Main behavior={behavior} campaing={campaing}>
      <span>{children}</span>
      <Container behavior={behavior}>
        <div>
          {options.map((option, index) => (
            <button id={name + '=' + String(index)} key={index} onClick={() => select(index, option)}>
              <Option selected={selected === index}>{option.name}</Option>
            </button>
          ))}
        </div>
        <span>{children}</span>
      </Container>
    </Main>
  )
}

TabMenu.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      handleClick: PropTypes.func.isRequired
    }).isRequired
  ).isRequired
}

export default TabMenu
