import axios from 'axios'

import client from '../../providers/fetchClient'

export const getAddressByCep = async zipCode => {
  try {
    const { data: addressInfos, statusText } = await axios.get(`https://viacep.com.br/ws/${zipCode}/json/`)
    if (statusText !== 'OK' || !addressInfos || addressInfos.erro)
      throw new Error('Ocorreu um erro ao buscar os dados do CEP')
    return addressInfos
  } catch (error) {
    console.error(error)
    throw new Error(error.message)
  }
}

export const getAddressById = id => client.get(`/v3/addresses/${id}`)
