export const contentOptions = {
  mainList: {
    color: '#0e6603',
    title: 'Lista principal.',
    caption: [
      'Você está na lista principal. Ao término da captação, você receberá um e-mail com os dados para realizar a TED. Este status será atualizado após recebermos as TEDs de todos os investidores.'
    ]
  },
  finishedCampaign: {
    color: '#a80303',
    title: 'Campanha encerrada.',
    caption: [
      'Você ficou em lista de espera, mas não foi acionado.',
      'Caso queira, clique no X ao lado para apagar este Card.'
    ]
  },
  canceledPledge: {
    color: '#a80303',
    title: 'Pedido cancelado.',
    caption: ['Caso queira, clique no x ao lado para apagar este Card.']
  },
  holdList: {
    color: '#edb100',
    title: 'Lista de espera.',
    caption: ['Você está na lista de espera. Caso necessário, você será comunicado para prosseguir com o investimento.']
  }
}

export const pledgeStatuses = {
  ACCEPTED: 'AC',
  ACTIVE: 'A',
  CANCELLED: 'C',
  DELETED: 'D',
  ON_HOLD: 'OH'
}
