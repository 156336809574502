import client from '../../providers/fetchClient'

export const getShareholders = id => client.get(`/v3/shareholders?campaign_id=${id}`)

export const getShareholdersById = id => client.get(`/v3/shareholders/${id}`)

export const createShareholders = data => client.post('/v3/shareholders', data)

export const updateShareholders = (id, data) => client.put(`/v3/shareholders/${id}`, data)

export const deleteShareholders = id => client.delete(`/v3/shareholders/${id}`)
