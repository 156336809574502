import client from '../../providers/fetchClient'

export const getPledges = params => client.get('/v3/pledges', { params })

export const createPledge = data => client.post('/v3/pledges', data)

export const getPledgeStatuses = () => client.get('/v3/pledge-statuses')

export const updatePledge = (id, data) => client.put(`/v3/pledges/${id}`, data)

export const simulateInvestment = body => client.post('/v3/pledges/simulation', body)

export const deletePledge = id => client.delete(`/v3/pledges/${id}`)
