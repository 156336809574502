import React, { forwardRef } from 'react'
import PropTypes from 'prop-types'
import InputAdornment from '@material-ui/core/InputAdornment'

import { IconInfoRounded } from 'components/icons'
import { InputText } from './styles'

const InputBase = forwardRef(
  ({ name, helperText, defaultValue, control, icon, suffix, prefix, error, shrink, children, ...props }, ref) => {
    const id = props.id || name || 'input'

    const validateAdorment = {
      ...(!!icon && {
        endAdornment: <InputAdornment position='end'>{icon}</InputAdornment>
      }),
      ...(!!icon &&
        !!error && {
          endAdornment: <InputAdornment position='end'>{<IconInfoRounded />}</InputAdornment>
        }),
      ...(!!suffix && {
        endAdornment: <InputAdornment position='end'>{suffix}</InputAdornment>
      }),
      ...(!!prefix && {
        startAdornment: <InputAdornment position='start'>{prefix}</InputAdornment>
      })
    }

    const inputProps = {
      id,
      error: !!error,
      helperText: (!!error && error.message) || helperText,
      InputProps: {
        ...validateAdorment,
        ...props.InputProps
      },
      InputLabelProps: { shrink },
      ...props
    }

    return <InputText ref={ref} {...inputProps} />
  }
)

InputBase.propTypes = {
  label: PropTypes.string,
  helperText: PropTypes.any,
  placeholder: PropTypes.string,
  defaultValue: PropTypes.string,
  disabled: PropTypes.bool,
  error: PropTypes.oneOfType([
    PropTypes.shape({
      message: PropTypes.string
    }),
    PropTypes.any
  ]),
  type: PropTypes.string,
  name: PropTypes.string,
  variant: PropTypes.string,
  size: PropTypes.string,
  icon: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  suffix: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  prefix: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  multiline: PropTypes.bool,
  rows: PropTypes.number,
  rowsMax: PropTypes.number,
  inputProps: PropTypes.object
}

InputBase.defaultProps = {
  variant: 'outlined',
  size: 'small',
  multiline: false
}

export default InputBase
