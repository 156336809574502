import React from 'react'
import PropTypes from 'prop-types'

import { Modal, ModalContainer, Close, StyledRow } from './styles'

const ModalBase = ({
  open,
  onClose,
  width,
  height,
  children,
  title,
  iconClose,
  campaign = false,
  invest = false,
  maxWidth = 'sm',
  ...props
}) => {
  return (
    <Modal
      open={open}
      onClose={onClose}
      campaign={campaign ? 'true' : 'false'}
      invest={invest ? 'true' : 'false'}
      maxWidth={maxWidth}
    >
      <ModalContainer width={width} height={height} campaign={campaign ? 'true' : 'false'} {...props}>
        <StyledRow flexDirection={iconClose ? 'row-reverse' : 'row'}>
          {iconClose && <Close onClick={onClose} fontSize='small' />}
          {title && <h4>{title}</h4>}
        </StyledRow>
        {children}
      </ModalContainer>
    </Modal>
  )
}

ModalBase.defaultProps = {
  width: 200,
  height: 200
}

ModalBase.propType = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  title: PropTypes.string,
  iconClose: PropTypes.bool
}

export default ModalBase
