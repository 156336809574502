export const cities = {
  '': null,
  "Alta Floresta D'Oeste - RO": null,
  'Ariquemes - RO': null,
  'Cabixi - RO': null,
  'Cacoal - RO': null,
  'Cerejeiras - RO': null,
  'Colorado do Oeste - RO': null,
  'Corumbiara - RO': null,
  'Costa Marques - RO': null,
  "Espigao D'Oeste - RO": null,
  'Guajara-mirim - RO': null,
  'Jaru - RO': null,
  'Ji-parana - RO': null,
  "Machadinho D'Oeste - RO": null,
  "Nova Brasilandia D'Oeste - RO": null,
  'Ouro Preto do Oeste - RO': null,
  'Pimenta Bueno - RO': null,
  'Porto Velho - RO': null,
  'Presidente Medici - RO': null,
  'Rio Crespo - RO': null,
  'Rolim de Moura - RO': null,
  "Santa Luzia D'Oeste - RO": null,
  'Vilhena - RO': null,
  'Sao Miguel do Guapore - RO': null,
  'Nova Mamore - RO': null,
  "Alvorada D'Oeste - RO": null,
  'Alto Alegre dos Parecis - RO': null,
  'Alto Paraiso - RO': null,
  'Buritis - RO': null,
  'Novo Horizonte do Oeste - RO': null,
  'Cacaulandia - RO': null,
  'Campo Novo de Rondonia - RO': null,
  'Candeias do Jamari - RO': null,
  'Castanheiras - RO': null,
  'Chupinguaia - RO': null,
  'Cujubim - RO': null,
  'Governador Jorge Teixeira - RO': null,
  'Itapua do Oeste - RO': null,
  'Ministro Andreazza - RO': null,
  'Mirante da Serra - RO': null,
  'Monte Negro - RO': null,
  'Nova Uniao - RO': null,
  'Parecis - RO': null,
  'Pimenteiras do Oeste - RO': null,
  'Primavera de Rondonia - RO': null,
  "Sao Felipe D'Oeste - RO": null,
  'Sao Francisco do Guapore - RO': null,
  'Seringueiras - RO': null,
  'Teixeiropolis - RO': null,
  'Theobroma - RO': null,
  'Urupa - RO': null,
  'Vale do Anari - RO': null,
  'Vale do Paraiso - RO': null,
  'Acrelandia - AC': null,
  'Assis Brasil - AC': null,
  'Brasileia - AC': null,
  'Bujari - AC': null,
  'Capixaba - AC': null,
  'Cruzeiro do Sul - AC': null,
  'Epitaciolandia - AC': null,
  'Feijo - AC': null,
  'Jordao - AC': null,
  'Mancio Lima - AC': null,
  'Manoel Urbano - AC': null,
  'Marechal Thaumaturgo - AC': null,
  'Placido de Castro - AC': null,
  'Porto Walter - AC': null,
  'Rio Branco - AC': null,
  'Rodrigues Alves - AC': null,
  'Santa Rosa do Purus - AC': null,
  'Senador Guiomard - AC': null,
  'Sena Madureira - AC': null,
  'Tarauaca - AC': null,
  'Xapuri - AC': null,
  'Porto Acre - AC': null,
  'Alvaraes - AM': null,
  'Amatura - AM': null,
  'Anama - AM': null,
  'Anori - AM': null,
  'Apui - AM': null,
  'Atalaia do Norte - AM': null,
  'Autazes - AM': null,
  'Barcelos - AM': null,
  'Barreirinha - AM': null,
  'Benjamin Constant - AM': null,
  'Beruri - AM': null,
  'Boa Vista do Ramos - AM': null,
  'Boca do Acre - AM': null,
  'Borba - AM': null,
  'Caapiranga - AM': null,
  'Canutama - AM': null,
  'Carauari - AM': null,
  'Careiro - AM': null,
  'Careiro da Varzea - AM': null,
  'Coari - AM': null,
  'Codajas - AM': null,
  'Eirunepe - AM': null,
  'Envira - AM': null,
  'Fonte Boa - AM': null,
  'Guajara - AM': null,
  'Humaita - AM': null,
  'Ipixuna - AM': null,
  'Iranduba - AM': null,
  'Itacoatiara - AM': null,
  'Itamarati - AM': null,
  'Itapiranga - AM': null,
  'Japura - AM': null,
  'Jurua - AM': null,
  'Jutai - AM': null,
  'Labrea - AM': null,
  'Manacapuru - AM': null,
  'Manaquiri - AM': null,
  'Manaus - AM': null,
  'Manicore - AM': null,
  'Maraa - AM': null,
  'Maues - AM': null,
  'Nhamunda - AM': null,
  'Nova Olinda do Norte - AM': null,
  'Novo Airao - AM': null,
  'Novo Aripuana - AM': null,
  'Parintins - AM': null,
  'Pauini - AM': null,
  'Presidente Figueiredo - AM': null,
  'Rio Preto da Eva - AM': null,
  'Santa Isabel do Rio Negro - AM': null,
  'Santo Antonio do Ica - AM': null,
  'Sao Gabriel da Cachoeira - AM': null,
  'Sao Paulo de Olivenca - AM': null,
  'Sao Sebastiao do Uatuma - AM': null,
  'Silves - AM': null,
  'Tabatinga - AM': null,
  'Tapaua - AM': null,
  'Tefe - AM': null,
  'Tonantins - AM': null,
  'Uarini - AM': null,
  'Urucara - AM': null,
  'Urucurituba - AM': null,
  'Amajari - RR': null,
  'Alto Alegre - RR': null,
  'Boa Vista - RR': null,
  'Bonfim - RR': null,
  'Canta - RR': null,
  'Caracarai - RR': null,
  'Caroebe - RR': null,
  'Iracema - RR': null,
  'Mucajai - RR': null,
  'Normandia - RR': null,
  'Pacaraima - RR': null,
  'Rorainopolis - RR': null,
  'Sao Joao da Baliza - RR': null,
  'Sao Luiz - RR': null,
  'Uiramuta - RR': null,
  'Abaetetuba - PA': null,
  'Abel Figueiredo - PA': null,
  'Acara - PA': null,
  'Afua - PA': null,
  'Agua Azul do Norte - PA': null,
  'Alenquer - PA': null,
  'Almeirim - PA': null,
  'Altamira - PA': null,
  'Anajas - PA': null,
  'Ananindeua - PA': null,
  'Anapu - PA': null,
  'Augusto Correa - PA': null,
  'Aurora do Para - PA': null,
  'Aveiro - PA': null,
  'Bagre - PA': null,
  'Baiao - PA': null,
  'Bannach - PA': null,
  'Barcarena - PA': null,
  'Belem - PA': null,
  'Belterra - PA': null,
  'Benevides - PA': null,
  'Bom Jesus do Tocantins - PA': null,
  'Bonito - PA': null,
  'Braganca - PA': null,
  'Brasil Novo - PA': null,
  'Brejo Grande do Araguaia - PA': null,
  'Breu Branco - PA': null,
  'Breves - PA': null,
  'Bujaru - PA': null,
  'Cachoeira do Piria - PA': null,
  'Cachoeira do Arari - PA': null,
  'Cameta - PA': null,
  'Canaa dos Carajas - PA': null,
  'Capanema - PA': null,
  'Capitao Poco - PA': null,
  'Castanhal - PA': null,
  'Chaves - PA': null,
  'Colares - PA': null,
  'Conceicao do Araguaia - PA': null,
  'Concordia do Para - PA': null,
  'Cumaru do Norte - PA': null,
  'Curionopolis - PA': null,
  'Curralinho - PA': null,
  'Curua - PA': null,
  'Curuca - PA': null,
  'Dom Eliseu - PA': null,
  'Eldorado dos Carajas - PA': null,
  'Faro - PA': null,
  'Floresta do Araguaia - PA': null,
  'Garrafao do Norte - PA': null,
  'Goianesia do Para - PA': null,
  'Gurupa - PA': null,
  'Igarape-acu - PA': null,
  'Igarape-miri - PA': null,
  'Inhangapi - PA': null,
  'Ipixuna do Para - PA': null,
  'Irituia - PA': null,
  'Itaituba - PA': null,
  'Itupiranga - PA': null,
  'Jacareacanga - PA': null,
  'Jacunda - PA': null,
  'Juruti - PA': null,
  'Limoeiro do Ajuru - PA': null,
  'Mae do Rio - PA': null,
  'Magalhaes Barata - PA': null,
  'Maraba - PA': null,
  'Maracana - PA': null,
  'Marapanim - PA': null,
  'Marituba - PA': null,
  'Medicilandia - PA': null,
  'Melgaco - PA': null,
  'Mocajuba - PA': null,
  'Moju - PA': null,
  'Monte Alegre - PA': null,
  'Muana - PA': null,
  'Nova Esperanca do Piria - PA': null,
  'Nova Ipixuna - PA': null,
  'Nova Timboteua - PA': null,
  'Novo Progresso - PA': null,
  'Novo Repartimento - PA': null,
  'Obidos - PA': null,
  'Oeiras do Para - PA': null,
  'Oriximina - PA': null,
  'Ourem - PA': null,
  'Ourilandia do Norte - PA': null,
  'Pacaja - PA': null,
  'Palestina do Para - PA': null,
  'Paragominas - PA': null,
  'Parauapebas - PA': null,
  "Pau D'Arco - PA": null,
  'Peixe-boi - PA': null,
  'Picarra - PA': null,
  'Placas - PA': null,
  'Ponta de Pedras - PA': null,
  'Portel - PA': null,
  'Porto de Moz - PA': null,
  'Prainha - PA': null,
  'Primavera - PA': null,
  'Quatipuru - PA': null,
  'Redencao - PA': null,
  'Rio Maria - PA': null,
  'Rondon do Para - PA': null,
  'Ruropolis - PA': null,
  'Salinopolis - PA': null,
  'Salvaterra - PA': null,
  'Santa Barbara do Para - PA': null,
  'Santa Cruz do Arari - PA': null,
  'Santa Isabel do Para - PA': null,
  'Santa Luzia do Para - PA': null,
  'Santa Maria das Barreiras - PA': null,
  'Santa Maria do Para - PA': null,
  'Santana do Araguaia - PA': null,
  'Santarem - PA': null,
  'Santarem Novo - PA': null,
  'Santo Antonio do Taua - PA': null,
  'Sao Caetano de Odivelas - PA': null,
  'Sao Domingos do Araguaia - PA': null,
  'Sao Domingos do Capim - PA': null,
  'Sao Felix do Xingu - PA': null,
  'Sao Francisco do Para - PA': null,
  'Sao Geraldo do Araguaia - PA': null,
  'Sao Joao da Ponta - PA': null,
  'Sao Joao de Pirabas - PA': null,
  'Sao Joao do Araguaia - PA': null,
  'Sao Miguel do Guama - PA': null,
  'Sao Sebastiao da Boa Vista - PA': null,
  'Sapucaia - PA': null,
  'Senador Jose Porfirio - PA': null,
  'Soure - PA': null,
  'Tailandia - PA': null,
  'Terra Alta - PA': null,
  'Terra Santa - PA': null,
  'Tome-acu - PA': null,
  'Tracuateua - PA': null,
  'Trairao - PA': null,
  'Tucuma - PA': null,
  'Tucurui - PA': null,
  'Ulianopolis - PA': null,
  'Uruara - PA': null,
  'Vigia - PA': null,
  'Viseu - PA': null,
  'Vitoria do Xingu - PA': null,
  'Xinguara - PA': null,
  'Serra do Navio - AP': null,
  'Amapa - AP': null,
  'Pedra Branca do Amapari - AP': null,
  'Calcoene - AP': null,
  'Cutias - AP': null,
  'Ferreira Gomes - AP': null,
  'Itaubal - AP': null,
  'Laranjal do Jari - AP': null,
  'Macapa - AP': null,
  'Mazagao - AP': null,
  'Oiapoque - AP': null,
  'Porto Grande - AP': null,
  'Pracuuba - AP': null,
  'Santana - AP': null,
  'Tartarugalzinho - AP': null,
  'Vitoria do Jari - AP': null,
  'Abreulandia - TO': null,
  'Aguiarnopolis - TO': null,
  'Alianca do Tocantins - TO': null,
  'Almas - TO': null,
  'Alvorada - TO': null,
  'Ananas - TO': null,
  'Angico - TO': null,
  'Aparecida do Rio Negro - TO': null,
  'Aragominas - TO': null,
  'Araguacema - TO': null,
  'Araguacu - TO': null,
  'Araguaina - TO': null,
  'Araguana - TO': null,
  'Araguatins - TO': null,
  'Arapoema - TO': null,
  'Arraias - TO': null,
  'Augustinopolis - TO': null,
  'Aurora do Tocantins - TO': null,
  'Axixa do Tocantins - TO': null,
  'Babaculandia - TO': null,
  'Bandeirantes do Tocantins - TO': null,
  'Barra do Ouro - TO': null,
  'Barrolandia - TO': null,
  'Bernardo Sayao - TO': null,
  'Bom Jesus do Tocantins - TO': null,
  'Brasilandia do Tocantins - TO': null,
  'Brejinho de Nazare - TO': null,
  'Buriti do Tocantins - TO': null,
  'Cachoeirinha - TO': null,
  'Campos Lindos - TO': null,
  'Cariri do Tocantins - TO': null,
  'Carmolandia - TO': null,
  'Carrasco Bonito - TO': null,
  'Caseara - TO': null,
  'Centenario - TO': null,
  'Chapada de Areia - TO': null,
  'Chapada da Natividade - TO': null,
  'Colinas do Tocantins - TO': null,
  'Combinado - TO': null,
  'Conceicao do Tocantins - TO': null,
  'Couto Magalhaes - TO': null,
  'Cristalandia - TO': null,
  'Crixas do Tocantins - TO': null,
  'Darcinopolis - TO': null,
  'Dianopolis - TO': null,
  'Divinopolis do Tocantins - TO': null,
  'Dois Irmaos do Tocantins - TO': null,
  'Duere - TO': null,
  'Esperantina - TO': null,
  'Fatima - TO': null,
  'Figueiropolis - TO': null,
  'Filadelfia - TO': null,
  'Formoso do Araguaia - TO': null,
  'Fortaleza do Tabocao - TO': null,
  'Goianorte - TO': null,
  'Goiatins - TO': null,
  'Guarai - TO': null,
  'Gurupi - TO': null,
  'Ipueiras - TO': null,
  'Itacaja - TO': null,
  'Itaguatins - TO': null,
  'Itapiratins - TO': null,
  'Itapora do Tocantins - TO': null,
  'Jau do Tocantins - TO': null,
  'Juarina - TO': null,
  'Lagoa da Confusao - TO': null,
  'Lagoa do Tocantins - TO': null,
  'Lajeado - TO': null,
  'Lavandeira - TO': null,
  'Lizarda - TO': null,
  'Luzinopolis - TO': null,
  'Marianopolis do Tocantins - TO': null,
  'Mateiros - TO': null,
  'Maurilandia do Tocantins - TO': null,
  'Miracema do Tocantins - TO': null,
  'Miranorte - TO': null,
  'Monte do Carmo - TO': null,
  'Monte Santo do Tocantins - TO': null,
  'Palmeiras do Tocantins - TO': null,
  'Muricilandia - TO': null,
  'Natividade - TO': null,
  'Nazare - TO': null,
  'Nova Olinda - TO': null,
  'Nova Rosalandia - TO': null,
  'Novo Acordo - TO': null,
  'Novo Alegre - TO': null,
  'Novo Jardim - TO': null,
  'Oliveira de Fatima - TO': null,
  'Palmeirante - TO': null,
  'Palmeiropolis - TO': null,
  'Paraiso do Tocantins - TO': null,
  'Parana - TO': null,
  "Pau D'Arco - TO": null,
  'Pedro Afonso - TO': null,
  'Peixe - TO': null,
  'Pequizeiro - TO': null,
  'Colmeia - TO': null,
  'Pindorama do Tocantins - TO': null,
  'Piraque - TO': null,
  'Pium - TO': null,
  'Ponte Alta do Bom Jesus - TO': null,
  'Ponte Alta do Tocantins - TO': null,
  'Porto Alegre do Tocantins - TO': null,
  'Porto Nacional - TO': null,
  'Praia Norte - TO': null,
  'Presidente Kennedy - TO': null,
  'Pugmil - TO': null,
  'Recursolandia - TO': null,
  'Riachinho - TO': null,
  'Rio da Conceicao - TO': null,
  'Rio dos Bois - TO': null,
  'Rio Sono - TO': null,
  'Sampaio - TO': null,
  'Sandolandia - TO': null,
  'Santa Fe do Araguaia - TO': null,
  'Santa Maria do Tocantins - TO': null,
  'Santa Rita do Tocantins - TO': null,
  'Santa Rosa do Tocantins - TO': null,
  'Santa Tereza do Tocantins - TO': null,
  'Santa Terezinha do Tocantins - TO': null,
  'Sao Bento do Tocantins - TO': null,
  'Sao Felix do Tocantins - TO': null,
  'Sao Miguel do Tocantins - TO': null,
  'Sao Salvador do Tocantins - TO': null,
  'Sao Sebastiao do Tocantins - TO': null,
  'Sao Valerio da Natividade - TO': null,
  'Silvanopolis - TO': null,
  'Sitio Novo do Tocantins - TO': null,
  'Sucupira - TO': null,
  'Taguatinga - TO': null,
  'Taipas do Tocantins - TO': null,
  'Talisma - TO': null,
  'Palmas - TO': null,
  'Tocantinia - TO': null,
  'Tocantinopolis - TO': null,
  'Tupirama - TO': null,
  'Tupiratins - TO': null,
  'Wanderlandia - TO': null,
  'Xambioa - TO': null,
  'Acailandia - MA': null,
  'Afonso Cunha - MA': null,
  'Agua Doce do Maranhao - MA': null,
  'Alcantara - MA': null,
  'Aldeias Altas - MA': null,
  'Altamira do Maranhao - MA': null,
  'Alto Alegre do Maranhao - MA': null,
  'Alto Alegre do Pindare - MA': null,
  'Alto Parnaiba - MA': null,
  'Amapa do Maranhao - MA': null,
  'Amarante do Maranhao - MA': null,
  'Anajatuba - MA': null,
  'Anapurus - MA': null,
  'Apicum-acu - MA': null,
  'Araguana - MA': null,
  'Araioses - MA': null,
  'Arame - MA': null,
  'Arari - MA': null,
  'Axixa - MA': null,
  'Bacabal - MA': null,
  'Bacabeira - MA': null,
  'Bacuri - MA': null,
  'Bacurituba - MA': null,
  'Balsas - MA': null,
  'Barao de Grajau - MA': null,
  'Barra do Corda - MA': null,
  'Barreirinhas - MA': null,
  'Belagua - MA': null,
  'Bela Vista do Maranhao - MA': null,
  'Benedito Leite - MA': null,
  'Bequimao - MA': null,
  'Bernardo do Mearim - MA': null,
  'Boa Vista do Gurupi - MA': null,
  'Bom Jardim - MA': null,
  'Bom Jesus das Selvas - MA': null,
  'Bom Lugar - MA': null,
  'Brejo - MA': null,
  'Brejo de Areia - MA': null,
  'Buriti - MA': null,
  'Buriti Bravo - MA': null,
  'Buriticupu - MA': null,
  'Buritirana - MA': null,
  'Cachoeira Grande - MA': null,
  'Cajapio - MA': null,
  'Cajari - MA': null,
  'Campestre do Maranhao - MA': null,
  'Candido Mendes - MA': null,
  'Cantanhede - MA': null,
  'Capinzal do Norte - MA': null,
  'Carolina - MA': null,
  'Carutapera - MA': null,
  'Caxias - MA': null,
  'Cedral - MA': null,
  'Central do Maranhao - MA': null,
  'Centro do Guilherme - MA': null,
  'Centro Novo do Maranhao - MA': null,
  'Chapadinha - MA': null,
  'Cidelandia - MA': null,
  'Codo - MA': null,
  'Coelho Neto - MA': null,
  'Colinas - MA': null,
  'Conceicao do Lago-acu - MA': null,
  'Coroata - MA': null,
  'Cururupu - MA': null,
  'Davinopolis - MA': null,
  'Dom Pedro - MA': null,
  'Duque Bacelar - MA': null,
  'Esperantinopolis - MA': null,
  'Estreito - MA': null,
  'Feira Nova do Maranhao - MA': null,
  'Fernando Falcao - MA': null,
  'Formosa da Serra Negra - MA': null,
  'Fortaleza dos Nogueiras - MA': null,
  'Fortuna - MA': null,
  'Godofredo Viana - MA': null,
  'Goncalves Dias - MA': null,
  'Governador Archer - MA': null,
  'Governador Edison Lobao - MA': null,
  'Governador Eugenio Barros - MA': null,
  'Governador Luiz Rocha - MA': null,
  'Governador Newton Bello - MA': null,
  'Governador Nunes Freire - MA': null,
  'Graca Aranha - MA': null,
  'Grajau - MA': null,
  'Guimaraes - MA': null,
  'Humberto de Campos - MA': null,
  'Icatu - MA': null,
  'Igarape do Meio - MA': null,
  'Igarape Grande - MA': null,
  'Imperatriz - MA': null,
  'Itaipava do Grajau - MA': null,
  'Itapecuru Mirim - MA': null,
  'Itinga do Maranhao - MA': null,
  'Jatoba - MA': null,
  'Jenipapo dos Vieiras - MA': null,
  'Joao Lisboa - MA': null,
  'Joselandia - MA': null,
  'Junco do Maranhao - MA': null,
  'Lago da Pedra - MA': null,
  'Lago do Junco - MA': null,
  'Lago Verde - MA': null,
  'Lagoa do Mato - MA': null,
  'Lago dos Rodrigues - MA': null,
  'Lagoa Grande do Maranhao - MA': null,
  'Lajeado Novo - MA': null,
  'Lima Campos - MA': null,
  'Loreto - MA': null,
  'Luis Domingues - MA': null,
  'Magalhaes de Almeida - MA': null,
  'Maracacume - MA': null,
  'Maraja do Sena - MA': null,
  'Maranhaozinho - MA': null,
  'Mata Roma - MA': null,
  'Matinha - MA': null,
  'Matoes - MA': null,
  'Matoes do Norte - MA': null,
  'Milagres do Maranhao - MA': null,
  'Mirador - MA': null,
  'Miranda do Norte - MA': null,
  'Mirinzal - MA': null,
  'Moncao - MA': null,
  'Montes Altos - MA': null,
  'Morros - MA': null,
  'Nina Rodrigues - MA': null,
  'Nova Colinas - MA': null,
  'Nova Iorque - MA': null,
  'Nova Olinda do Maranhao - MA': null,
  "Olho D'Agua das Cunhas - MA": null,
  'Olinda Nova do Maranhao - MA': null,
  'Paco do Lumiar - MA': null,
  'Palmeirandia - MA': null,
  'Paraibano - MA': null,
  'Parnarama - MA': null,
  'Passagem Franca - MA': null,
  'Pastos Bons - MA': null,
  'Paulino Neves - MA': null,
  'Paulo Ramos - MA': null,
  'Pedreiras - MA': null,
  'Pedro do Rosario - MA': null,
  'Penalva - MA': null,
  'Peri Mirim - MA': null,
  'Peritoro - MA': null,
  'Pindare-mirim - MA': null,
  'Pinheiro - MA': null,
  'Pio Xii - MA': null,
  'Pirapemas - MA': null,
  'Pocao de Pedras - MA': null,
  'Porto Franco - MA': null,
  'Porto Rico do Maranhao - MA': null,
  'Presidente Dutra - MA': null,
  'Presidente Juscelino - MA': null,
  'Presidente Medici - MA': null,
  'Presidente Sarney - MA': null,
  'Presidente Vargas - MA': null,
  'Primeira Cruz - MA': null,
  'Raposa - MA': null,
  'Riachao - MA': null,
  'Ribamar Fiquene - MA': null,
  'Rosario - MA': null,
  'Sambaiba - MA': null,
  'Santa Filomena do Maranhao - MA': null,
  'Santa Helena - MA': null,
  'Santa Ines - MA': null,
  'Santa Luzia - MA': null,
  'Santa Luzia do Parua - MA': null,
  'Santa Quiteria do Maranhao - MA': null,
  'Santa Rita - MA': null,
  'Santana do Maranhao - MA': null,
  'Santo Amaro do Maranhao - MA': null,
  'Santo Antonio dos Lopes - MA': null,
  'Sao Benedito do Rio Preto - MA': null,
  'Sao Bento - MA': null,
  'Sao Bernardo - MA': null,
  'Sao Domingos do Azeitao - MA': null,
  'Sao Domingos do Maranhao - MA': null,
  'Sao Felix de Balsas - MA': null,
  'Sao Francisco do Brejao - MA': null,
  'Sao Francisco do Maranhao - MA': null,
  'Sao Joao Batista - MA': null,
  'Sao Joao do Caru - MA': null,
  'Sao Joao do Paraiso - MA': null,
  'Sao Joao do Soter - MA': null,
  'Sao Joao dos Patos - MA': null,
  'Sao Jose de Ribamar - MA': null,
  'Sao Jose dos Basilios - MA': null,
  'Sao Luis - MA': null,
  'Sao Luis Gonzaga do Maranhao - MA': null,
  'Sao Mateus do Maranhao - MA': null,
  'Sao Pedro da Agua Branca - MA': null,
  'Sao Pedro dos Crentes - MA': null,
  'Sao Raimundo das Mangabeiras - MA': null,
  'Sao Raimundo do Doca Bezerra - MA': null,
  'Sao Roberto - MA': null,
  'Sao Vicente Ferrer - MA': null,
  'Satubinha - MA': null,
  'Senador Alexandre Costa - MA': null,
  'Senador La Rocque - MA': null,
  'Serrano do Maranhao - MA': null,
  'Sitio Novo - MA': null,
  'Sucupira do Norte - MA': null,
  'Sucupira do Riachao - MA': null,
  'Tasso Fragoso - MA': null,
  'Timbiras - MA': null,
  'Timon - MA': null,
  'Trizidela do Vale - MA': null,
  'Tufilandia - MA': null,
  'Tuntum - MA': null,
  'Turiacu - MA': null,
  'Turilandia - MA': null,
  'Tutoia - MA': null,
  'Urbano Santos - MA': null,
  'Vargem Grande - MA': null,
  'Viana - MA': null,
  'Vila Nova dos Martirios - MA': null,
  'Vitoria do Mearim - MA': null,
  'Vitorino Freire - MA': null,
  'Ze Doca - MA': null,
  'Acaua - PI': null,
  'Agricolandia - PI': null,
  'Agua Branca - PI': null,
  'Alagoinha do Piaui - PI': null,
  'Alegrete do Piaui - PI': null,
  'Alto Longa - PI': null,
  'Altos - PI': null,
  'Alvorada do Gurgueia - PI': null,
  'Amarante - PI': null,
  'Angical do Piaui - PI': null,
  'Anisio de Abreu - PI': null,
  'Antonio Almeida - PI': null,
  'Aroazes - PI': null,
  'Aroeiras do Itaim - PI': null,
  'Arraial - PI': null,
  'Assuncao do Piaui - PI': null,
  'Avelino Lopes - PI': null,
  'Baixa Grande do Ribeiro - PI': null,
  "Barra D'Alcantara - PI": null,
  'Barras - PI': null,
  'Barreiras do Piaui - PI': null,
  'Barro Duro - PI': null,
  'Batalha - PI': null,
  'Bela Vista do Piaui - PI': null,
  'Belem do Piaui - PI': null,
  'Beneditinos - PI': null,
  'Bertolinia - PI': null,
  'Betania do Piaui - PI': null,
  'Boa Hora - PI': null,
  'Bocaina - PI': null,
  'Bom Jesus - PI': null,
  'Bom Principio do Piaui - PI': null,
  'Bonfim do Piaui - PI': null,
  'Boqueirao do Piaui - PI': null,
  'Brasileira - PI': null,
  'Brejo do Piaui - PI': null,
  'Buriti dos Lopes - PI': null,
  'Buriti dos Montes - PI': null,
  'Cabeceiras do Piaui - PI': null,
  'Cajazeiras do Piaui - PI': null,
  'Cajueiro da Praia - PI': null,
  'Caldeirao Grande do Piaui - PI': null,
  'Campinas do Piaui - PI': null,
  'Campo Alegre do Fidalgo - PI': null,
  'Campo Grande do Piaui - PI': null,
  'Campo Largo do Piaui - PI': null,
  'Campo Maior - PI': null,
  'Canavieira - PI': null,
  'Canto do Buriti - PI': null,
  'Capitao de Campos - PI': null,
  'Capitao Gervasio Oliveira - PI': null,
  'Caracol - PI': null,
  'Caraubas do Piaui - PI': null,
  'Caridade do Piaui - PI': null,
  'Castelo do Piaui - PI': null,
  'Caxingo - PI': null,
  'Cocal - PI': null,
  'Cocal de Telha - PI': null,
  'Cocal dos Alves - PI': null,
  'Coivaras - PI': null,
  'Colonia do Gurgueia - PI': null,
  'Colonia do Piaui - PI': null,
  'Conceicao do Caninde - PI': null,
  'Coronel Jose Dias - PI': null,
  'Corrente - PI': null,
  'Cristalandia do Piaui - PI': null,
  'Cristino Castro - PI': null,
  'Curimata - PI': null,
  'Currais - PI': null,
  'Curralinhos - PI': null,
  'Curral Novo do Piaui - PI': null,
  'Demerval Lobao - PI': null,
  'Dirceu Arcoverde - PI': null,
  'Dom Expedito Lopes - PI': null,
  'Domingos Mourao - PI': null,
  'Dom Inocencio - PI': null,
  'Elesbao Veloso - PI': null,
  'Eliseu Martins - PI': null,
  'Esperantina - PI': null,
  'Fartura do Piaui - PI': null,
  'Flores do Piaui - PI': null,
  'Floresta do Piaui - PI': null,
  'Floriano - PI': null,
  'Francinopolis - PI': null,
  'Francisco Ayres - PI': null,
  'Francisco Macedo - PI': null,
  'Francisco Santos - PI': null,
  'Fronteiras - PI': null,
  'Geminiano - PI': null,
  'Gilbues - PI': null,
  'Guadalupe - PI': null,
  'Guaribas - PI': null,
  'Hugo Napoleao - PI': null,
  'Ilha Grande - PI': null,
  'Inhuma - PI': null,
  'Ipiranga do Piaui - PI': null,
  'Isaias Coelho - PI': null,
  'Itainopolis - PI': null,
  'Itaueira - PI': null,
  'Jacobina do Piaui - PI': null,
  'Jaicos - PI': null,
  'Jardim do Mulato - PI': null,
  'Jatoba do Piaui - PI': null,
  'Jerumenha - PI': null,
  'Joao Costa - PI': null,
  'Joaquim Pires - PI': null,
  'Joca Marques - PI': null,
  'Jose de Freitas - PI': null,
  'Juazeiro do Piaui - PI': null,
  'Julio Borges - PI': null,
  'Jurema - PI': null,
  'Lagoinha do Piaui - PI': null,
  'Lagoa Alegre - PI': null,
  'Lagoa do Barro do Piaui - PI': null,
  'Lagoa de Sao Francisco - PI': null,
  'Lagoa do Piaui - PI': null,
  'Lagoa do Sitio - PI': null,
  'Landri Sales - PI': null,
  'Luis Correia - PI': null,
  'Luzilandia - PI': null,
  'Madeiro - PI': null,
  'Manoel Emidio - PI': null,
  'Marcolandia - PI': null,
  'Marcos Parente - PI': null,
  'Massape do Piaui - PI': null,
  'Matias Olimpio - PI': null,
  'Miguel Alves - PI': null,
  'Miguel Leao - PI': null,
  'Milton Brandao - PI': null,
  'Monsenhor Gil - PI': null,
  'Monsenhor Hipolito - PI': null,
  'Monte Alegre do Piaui - PI': null,
  'Morro Cabeca No Tempo - PI': null,
  'Morro do Chapeu do Piaui - PI': null,
  'Murici dos Portelas - PI': null,
  'Nazare do Piaui - PI': null,
  'Nazaria - PI': null,
  'Nossa Senhora de Nazare - PI': null,
  'Nossa Senhora dos Remedios - PI': null,
  'Novo Oriente do Piaui - PI': null,
  'Novo Santo Antonio - PI': null,
  'Oeiras - PI': null,
  "Olho D'Agua do Piaui - PI": null,
  'Padre Marcos - PI': null,
  'Paes Landim - PI': null,
  'Pajeu do Piaui - PI': null,
  'Palmeira do Piaui - PI': null,
  'Palmeirais - PI': null,
  'Paqueta - PI': null,
  'Parnagua - PI': null,
  'Parnaiba - PI': null,
  'Passagem Franca do Piaui - PI': null,
  'Patos do Piaui - PI': null,
  "Pau D'Arco do Piaui - PI": null,
  'Paulistana - PI': null,
  'Pavussu - PI': null,
  'Pedro Ii - PI': null,
  'Pedro Laurentino - PI': null,
  'Nova Santa Rita - PI': null,
  'Picos - PI': null,
  'Pimenteiras - PI': null,
  'Pio Ix - PI': null,
  'Piracuruca - PI': null,
  'Piripiri - PI': null,
  'Porto - PI': null,
  'Porto Alegre do Piaui - PI': null,
  'Prata do Piaui - PI': null,
  'Queimada Nova - PI': null,
  'Redencao do Gurgueia - PI': null,
  'Regeneracao - PI': null,
  'Riacho Frio - PI': null,
  'Ribeira do Piaui - PI': null,
  'Ribeiro Goncalves - PI': null,
  'Rio Grande do Piaui - PI': null,
  'Santa Cruz do Piaui - PI': null,
  'Santa Cruz dos Milagres - PI': null,
  'Santa Filomena - PI': null,
  'Santa Luz - PI': null,
  'Santana do Piaui - PI': null,
  'Santa Rosa do Piaui - PI': null,
  'Santo Antonio de Lisboa - PI': null,
  'Santo Antonio dos Milagres - PI': null,
  'Santo Inacio do Piaui - PI': null,
  'Sao Braz do Piaui - PI': null,
  'Sao Felix do Piaui - PI': null,
  'Sao Francisco de Assis do Piaui - PI': null,
  'Sao Francisco do Piaui - PI': null,
  'Sao Goncalo do Gurgueia - PI': null,
  'Sao Goncalo do Piaui - PI': null,
  'Sao Joao da Canabrava - PI': null,
  'Sao Joao da Fronteira - PI': null,
  'Sao Joao da Serra - PI': null,
  'Sao Joao da Varjota - PI': null,
  'Sao Joao do Arraial - PI': null,
  'Sao Joao do Piaui - PI': null,
  'Sao Jose do Divino - PI': null,
  'Sao Jose do Peixe - PI': null,
  'Sao Jose do Piaui - PI': null,
  'Sao Juliao - PI': null,
  'Sao Lourenco do Piaui - PI': null,
  'Sao Luis do Piaui - PI': null,
  'Sao Miguel da Baixa Grande - PI': null,
  'Sao Miguel do Fidalgo - PI': null,
  'Sao Miguel do Tapuio - PI': null,
  'Sao Pedro do Piaui - PI': null,
  'Sao Raimundo Nonato - PI': null,
  'Sebastiao Barros - PI': null,
  'Sebastiao Leal - PI': null,
  'Sigefredo Pacheco - PI': null,
  'Simoes - PI': null,
  'Simplicio Mendes - PI': null,
  'Socorro do Piaui - PI': null,
  'Sussuapara - PI': null,
  'Tamboril do Piaui - PI': null,
  'Tanque do Piaui - PI': null,
  'Teresina - PI': null,
  'Uniao - PI': null,
  'Urucui - PI': null,
  'Valenca do Piaui - PI': null,
  'Varzea Branca - PI': null,
  'Varzea Grande - PI': null,
  'Vera Mendes - PI': null,
  'Vila Nova do Piaui - PI': null,
  'Wall Ferraz - PI': null,
  'Abaiara - CE': null,
  'Acarape - CE': null,
  'Acarau - CE': null,
  'Acopiara - CE': null,
  'Aiuaba - CE': null,
  'Alcantaras - CE': null,
  'Altaneira - CE': null,
  'Alto Santo - CE': null,
  'Amontada - CE': null,
  'Antonina do Norte - CE': null,
  'Apuiares - CE': null,
  'Aquiraz - CE': null,
  'Aracati - CE': null,
  'Aracoiaba - CE': null,
  'Ararenda - CE': null,
  'Araripe - CE': null,
  'Aratuba - CE': null,
  'Arneiroz - CE': null,
  'Assare - CE': null,
  'Aurora - CE': null,
  'Baixio - CE': null,
  'Banabuiu - CE': null,
  'Barbalha - CE': null,
  'Barreira - CE': null,
  'Barro - CE': null,
  'Barroquinha - CE': null,
  'Baturite - CE': null,
  'Beberibe - CE': null,
  'Bela Cruz - CE': null,
  'Boa Viagem - CE': null,
  'Brejo Santo - CE': null,
  'Camocim - CE': null,
  'Campos Sales - CE': null,
  'Caninde - CE': null,
  'Capistrano - CE': null,
  'Caridade - CE': null,
  'Carire - CE': null,
  'Caririacu - CE': null,
  'Carius - CE': null,
  'Carnaubal - CE': null,
  'Cascavel - CE': null,
  'Catarina - CE': null,
  'Catunda - CE': null,
  'Caucaia - CE': null,
  'Cedro - CE': null,
  'Chaval - CE': null,
  'Choro - CE': null,
  'Chorozinho - CE': null,
  'Coreau - CE': null,
  'Crateus - CE': null,
  'Crato - CE': null,
  'Croata - CE': null,
  'Cruz - CE': null,
  'Deputado Irapuan Pinheiro - CE': null,
  'Erere - CE': null,
  'Eusebio - CE': null,
  'Farias Brito - CE': null,
  'Forquilha - CE': null,
  'Fortaleza - CE': null,
  'Fortim - CE': null,
  'Frecheirinha - CE': null,
  'General Sampaio - CE': null,
  'Graca - CE': null,
  'Granja - CE': null,
  'Granjeiro - CE': null,
  'Groairas - CE': null,
  'Guaiuba - CE': null,
  'Guaraciaba do Norte - CE': null,
  'Guaramiranga - CE': null,
  'Hidrolandia - CE': null,
  'Horizonte - CE': null,
  'Ibaretama - CE': null,
  'Ibiapina - CE': null,
  'Ibicuitinga - CE': null,
  'Icapui - CE': null,
  'Ico - CE': null,
  'Iguatu - CE': null,
  'Independencia - CE': null,
  'Ipaporanga - CE': null,
  'Ipaumirim - CE': null,
  'Ipu - CE': null,
  'Ipueiras - CE': null,
  'Iracema - CE': null,
  'Iraucuba - CE': null,
  'Itaicaba - CE': null,
  'Itaitinga - CE': null,
  'Itapage - CE': null,
  'Itapipoca - CE': null,
  'Itapiuna - CE': null,
  'Itarema - CE': null,
  'Itatira - CE': null,
  'Jaguaretama - CE': null,
  'Jaguaribara - CE': null,
  'Jaguaribe - CE': null,
  'Jaguaruana - CE': null,
  'Jardim - CE': null,
  'Jati - CE': null,
  'Jijoca de Jericoacoara - CE': null,
  'Juazeiro do Norte - CE': null,
  'Jucas - CE': null,
  'Lavras da Mangabeira - CE': null,
  'Limoeiro do Norte - CE': null,
  'Madalena - CE': null,
  'Maracanau - CE': null,
  'Maranguape - CE': null,
  'Marco - CE': null,
  'Martinopole - CE': null,
  'Massape - CE': null,
  'Mauriti - CE': null,
  'Meruoca - CE': null,
  'Milagres - CE': null,
  'Milha - CE': null,
  'Miraima - CE': null,
  'Missao Velha - CE': null,
  'Mombaca - CE': null,
  'Monsenhor Tabosa - CE': null,
  'Morada Nova - CE': null,
  'Moraujo - CE': null,
  'Morrinhos - CE': null,
  'Mucambo - CE': null,
  'Mulungu - CE': null,
  'Nova Olinda - CE': null,
  'Nova Russas - CE': null,
  'Novo Oriente - CE': null,
  'Ocara - CE': null,
  'Oros - CE': null,
  'Pacajus - CE': null,
  'Pacatuba - CE': null,
  'Pacoti - CE': null,
  'Pacuja - CE': null,
  'Palhano - CE': null,
  'Palmacia - CE': null,
  'Paracuru - CE': null,
  'Paraipaba - CE': null,
  'Parambu - CE': null,
  'Paramoti - CE': null,
  'Pedra Branca - CE': null,
  'Penaforte - CE': null,
  'Pentecoste - CE': null,
  'Pereiro - CE': null,
  'Pindoretama - CE': null,
  'Piquet Carneiro - CE': null,
  'Pires Ferreira - CE': null,
  'Poranga - CE': null,
  'Porteiras - CE': null,
  'Potengi - CE': null,
  'Potiretama - CE': null,
  'Quiterianopolis - CE': null,
  'Quixada - CE': null,
  'Quixelo - CE': null,
  'Quixeramobim - CE': null,
  'Quixere - CE': null,
  'Redencao - CE': null,
  'Reriutaba - CE': null,
  'Russas - CE': null,
  'Saboeiro - CE': null,
  'Salitre - CE': null,
  'Santana do Acarau - CE': null,
  'Santana do Cariri - CE': null,
  'Santa Quiteria - CE': null,
  'Sao Benedito - CE': null,
  'Sao Goncalo do Amarante - CE': null,
  'Sao Joao do Jaguaribe - CE': null,
  'Sao Luis do Curu - CE': null,
  'Senador Pompeu - CE': null,
  'Senador Sa - CE': null,
  'Sobral - CE': null,
  'Solonopole - CE': null,
  'Tabuleiro do Norte - CE': null,
  'Tamboril - CE': null,
  'Tarrafas - CE': null,
  'Taua - CE': null,
  'Tejucuoca - CE': null,
  'Tiangua - CE': null,
  'Trairi - CE': null,
  'Tururu - CE': null,
  'Ubajara - CE': null,
  'Umari - CE': null,
  'Umirim - CE': null,
  'Uruburetama - CE': null,
  'Uruoca - CE': null,
  'Varjota - CE': null,
  'Varzea Alegre - CE': null,
  'Vicosa do Ceara - CE': null,
  'Acari - RN': null,
  'Acu - RN': null,
  'Afonso Bezerra - RN': null,
  'Agua Nova - RN': null,
  'Alexandria - RN': null,
  'Almino Afonso - RN': null,
  'Alto do Rodrigues - RN': null,
  'Angicos - RN': null,
  'Antonio Martins - RN': null,
  'Apodi - RN': null,
  'Areia Branca - RN': null,
  'Ares - RN': null,
  'Augusto Severo - RN': null,
  'Baia Formosa - RN': null,
  'Barauna - RN': null,
  'Barcelona - RN': null,
  'Bento Fernandes - RN': null,
  'Bodo - RN': null,
  'Bom Jesus - RN': null,
  'Brejinho - RN': null,
  'Caicara do Norte - RN': null,
  'Caicara do Rio do Vento - RN': null,
  'Caico - RN': null,
  'Campo Redondo - RN': null,
  'Canguaretama - RN': null,
  'Caraubas - RN': null,
  'Carnauba dos Dantas - RN': null,
  'Carnaubais - RN': null,
  'Ceara-mirim - RN': null,
  'Cerro Cora - RN': null,
  'Coronel Ezequiel - RN': null,
  'Coronel Joao Pessoa - RN': null,
  'Cruzeta - RN': null,
  'Currais Novos - RN': null,
  'Doutor Severiano - RN': null,
  'Parnamirim - RN': null,
  'Encanto - RN': null,
  'Equador - RN': null,
  'Espirito Santo - RN': null,
  'Extremoz - RN': null,
  'Felipe Guerra - RN': null,
  'Fernando Pedroza - RN': null,
  'Florania - RN': null,
  'Francisco Dantas - RN': null,
  'Frutuoso Gomes - RN': null,
  'Galinhos - RN': null,
  'Goianinha - RN': null,
  'Governador Dix-sept Rosado - RN': null,
  'Grossos - RN': null,
  'Guamare - RN': null,
  'Ielmo Marinho - RN': null,
  'Ipanguacu - RN': null,
  'Ipueira - RN': null,
  'Itaja - RN': null,
  'Itau - RN': null,
  'Jacana - RN': null,
  'Jandaira - RN': null,
  'Janduis - RN': null,
  'Januario Cicco - RN': null,
  'Japi - RN': null,
  'Jardim de Angicos - RN': null,
  'Jardim de Piranhas - RN': null,
  'Jardim do Serido - RN': null,
  'Joao Camara - RN': null,
  'Joao Dias - RN': null,
  'Jose da Penha - RN': null,
  'Jucurutu - RN': null,
  'Jundia - RN': null,
  "Lagoa D'Anta - RN": null,
  'Lagoa de Pedras - RN': null,
  'Lagoa de Velhos - RN': null,
  'Lagoa Nova - RN': null,
  'Lagoa Salgada - RN': null,
  'Lajes - RN': null,
  'Lajes Pintadas - RN': null,
  'Lucrecia - RN': null,
  'Luis Gomes - RN': null,
  'Macaiba - RN': null,
  'Macau - RN': null,
  'Major Sales - RN': null,
  'Marcelino Vieira - RN': null,
  'Martins - RN': null,
  'Maxaranguape - RN': null,
  'Messias Targino - RN': null,
  'Montanhas - RN': null,
  'Monte Alegre - RN': null,
  'Monte das Gameleiras - RN': null,
  'Mossoro - RN': null,
  'Natal - RN': null,
  'Nisia Floresta - RN': null,
  'Nova Cruz - RN': null,
  "Olho D'Agua do Borges - RN": null,
  'Ouro Branco - RN': null,
  'Parana - RN': null,
  'Parau - RN': null,
  'Parazinho - RN': null,
  'Parelhas - RN': null,
  'Rio do Fogo - RN': null,
  'Passa E Fica - RN': null,
  'Passagem - RN': null,
  'Patu - RN': null,
  'Santa Maria - RN': null,
  'Pau dos Ferros - RN': null,
  'Pedra Grande - RN': null,
  'Pedra Preta - RN': null,
  'Pedro Avelino - RN': null,
  'Pedro Velho - RN': null,
  'Pendencias - RN': null,
  'Piloes - RN': null,
  'Poco Branco - RN': null,
  'Portalegre - RN': null,
  'Porto do Mangue - RN': null,
  'Presidente Juscelino - RN': null,
  'Pureza - RN': null,
  'Rafael Fernandes - RN': null,
  'Rafael Godeiro - RN': null,
  'Riacho da Cruz - RN': null,
  'Riacho de Santana - RN': null,
  'Riachuelo - RN': null,
  'Rodolfo Fernandes - RN': null,
  'Tibau - RN': null,
  'Ruy Barbosa - RN': null,
  'Santa Cruz - RN': null,
  'Santana do Matos - RN': null,
  'Santana do Serido - RN': null,
  'Santo Antonio - RN': null,
  'Sao Bento do Norte - RN': null,
  'Sao Bento do Trairi - RN': null,
  'Sao Fernando - RN': null,
  'Sao Francisco do Oeste - RN': null,
  'Sao Goncalo do Amarante - RN': null,
  'Sao Joao do Sabugi - RN': null,
  'Sao Jose de Mipibu - RN': null,
  'Sao Jose do Campestre - RN': null,
  'Sao Jose do Serido - RN': null,
  'Sao Miguel - RN': null,
  'Sao Miguel do Gostoso - RN': null,
  'Sao Paulo do Potengi - RN': null,
  'Sao Pedro - RN': null,
  'Sao Rafael - RN': null,
  'Sao Tome - RN': null,
  'Sao Vicente - RN': null,
  'Senador Eloi de Souza - RN': null,
  'Senador Georgino Avelino - RN': null,
  'Serra de Sao Bento - RN': null,
  'Serra do Mel - RN': null,
  'Serra Negra do Norte - RN': null,
  'Serrinha - RN': null,
  'Serrinha dos Pintos - RN': null,
  'Severiano Melo - RN': null,
  'Sitio Novo - RN': null,
  'Taboleiro Grande - RN': null,
  'Taipu - RN': null,
  'Tangara - RN': null,
  'Tenente Ananias - RN': null,
  'Tenente Laurentino Cruz - RN': null,
  'Tibau do Sul - RN': null,
  'Timbauba dos Batistas - RN': null,
  'Touros - RN': null,
  'Triunfo Potiguar - RN': null,
  'Umarizal - RN': null,
  'Upanema - RN': null,
  'Varzea - RN': null,
  'Venha-ver - RN': null,
  'Vera Cruz - RN': null,
  'Vicosa - RN': null,
  'Vila Flor - RN': null,
  'Agua Branca - PB': null,
  'Aguiar - PB': null,
  'Alagoa Grande - PB': null,
  'Alagoa Nova - PB': null,
  'Alagoinha - PB': null,
  'Alcantil - PB': null,
  'Algodao de Jandaira - PB': null,
  'Alhandra - PB': null,
  'Sao Joao do Rio do Peixe - PB': null,
  'Amparo - PB': null,
  'Aparecida - PB': null,
  'Aracagi - PB': null,
  'Arara - PB': null,
  'Araruna - PB': null,
  'Areia - PB': null,
  'Areia de Baraunas - PB': null,
  'Areial - PB': null,
  'Aroeiras - PB': null,
  'Assuncao - PB': null,
  'Baia da Traicao - PB': null,
  'Bananeiras - PB': null,
  'Barauna - PB': null,
  'Barra de Santana - PB': null,
  'Barra de Santa Rosa - PB': null,
  'Barra de Sao Miguel - PB': null,
  'Bayeux - PB': null,
  'Belem - PB': null,
  'Belem do Brejo do Cruz - PB': null,
  'Bernardino Batista - PB': null,
  'Boa Ventura - PB': null,
  'Boa Vista - PB': null,
  'Bom Jesus - PB': null,
  'Bom Sucesso - PB': null,
  'Bonito de Santa Fe - PB': null,
  'Boqueirao - PB': null,
  'Igaracy - PB': null,
  'Borborema - PB': null,
  'Brejo do Cruz - PB': null,
  'Brejo dos Santos - PB': null,
  'Caapora - PB': null,
  'Cabaceiras - PB': null,
  'Cabedelo - PB': null,
  'Cachoeira dos Indios - PB': null,
  'Cacimba de Areia - PB': null,
  'Cacimba de Dentro - PB': null,
  'Cacimbas - PB': null,
  'Caicara - PB': null,
  'Cajazeiras - PB': null,
  'Cajazeirinhas - PB': null,
  'Caldas Brandao - PB': null,
  'Camalau - PB': null,
  'Campina Grande - PB': null,
  'Capim - PB': null,
  'Caraubas - PB': null,
  'Carrapateira - PB': null,
  'Casserengue - PB': null,
  'Catingueira - PB': null,
  'Catole do Rocha - PB': null,
  'Caturite - PB': null,
  'Conceicao - PB': null,
  'Condado - PB': null,
  'Conde - PB': null,
  'Congo - PB': null,
  'Coremas - PB': null,
  'Coxixola - PB': null,
  'Cruz do Espirito Santo - PB': null,
  'Cubati - PB': null,
  'Cuite - PB': null,
  'Cuitegi - PB': null,
  'Cuite de Mamanguape - PB': null,
  'Curral de Cima - PB': null,
  'Curral Velho - PB': null,
  'Damiao - PB': null,
  'Desterro - PB': null,
  'Vista Serrana - PB': null,
  'Diamante - PB': null,
  'Dona Ines - PB': null,
  'Duas Estradas - PB': null,
  'Emas - PB': null,
  'Esperanca - PB': null,
  'Fagundes - PB': null,
  'Frei Martinho - PB': null,
  'Gado Bravo - PB': null,
  'Guarabira - PB': null,
  'Gurinhem - PB': null,
  'Gurjao - PB': null,
  'Ibiara - PB': null,
  'Imaculada - PB': null,
  'Inga - PB': null,
  'Itabaiana - PB': null,
  'Itaporanga - PB': null,
  'Itapororoca - PB': null,
  'Itatuba - PB': null,
  'Jacarau - PB': null,
  'Jerico - PB': null,
  'Joao Pessoa - PB': null,
  'Juarez Tavora - PB': null,
  'Juazeirinho - PB': null,
  'Junco do Serido - PB': null,
  'Juripiranga - PB': null,
  'Juru - PB': null,
  'Lagoa - PB': null,
  'Lagoa de Dentro - PB': null,
  'Lagoa Seca - PB': null,
  'Lastro - PB': null,
  'Livramento - PB': null,
  'Logradouro - PB': null,
  'Lucena - PB': null,
  "Mae D'Agua - PB": null,
  'Malta - PB': null,
  'Mamanguape - PB': null,
  'Manaira - PB': null,
  'Marcacao - PB': null,
  'Mari - PB': null,
  'Marizopolis - PB': null,
  'Massaranduba - PB': null,
  'Mataraca - PB': null,
  'Matinhas - PB': null,
  'Mato Grosso - PB': null,
  'Matureia - PB': null,
  'Mogeiro - PB': null,
  'Montadas - PB': null,
  'Monte Horebe - PB': null,
  'Monteiro - PB': null,
  'Mulungu - PB': null,
  'Natuba - PB': null,
  'Nazarezinho - PB': null,
  'Nova Floresta - PB': null,
  'Nova Olinda - PB': null,
  'Nova Palmeira - PB': null,
  "Olho D'Agua - PB": null,
  'Olivedos - PB': null,
  'Ouro Velho - PB': null,
  'Parari - PB': null,
  'Passagem - PB': null,
  'Patos - PB': null,
  'Paulista - PB': null,
  'Pedra Branca - PB': null,
  'Pedra Lavrada - PB': null,
  'Pedras de Fogo - PB': null,
  'Pianco - PB': null,
  'Picui - PB': null,
  'Pilar - PB': null,
  'Piloes - PB': null,
  'Piloezinhos - PB': null,
  'Pirpirituba - PB': null,
  'Pitimbu - PB': null,
  'Pocinhos - PB': null,
  'Poco Dantas - PB': null,
  'Poco de Jose de Moura - PB': null,
  'Pombal - PB': null,
  'Prata - PB': null,
  'Princesa Isabel - PB': null,
  'Puxinana - PB': null,
  'Queimadas - PB': null,
  'Quixaba - PB': null,
  'Remigio - PB': null,
  'Pedro Regis - PB': null,
  'Riachao - PB': null,
  'Riachao do Bacamarte - PB': null,
  'Riachao do Poco - PB': null,
  'Riacho de Santo Antonio - PB': null,
  'Riacho dos Cavalos - PB': null,
  'Rio Tinto - PB': null,
  'Salgadinho - PB': null,
  'Salgado de Sao Felix - PB': null,
  'Santa Cecilia - PB': null,
  'Santa Cruz - PB': null,
  'Santa Helena - PB': null,
  'Santa Ines - PB': null,
  'Santa Luzia - PB': null,
  'Santana de Mangueira - PB': null,
  'Santana dos Garrotes - PB': null,
  'Santarem - PB': null,
  'Santa Rita - PB': null,
  'Santa Teresinha - PB': null,
  'Santo Andre - PB': null,
  'Sao Bento - PB': null,
  'Sao Bentinho - PB': null,
  'Sao Domingos do Cariri - PB': null,
  'Sao Domingos - PB': null,
  'Sao Francisco - PB': null,
  'Sao Joao do Cariri - PB': null,
  'Sao Joao do Tigre - PB': null,
  'Sao Jose da Lagoa Tapada - PB': null,
  'Sao Jose de Caiana - PB': null,
  'Sao Jose de Espinharas - PB': null,
  'Sao Jose dos Ramos - PB': null,
  'Sao Jose de Piranhas - PB': null,
  'Sao Jose de Princesa - PB': null,
  'Sao Jose do Bonfim - PB': null,
  'Sao Jose do Brejo do Cruz - PB': null,
  'Sao Jose do Sabugi - PB': null,
  'Sao Jose dos Cordeiros - PB': null,
  'Sao Mamede - PB': null,
  'Sao Miguel de Taipu - PB': null,
  'Sao Sebastiao de Lagoa de Roca - PB': null,
  'Sao Sebastiao do Umbuzeiro - PB': null,
  'Sape - PB': null,
  'Serido - PB': null,
  'Serra Branca - PB': null,
  'Serra da Raiz - PB': null,
  'Serra Grande - PB': null,
  'Serra Redonda - PB': null,
  'Serraria - PB': null,
  'Sertaozinho - PB': null,
  'Sobrado - PB': null,
  'Solanea - PB': null,
  'Soledade - PB': null,
  'Sossego - PB': null,
  'Sousa - PB': null,
  'Sume - PB': null,
  'Campo de Santana - PB': null,
  'Taperoa - PB': null,
  'Tavares - PB': null,
  'Teixeira - PB': null,
  'Tenorio - PB': null,
  'Triunfo - PB': null,
  'Uirauna - PB': null,
  'Umbuzeiro - PB': null,
  'Varzea - PB': null,
  'Vieiropolis - PB': null,
  'Zabele - PB': null,
  'Abreu E Lima - PE': null,
  'Afogados da Ingazeira - PE': null,
  'Afranio - PE': null,
  'Agrestina - PE': null,
  'Agua Preta - PE': null,
  'Aguas Belas - PE': null,
  'Alagoinha - PE': null,
  'Alianca - PE': null,
  'Altinho - PE': null,
  'Amaraji - PE': null,
  'Angelim - PE': null,
  'Aracoiaba - PE': null,
  'Araripina - PE': null,
  'Arcoverde - PE': null,
  'Barra de Guabiraba - PE': null,
  'Barreiros - PE': null,
  'Belem de Maria - PE': null,
  'Belem de Sao Francisco - PE': null,
  'Belo Jardim - PE': null,
  'Betania - PE': null,
  'Bezerros - PE': null,
  'Bodoco - PE': null,
  'Bom Conselho - PE': null,
  'Bom Jardim - PE': null,
  'Bonito - PE': null,
  'Brejao - PE': null,
  'Brejinho - PE': null,
  'Brejo da Madre de Deus - PE': null,
  'Buenos Aires - PE': null,
  'Buique - PE': null,
  'Cabo de Santo Agostinho - PE': null,
  'Cabrobo - PE': null,
  'Cachoeirinha - PE': null,
  'Caetes - PE': null,
  'Calcado - PE': null,
  'Calumbi - PE': null,
  'Camaragibe - PE': null,
  'Camocim de Sao Felix - PE': null,
  'Camutanga - PE': null,
  'Canhotinho - PE': null,
  'Capoeiras - PE': null,
  'Carnaiba - PE': null,
  'Carnaubeira da Penha - PE': null,
  'Carpina - PE': null,
  'Caruaru - PE': null,
  'Casinhas - PE': null,
  'Catende - PE': null,
  'Cedro - PE': null,
  'Cha de Alegria - PE': null,
  'Cha Grande - PE': null,
  'Condado - PE': null,
  'Correntes - PE': null,
  'Cortes - PE': null,
  'Cumaru - PE': null,
  'Cupira - PE': null,
  'Custodia - PE': null,
  'Dormentes - PE': null,
  'Escada - PE': null,
  'Exu - PE': null,
  'Feira Nova - PE': null,
  'Fernando de Noronha - PE': null,
  'Ferreiros - PE': null,
  'Flores - PE': null,
  'Floresta - PE': null,
  'Frei Miguelinho - PE': null,
  'Gameleira - PE': null,
  'Garanhuns - PE': null,
  'Gloria do Goita - PE': null,
  'Goiana - PE': null,
  'Granito - PE': null,
  'Gravata - PE': null,
  'Iati - PE': null,
  'Ibimirim - PE': null,
  'Ibirajuba - PE': null,
  'Igarassu - PE': null,
  'Iguaraci - PE': null,
  'Inaja - PE': null,
  'Ingazeira - PE': null,
  'Ipojuca - PE': null,
  'Ipubi - PE': null,
  'Itacuruba - PE': null,
  'Itaiba - PE': null,
  'Ilha de Itamaraca - PE': null,
  'Itambe - PE': null,
  'Itapetim - PE': null,
  'Itapissuma - PE': null,
  'Itaquitinga - PE': null,
  'Jaboatao dos Guararapes - PE': null,
  'Jaqueira - PE': null,
  'Jatauba - PE': null,
  'Jatoba - PE': null,
  'Joao Alfredo - PE': null,
  'Joaquim Nabuco - PE': null,
  'Jucati - PE': null,
  'Jupi - PE': null,
  'Jurema - PE': null,
  'Lagoa do Carro - PE': null,
  'Lagoa do Itaenga - PE': null,
  'Lagoa do Ouro - PE': null,
  'Lagoa dos Gatos - PE': null,
  'Lagoa Grande - PE': null,
  'Lajedo - PE': null,
  'Limoeiro - PE': null,
  'Macaparana - PE': null,
  'Machados - PE': null,
  'Manari - PE': null,
  'Maraial - PE': null,
  'Mirandiba - PE': null,
  'Moreno - PE': null,
  'Nazare da Mata - PE': null,
  'Olinda - PE': null,
  'Orobo - PE': null,
  'Oroco - PE': null,
  'Ouricuri - PE': null,
  'Palmares - PE': null,
  'Palmeirina - PE': null,
  'Panelas - PE': null,
  'Paranatama - PE': null,
  'Parnamirim - PE': null,
  'Passira - PE': null,
  'Paudalho - PE': null,
  'Paulista - PE': null,
  'Pedra - PE': null,
  'Pesqueira - PE': null,
  'Petrolandia - PE': null,
  'Petrolina - PE': null,
  'Pocao - PE': null,
  'Pombos - PE': null,
  'Primavera - PE': null,
  'Quipapa - PE': null,
  'Quixaba - PE': null,
  'Recife - PE': null,
  'Riacho das Almas - PE': null,
  'Ribeirao - PE': null,
  'Rio Formoso - PE': null,
  'Saire - PE': null,
  'Salgadinho - PE': null,
  'Salgueiro - PE': null,
  'Saloa - PE': null,
  'Sanharo - PE': null,
  'Santa Cruz - PE': null,
  'Santa Cruz da Baixa Verde - PE': null,
  'Santa Cruz do Capibaribe - PE': null,
  'Santa Filomena - PE': null,
  'Santa Maria da Boa Vista - PE': null,
  'Santa Maria do Cambuca - PE': null,
  'Santa Terezinha - PE': null,
  'Sao Benedito do Sul - PE': null,
  'Sao Bento do Una - PE': null,
  'Sao Caitano - PE': null,
  'Sao Joao - PE': null,
  'Sao Joaquim do Monte - PE': null,
  'Sao Jose da Coroa Grande - PE': null,
  'Sao Jose do Belmonte - PE': null,
  'Sao Jose do Egito - PE': null,
  'Sao Lourenco da Mata - PE': null,
  'Sao Vicente Ferrer - PE': null,
  'Serra Talhada - PE': null,
  'Serrita - PE': null,
  'Sertania - PE': null,
  'Sirinhaem - PE': null,
  'Moreilandia - PE': null,
  'Solidao - PE': null,
  'Surubim - PE': null,
  'Tabira - PE': null,
  'Tacaimbo - PE': null,
  'Tacaratu - PE': null,
  'Tamandare - PE': null,
  'Taquaritinga do Norte - PE': null,
  'Terezinha - PE': null,
  'Terra Nova - PE': null,
  'Timbauba - PE': null,
  'Toritama - PE': null,
  'Tracunhaem - PE': null,
  'Trindade - PE': null,
  'Triunfo - PE': null,
  'Tupanatinga - PE': null,
  'Tuparetama - PE': null,
  'Venturosa - PE': null,
  'Verdejante - PE': null,
  'Vertente do Lerio - PE': null,
  'Vertentes - PE': null,
  'Vicencia - PE': null,
  'Vitoria de Santo Antao - PE': null,
  'Xexeu - PE': null,
  'Agua Branca - AL': null,
  'Anadia - AL': null,
  'Arapiraca - AL': null,
  'Atalaia - AL': null,
  'Barra de Santo Antonio - AL': null,
  'Barra de Sao Miguel - AL': null,
  'Batalha - AL': null,
  'Belem - AL': null,
  'Belo Monte - AL': null,
  'Boca da Mata - AL': null,
  'Branquinha - AL': null,
  'Cacimbinhas - AL': null,
  'Cajueiro - AL': null,
  'Campestre - AL': null,
  'Campo Alegre - AL': null,
  'Campo Grande - AL': null,
  'Canapi - AL': null,
  'Capela - AL': null,
  'Carneiros - AL': null,
  'Cha Preta - AL': null,
  'Coite do Noia - AL': null,
  'Colonia Leopoldina - AL': null,
  'Coqueiro Seco - AL': null,
  'Coruripe - AL': null,
  'Craibas - AL': null,
  'Delmiro Gouveia - AL': null,
  'Dois Riachos - AL': null,
  'Estrela de Alagoas - AL': null,
  'Feira Grande - AL': null,
  'Feliz Deserto - AL': null,
  'Flexeiras - AL': null,
  'Girau do Ponciano - AL': null,
  'Ibateguara - AL': null,
  'Igaci - AL': null,
  'Igreja Nova - AL': null,
  'Inhapi - AL': null,
  'Jacare dos Homens - AL': null,
  'Jacuipe - AL': null,
  'Japaratinga - AL': null,
  'Jaramataia - AL': null,
  'Jequia da Praia - AL': null,
  'Joaquim Gomes - AL': null,
  'Jundia - AL': null,
  'Junqueiro - AL': null,
  'Lagoa da Canoa - AL': null,
  'Limoeiro de Anadia - AL': null,
  'Maceio - AL': null,
  'Major Isidoro - AL': null,
  'Maragogi - AL': null,
  'Maravilha - AL': null,
  'Marechal Deodoro - AL': null,
  'Maribondo - AL': null,
  'Mar Vermelho - AL': null,
  'Mata Grande - AL': null,
  'Matriz de Camaragibe - AL': null,
  'Messias - AL': null,
  'Minador do Negrao - AL': null,
  'Monteiropolis - AL': null,
  'Murici - AL': null,
  'Novo Lino - AL': null,
  "Olho D'Agua das Flores - AL": null,
  "Olho D'Agua do Casado - AL": null,
  "Olho D'Agua Grande - AL": null,
  'Olivenca - AL': null,
  'Ouro Branco - AL': null,
  'Palestina - AL': null,
  'Palmeira dos Indios - AL': null,
  'Pao de Acucar - AL': null,
  'Pariconha - AL': null,
  'Paripueira - AL': null,
  'Passo de Camaragibe - AL': null,
  'Paulo Jacinto - AL': null,
  'Penedo - AL': null,
  'Piacabucu - AL': null,
  'Pilar - AL': null,
  'Pindoba - AL': null,
  'Piranhas - AL': null,
  'Poco das Trincheiras - AL': null,
  'Porto Calvo - AL': null,
  'Porto de Pedras - AL': null,
  'Porto Real do Colegio - AL': null,
  'Quebrangulo - AL': null,
  'Rio Largo - AL': null,
  'Roteiro - AL': null,
  'Santa Luzia do Norte - AL': null,
  'Santana do Ipanema - AL': null,
  'Santana do Mundau - AL': null,
  'Sao Bras - AL': null,
  'Sao Jose da Laje - AL': null,
  'Sao Jose da Tapera - AL': null,
  'Sao Luis do Quitunde - AL': null,
  'Sao Miguel dos Campos - AL': null,
  'Sao Miguel dos Milagres - AL': null,
  'Sao Sebastiao - AL': null,
  'Satuba - AL': null,
  'Senador Rui Palmeira - AL': null,
  "Tanque D'Arca - AL": null,
  'Taquarana - AL': null,
  'Teotonio Vilela - AL': null,
  'Traipu - AL': null,
  'Uniao dos Palmares - AL': null,
  'Vicosa - AL': null,
  'Amparo de Sao Francisco - SE': null,
  'Aquidaba - SE': null,
  'Aracaju - SE': null,
  'Araua - SE': null,
  'Areia Branca - SE': null,
  'Barra dos Coqueiros - SE': null,
  'Boquim - SE': null,
  'Brejo Grande - SE': null,
  'Campo do Brito - SE': null,
  'Canhoba - SE': null,
  'Caninde de Sao Francisco - SE': null,
  'Capela - SE': null,
  'Carira - SE': null,
  'Carmopolis - SE': null,
  'Cedro de Sao Joao - SE': null,
  'Cristinapolis - SE': null,
  'Cumbe - SE': null,
  'Divina Pastora - SE': null,
  'Estancia - SE': null,
  'Feira Nova - SE': null,
  'Frei Paulo - SE': null,
  'Gararu - SE': null,
  'General Maynard - SE': null,
  'Gracho Cardoso - SE': null,
  'Ilha das Flores - SE': null,
  'Indiaroba - SE': null,
  'Itabaiana - SE': null,
  'Itabaianinha - SE': null,
  'Itabi - SE': null,
  "Itaporanga D'Ajuda - SE": null,
  'Japaratuba - SE': null,
  'Japoata - SE': null,
  'Lagarto - SE': null,
  'Laranjeiras - SE': null,
  'Macambira - SE': null,
  'Malhada dos Bois - SE': null,
  'Malhador - SE': null,
  'Maruim - SE': null,
  'Moita Bonita - SE': null,
  'Monte Alegre de Sergipe - SE': null,
  'Muribeca - SE': null,
  'Neopolis - SE': null,
  'Nossa Senhora Aparecida - SE': null,
  'Nossa Senhora da Gloria - SE': null,
  'Nossa Senhora das Dores - SE': null,
  'Nossa Senhora de Lourdes - SE': null,
  'Nossa Senhora do Socorro - SE': null,
  'Pacatuba - SE': null,
  'Pedra Mole - SE': null,
  'Pedrinhas - SE': null,
  'Pinhao - SE': null,
  'Pirambu - SE': null,
  'Poco Redondo - SE': null,
  'Poco Verde - SE': null,
  'Porto da Folha - SE': null,
  'Propria - SE': null,
  'Riachao do Dantas - SE': null,
  'Riachuelo - SE': null,
  'Ribeiropolis - SE': null,
  'Rosario do Catete - SE': null,
  'Salgado - SE': null,
  'Santa Luzia do Itanhy - SE': null,
  'Santana do Sao Francisco - SE': null,
  'Santa Rosa de Lima - SE': null,
  'Santo Amaro das Brotas - SE': null,
  'Sao Cristovao - SE': null,
  'Sao Domingos - SE': null,
  'Sao Francisco - SE': null,
  'Sao Miguel do Aleixo - SE': null,
  'Simao Dias - SE': null,
  'Siriri - SE': null,
  'Telha - SE': null,
  'Tobias Barreto - SE': null,
  'Tomar do Geru - SE': null,
  'Umbauba - SE': null,
  'Abaira - BA': null,
  'Abare - BA': null,
  'Acajutiba - BA': null,
  'Adustina - BA': null,
  'Agua Fria - BA': null,
  'Erico Cardoso - BA': null,
  'Aiquara - BA': null,
  'Alagoinhas - BA': null,
  'Alcobaca - BA': null,
  'Almadina - BA': null,
  'Amargosa - BA': null,
  'Amelia Rodrigues - BA': null,
  'America Dourada - BA': null,
  'Anage - BA': null,
  'Andarai - BA': null,
  'Andorinha - BA': null,
  'Angical - BA': null,
  'Anguera - BA': null,
  'Antas - BA': null,
  'Antonio Cardoso - BA': null,
  'Antonio Goncalves - BA': null,
  'Apora - BA': null,
  'Apuarema - BA': null,
  'Aracatu - BA': null,
  'Aracas - BA': null,
  'Araci - BA': null,
  'Aramari - BA': null,
  'Arataca - BA': null,
  'Aratuipe - BA': null,
  'Aurelino Leal - BA': null,
  'Baianopolis - BA': null,
  'Baixa Grande - BA': null,
  'Banzae - BA': null,
  'Barra - BA': null,
  'Barra da Estiva - BA': null,
  'Barra do Choca - BA': null,
  'Barra do Mendes - BA': null,
  'Barra do Rocha - BA': null,
  'Barreiras - BA': null,
  'Barro Alto - BA': null,
  'Barrocas - BA': null,
  'Barro Preto - BA': null,
  'Belmonte - BA': null,
  'Belo Campo - BA': null,
  'Biritinga - BA': null,
  'Boa Nova - BA': null,
  'Boa Vista do Tupim - BA': null,
  'Bom Jesus da Lapa - BA': null,
  'Bom Jesus da Serra - BA': null,
  'Boninal - BA': null,
  'Bonito - BA': null,
  'Boquira - BA': null,
  'Botupora - BA': null,
  'Brejoes - BA': null,
  'Brejolandia - BA': null,
  'Brotas de Macaubas - BA': null,
  'Brumado - BA': null,
  'Buerarema - BA': null,
  'Buritirama - BA': null,
  'Caatiba - BA': null,
  'Cabaceiras do Paraguacu - BA': null,
  'Cachoeira - BA': null,
  'Cacule - BA': null,
  'Caem - BA': null,
  'Caetanos - BA': null,
  'Caetite - BA': null,
  'Cafarnaum - BA': null,
  'Cairu - BA': null,
  'Caldeirao Grande - BA': null,
  'Camacan - BA': null,
  'Camacari - BA': null,
  'Camamu - BA': null,
  'Campo Alegre de Lourdes - BA': null,
  'Campo Formoso - BA': null,
  'Canapolis - BA': null,
  'Canarana - BA': null,
  'Canavieiras - BA': null,
  'Candeal - BA': null,
  'Candeias - BA': null,
  'Candiba - BA': null,
  'Candido Sales - BA': null,
  'Cansancao - BA': null,
  'Canudos - BA': null,
  'Capela do Alto Alegre - BA': null,
  'Capim Grosso - BA': null,
  'Caraibas - BA': null,
  'Caravelas - BA': null,
  'Cardeal da Silva - BA': null,
  'Carinhanha - BA': null,
  'Casa Nova - BA': null,
  'Castro Alves - BA': null,
  'Catolandia - BA': null,
  'Catu - BA': null,
  'Caturama - BA': null,
  'Central - BA': null,
  'Chorrocho - BA': null,
  'Cicero Dantas - BA': null,
  'Cipo - BA': null,
  'Coaraci - BA': null,
  'Cocos - BA': null,
  'Conceicao da Feira - BA': null,
  'Conceicao do Almeida - BA': null,
  'Conceicao do Coite - BA': null,
  'Conceicao do Jacuipe - BA': null,
  'Conde - BA': null,
  'Condeuba - BA': null,
  'Contendas do Sincora - BA': null,
  'Coracao de Maria - BA': null,
  'Cordeiros - BA': null,
  'Coribe - BA': null,
  'Coronel Joao Sa - BA': null,
  'Correntina - BA': null,
  'Cotegipe - BA': null,
  'Cravolandia - BA': null,
  'Crisopolis - BA': null,
  'Cristopolis - BA': null,
  'Cruz das Almas - BA': null,
  'Curaca - BA': null,
  'Dario Meira - BA': null,
  "Dias D'Avila - BA": null,
  'Dom Basilio - BA': null,
  'Dom Macedo Costa - BA': null,
  'Elisio Medrado - BA': null,
  'Encruzilhada - BA': null,
  'Entre Rios - BA': null,
  'Esplanada - BA': null,
  'Euclides da Cunha - BA': null,
  'Eunapolis - BA': null,
  'Fatima - BA': null,
  'Feira da Mata - BA': null,
  'Feira de Santana - BA': null,
  'Filadelfia - BA': null,
  'Firmino Alves - BA': null,
  'Floresta Azul - BA': null,
  'Formosa do Rio Preto - BA': null,
  'Gandu - BA': null,
  'Gaviao - BA': null,
  'Gentio do Ouro - BA': null,
  'Gloria - BA': null,
  'Gongogi - BA': null,
  'Governador Mangabeira - BA': null,
  'Guajeru - BA': null,
  'Guanambi - BA': null,
  'Guaratinga - BA': null,
  'Heliopolis - BA': null,
  'Iacu - BA': null,
  'Ibiassuce - BA': null,
  'Ibicarai - BA': null,
  'Ibicoara - BA': null,
  'Ibicui - BA': null,
  'Ibipeba - BA': null,
  'Ibipitanga - BA': null,
  'Ibiquera - BA': null,
  'Ibirapitanga - BA': null,
  'Ibirapua - BA': null,
  'Ibirataia - BA': null,
  'Ibitiara - BA': null,
  'Ibitita - BA': null,
  'Ibotirama - BA': null,
  'Ichu - BA': null,
  'Igapora - BA': null,
  'Igrapiuna - BA': null,
  'Iguai - BA': null,
  'Ilheus - BA': null,
  'Inhambupe - BA': null,
  'Ipecaeta - BA': null,
  'Ipiau - BA': null,
  'Ipira - BA': null,
  'Ipupiara - BA': null,
  'Irajuba - BA': null,
  'Iramaia - BA': null,
  'Iraquara - BA': null,
  'Irara - BA': null,
  'Irece - BA': null,
  'Itabela - BA': null,
  'Itaberaba - BA': null,
  'Itabuna - BA': null,
  'Itacare - BA': null,
  'Itaete - BA': null,
  'Itagi - BA': null,
  'Itagiba - BA': null,
  'Itagimirim - BA': null,
  'Itaguacu da Bahia - BA': null,
  'Itaju do Colonia - BA': null,
  'Itajuipe - BA': null,
  'Itamaraju - BA': null,
  'Itamari - BA': null,
  'Itambe - BA': null,
  'Itanagra - BA': null,
  'Itanhem - BA': null,
  'Itaparica - BA': null,
  'Itape - BA': null,
  'Itapebi - BA': null,
  'Itapetinga - BA': null,
  'Itapicuru - BA': null,
  'Itapitanga - BA': null,
  'Itaquara - BA': null,
  'Itarantim - BA': null,
  'Itatim - BA': null,
  'Itirucu - BA': null,
  'Itiuba - BA': null,
  'Itororo - BA': null,
  'Ituacu - BA': null,
  'Itubera - BA': null,
  'Iuiu - BA': null,
  'Jaborandi - BA': null,
  'Jacaraci - BA': null,
  'Jacobina - BA': null,
  'Jaguaquara - BA': null,
  'Jaguarari - BA': null,
  'Jaguaripe - BA': null,
  'Jandaira - BA': null,
  'Jequie - BA': null,
  'Jeremoabo - BA': null,
  'Jiquirica - BA': null,
  'Jitauna - BA': null,
  'Joao Dourado - BA': null,
  'Juazeiro - BA': null,
  'Jucurucu - BA': null,
  'Jussara - BA': null,
  'Jussari - BA': null,
  'Jussiape - BA': null,
  'Lafaiete Coutinho - BA': null,
  'Lagoa Real - BA': null,
  'Laje - BA': null,
  'Lajedao - BA': null,
  'Lajedinho - BA': null,
  'Lajedo do Tabocal - BA': null,
  'Lamarao - BA': null,
  'Lapao - BA': null,
  'Lauro de Freitas - BA': null,
  'Lencois - BA': null,
  'Licinio de Almeida - BA': null,
  'Livramento de Nossa Senhora - BA': null,
  'Luis Eduardo Magalhaes - BA': null,
  'Macajuba - BA': null,
  'Macarani - BA': null,
  'Macaubas - BA': null,
  'Macurure - BA': null,
  'Madre de Deus - BA': null,
  'Maetinga - BA': null,
  'Maiquinique - BA': null,
  'Mairi - BA': null,
  'Malhada - BA': null,
  'Malhada de Pedras - BA': null,
  'Manoel Vitorino - BA': null,
  'Mansidao - BA': null,
  'Maracas - BA': null,
  'Maragogipe - BA': null,
  'Marau - BA': null,
  'Marcionilio Souza - BA': null,
  'Mascote - BA': null,
  'Mata de Sao Joao - BA': null,
  'Matina - BA': null,
  'Medeiros Neto - BA': null,
  'Miguel Calmon - BA': null,
  'Milagres - BA': null,
  'Mirangaba - BA': null,
  'Mirante - BA': null,
  'Monte Santo - BA': null,
  'Morpara - BA': null,
  'Morro do Chapeu - BA': null,
  'Mortugaba - BA': null,
  'Mucuge - BA': null,
  'Mucuri - BA': null,
  'Mulungu do Morro - BA': null,
  'Mundo Novo - BA': null,
  'Muniz Ferreira - BA': null,
  'Muquem de Sao Francisco - BA': null,
  'Muritiba - BA': null,
  'Mutuipe - BA': null,
  'Nazare - BA': null,
  'Nilo Pecanha - BA': null,
  'Nordestina - BA': null,
  'Nova Canaa - BA': null,
  'Nova Fatima - BA': null,
  'Nova Ibia - BA': null,
  'Nova Itarana - BA': null,
  'Nova Redencao - BA': null,
  'Nova Soure - BA': null,
  'Nova Vicosa - BA': null,
  'Novo Horizonte - BA': null,
  'Novo Triunfo - BA': null,
  'Olindina - BA': null,
  'Oliveira dos Brejinhos - BA': null,
  'Ouricangas - BA': null,
  'Ourolandia - BA': null,
  'Palmas de Monte Alto - BA': null,
  'Palmeiras - BA': null,
  'Paramirim - BA': null,
  'Paratinga - BA': null,
  'Paripiranga - BA': null,
  'Pau Brasil - BA': null,
  'Paulo Afonso - BA': null,
  'Pe de Serra - BA': null,
  'Pedrao - BA': null,
  'Pedro Alexandre - BA': null,
  'Piata - BA': null,
  'Pilao Arcado - BA': null,
  'Pindai - BA': null,
  'Pindobacu - BA': null,
  'Pintadas - BA': null,
  'Pirai do Norte - BA': null,
  'Piripa - BA': null,
  'Piritiba - BA': null,
  'Planaltino - BA': null,
  'Planalto - BA': null,
  'Pocoes - BA': null,
  'Pojuca - BA': null,
  'Ponto Novo - BA': null,
  'Porto Seguro - BA': null,
  'Potiragua - BA': null,
  'Prado - BA': null,
  'Presidente Dutra - BA': null,
  'Presidente Janio Quadros - BA': null,
  'Presidente Tancredo Neves - BA': null,
  'Queimadas - BA': null,
  'Quijingue - BA': null,
  'Quixabeira - BA': null,
  'Rafael Jambeiro - BA': null,
  'Remanso - BA': null,
  'Retirolandia - BA': null,
  'Riachao das Neves - BA': null,
  'Riachao do Jacuipe - BA': null,
  'Riacho de Santana - BA': null,
  'Ribeira do Amparo - BA': null,
  'Ribeira do Pombal - BA': null,
  'Ribeirao do Largo - BA': null,
  'Rio de Contas - BA': null,
  'Rio do Antonio - BA': null,
  'Rio do Pires - BA': null,
  'Rio Real - BA': null,
  'Rodelas - BA': null,
  'Ruy Barbosa - BA': null,
  'Salinas da Margarida - BA': null,
  'Salvador - BA': null,
  'Santa Barbara - BA': null,
  'Santa Brigida - BA': null,
  'Santa Cruz Cabralia - BA': null,
  'Santa Cruz da Vitoria - BA': null,
  'Santa Ines - BA': null,
  'Santaluz - BA': null,
  'Santa Luzia - BA': null,
  'Santa Maria da Vitoria - BA': null,
  'Santana - BA': null,
  'Santanopolis - BA': null,
  'Santa Rita de Cassia - BA': null,
  'Santa Teresinha - BA': null,
  'Santo Amaro - BA': null,
  'Santo Antonio de Jesus - BA': null,
  'Santo Estevao - BA': null,
  'Sao Desiderio - BA': null,
  'Sao Domingos - BA': null,
  'Sao Felix - BA': null,
  'Sao Felix do Coribe - BA': null,
  'Sao Felipe - BA': null,
  'Sao Francisco do Conde - BA': null,
  'Sao Gabriel - BA': null,
  'Sao Goncalo dos Campos - BA': null,
  'Sao Jose da Vitoria - BA': null,
  'Sao Jose do Jacuipe - BA': null,
  'Sao Miguel das Matas - BA': null,
  'Sao Sebastiao do Passe - BA': null,
  'Sapeacu - BA': null,
  'Satiro Dias - BA': null,
  'Saubara - BA': null,
  'Saude - BA': null,
  'Seabra - BA': null,
  'Sebastiao Laranjeiras - BA': null,
  'Senhor do Bonfim - BA': null,
  'Serra do Ramalho - BA': null,
  'Sento Se - BA': null,
  'Serra Dourada - BA': null,
  'Serra Preta - BA': null,
  'Serrinha - BA': null,
  'Serrolandia - BA': null,
  'Simoes Filho - BA': null,
  'Sitio do Mato - BA': null,
  'Sitio do Quinto - BA': null,
  'Sobradinho - BA': null,
  'Souto Soares - BA': null,
  'Tabocas do Brejo Velho - BA': null,
  'Tanhacu - BA': null,
  'Tanque Novo - BA': null,
  'Tanquinho - BA': null,
  'Taperoa - BA': null,
  'Tapiramuta - BA': null,
  'Teixeira de Freitas - BA': null,
  'Teodoro Sampaio - BA': null,
  'Teofilandia - BA': null,
  'Teolandia - BA': null,
  'Terra Nova - BA': null,
  'Tremedal - BA': null,
  'Tucano - BA': null,
  'Uaua - BA': null,
  'Ubaira - BA': null,
  'Ubaitaba - BA': null,
  'Ubata - BA': null,
  'Uibai - BA': null,
  'Umburanas - BA': null,
  'Una - BA': null,
  'Urandi - BA': null,
  'Urucuca - BA': null,
  'Utinga - BA': null,
  'Valenca - BA': null,
  'Valente - BA': null,
  'Varzea da Roca - BA': null,
  'Varzea do Poco - BA': null,
  'Varzea Nova - BA': null,
  'Varzedo - BA': null,
  'Vera Cruz - BA': null,
  'Vereda - BA': null,
  'Vitoria da Conquista - BA': null,
  'Wagner - BA': null,
  'Wanderley - BA': null,
  'Wenceslau Guimaraes - BA': null,
  'Xique-xique - BA': null,
  'Abadia dos Dourados - MG': null,
  'Abaete - MG': null,
  'Abre Campo - MG': null,
  'Acaiaca - MG': null,
  'Acucena - MG': null,
  'Agua Boa - MG': null,
  'Agua Comprida - MG': null,
  'Aguanil - MG': null,
  'Aguas Formosas - MG': null,
  'Aguas Vermelhas - MG': null,
  'Aimores - MG': null,
  'Aiuruoca - MG': null,
  'Alagoa - MG': null,
  'Albertina - MG': null,
  'Alem Paraiba - MG': null,
  'Alfenas - MG': null,
  'Alfredo Vasconcelos - MG': null,
  'Almenara - MG': null,
  'Alpercata - MG': null,
  'Alpinopolis - MG': null,
  'Alterosa - MG': null,
  'Alto Caparao - MG': null,
  'Alto Rio Doce - MG': null,
  'Alvarenga - MG': null,
  'Alvinopolis - MG': null,
  'Alvorada de Minas - MG': null,
  'Amparo do Serra - MG': null,
  'Andradas - MG': null,
  'Cachoeira de Pajeu - MG': null,
  'Andrelandia - MG': null,
  'Angelandia - MG': null,
  'Antonio Carlos - MG': null,
  'Antonio Dias - MG': null,
  'Antonio Prado de Minas - MG': null,
  'Aracai - MG': null,
  'Aracitaba - MG': null,
  'Aracuai - MG': null,
  'Araguari - MG': null,
  'Arantina - MG': null,
  'Araponga - MG': null,
  'Arapora - MG': null,
  'Arapua - MG': null,
  'Araujos - MG': null,
  'Araxa - MG': null,
  'Arceburgo - MG': null,
  'Arcos - MG': null,
  'Areado - MG': null,
  'Argirita - MG': null,
  'Aricanduva - MG': null,
  'Arinos - MG': null,
  'Astolfo Dutra - MG': null,
  'Ataleia - MG': null,
  'Augusto de Lima - MG': null,
  'Baependi - MG': null,
  'Baldim - MG': null,
  'Bambui - MG': null,
  'Bandeira - MG': null,
  'Bandeira do Sul - MG': null,
  'Barao de Cocais - MG': null,
  'Barao de Monte Alto - MG': null,
  'Barbacena - MG': null,
  'Barra Longa - MG': null,
  'Barroso - MG': null,
  'Bela Vista de Minas - MG': null,
  'Belmiro Braga - MG': null,
  'Belo Horizonte - MG': null,
  'Belo Oriente - MG': null,
  'Belo Vale - MG': null,
  'Berilo - MG': null,
  'Bertopolis - MG': null,
  'Berizal - MG': null,
  'Betim - MG': null,
  'Bias Fortes - MG': null,
  'Bicas - MG': null,
  'Biquinhas - MG': null,
  'Boa Esperanca - MG': null,
  'Bocaina de Minas - MG': null,
  'Bocaiuva - MG': null,
  'Bom Despacho - MG': null,
  'Bom Jardim de Minas - MG': null,
  'Bom Jesus da Penha - MG': null,
  'Bom Jesus do Amparo - MG': null,
  'Bom Jesus do Galho - MG': null,
  'Bom Repouso - MG': null,
  'Bom Sucesso - MG': null,
  'Bonfim - MG': null,
  'Bonfinopolis de Minas - MG': null,
  'Bonito de Minas - MG': null,
  'Borda da Mata - MG': null,
  'Botelhos - MG': null,
  'Botumirim - MG': null,
  'Brasilandia de Minas - MG': null,
  'Brasilia de Minas - MG': null,
  'Bras Pires - MG': null,
  'Braunas - MG': null,
  'Brasopolis - MG': null,
  'Brumadinho - MG': null,
  'Bueno Brandao - MG': null,
  'Buenopolis - MG': null,
  'Bugre - MG': null,
  'Buritis - MG': null,
  'Buritizeiro - MG': null,
  'Cabeceira Grande - MG': null,
  'Cabo Verde - MG': null,
  'Cachoeira da Prata - MG': null,
  'Cachoeira de Minas - MG': null,
  'Cachoeira Dourada - MG': null,
  'Caetanopolis - MG': null,
  'Caete - MG': null,
  'Caiana - MG': null,
  'Cajuri - MG': null,
  'Caldas - MG': null,
  'Camacho - MG': null,
  'Camanducaia - MG': null,
  'Cambui - MG': null,
  'Cambuquira - MG': null,
  'Campanario - MG': null,
  'Campanha - MG': null,
  'Campestre - MG': null,
  'Campina Verde - MG': null,
  'Campo Azul - MG': null,
  'Campo Belo - MG': null,
  'Campo do Meio - MG': null,
  'Campo Florido - MG': null,
  'Campos Altos - MG': null,
  'Campos Gerais - MG': null,
  'Canaa - MG': null,
  'Canapolis - MG': null,
  'Cana Verde - MG': null,
  'Candeias - MG': null,
  'Cantagalo - MG': null,
  'Caparao - MG': null,
  'Capela Nova - MG': null,
  'Capelinha - MG': null,
  'Capetinga - MG': null,
  'Capim Branco - MG': null,
  'Capinopolis - MG': null,
  'Capitao Andrade - MG': null,
  'Capitao Eneas - MG': null,
  'Capitolio - MG': null,
  'Caputira - MG': null,
  'Carai - MG': null,
  'Caranaiba - MG': null,
  'Carandai - MG': null,
  'Carangola - MG': null,
  'Caratinga - MG': null,
  'Carbonita - MG': null,
  'Careacu - MG': null,
  'Carlos Chagas - MG': null,
  'Carmesia - MG': null,
  'Carmo da Cachoeira - MG': null,
  'Carmo da Mata - MG': null,
  'Carmo de Minas - MG': null,
  'Carmo do Cajuru - MG': null,
  'Carmo do Paranaiba - MG': null,
  'Carmo do Rio Claro - MG': null,
  'Carmopolis de Minas - MG': null,
  'Carneirinho - MG': null,
  'Carrancas - MG': null,
  'Carvalhopolis - MG': null,
  'Carvalhos - MG': null,
  'Casa Grande - MG': null,
  'Cascalho Rico - MG': null,
  'Cassia - MG': null,
  'Conceicao da Barra de Minas - MG': null,
  'Cataguases - MG': null,
  'Catas Altas - MG': null,
  'Catas Altas da Noruega - MG': null,
  'Catuji - MG': null,
  'Catuti - MG': null,
  'Caxambu - MG': null,
  'Cedro do Abaete - MG': null,
  'Central de Minas - MG': null,
  'Centralina - MG': null,
  'Chacara - MG': null,
  'Chale - MG': null,
  'Chapada do Norte - MG': null,
  'Chapada Gaucha - MG': null,
  'Chiador - MG': null,
  'Cipotanea - MG': null,
  'Claraval - MG': null,
  'Claro dos Pocoes - MG': null,
  'Claudio - MG': null,
  'Coimbra - MG': null,
  'Coluna - MG': null,
  'Comendador Gomes - MG': null,
  'Comercinho - MG': null,
  'Conceicao da Aparecida - MG': null,
  'Conceicao das Pedras - MG': null,
  'Conceicao das Alagoas - MG': null,
  'Conceicao de Ipanema - MG': null,
  'Conceicao do Mato Dentro - MG': null,
  'Conceicao do Para - MG': null,
  'Conceicao do Rio Verde - MG': null,
  'Conceicao dos Ouros - MG': null,
  'Conego Marinho - MG': null,
  'Confins - MG': null,
  'Congonhal - MG': null,
  'Congonhas - MG': null,
  'Congonhas do Norte - MG': null,
  'Conquista - MG': null,
  'Conselheiro Lafaiete - MG': null,
  'Conselheiro Pena - MG': null,
  'Consolacao - MG': null,
  'Contagem - MG': null,
  'Coqueiral - MG': null,
  'Coracao de Jesus - MG': null,
  'Cordisburgo - MG': null,
  'Cordislandia - MG': null,
  'Corinto - MG': null,
  'Coroaci - MG': null,
  'Coromandel - MG': null,
  'Coronel Fabriciano - MG': null,
  'Coronel Murta - MG': null,
  'Coronel Pacheco - MG': null,
  'Coronel Xavier Chaves - MG': null,
  'Corrego Danta - MG': null,
  'Corrego do Bom Jesus - MG': null,
  'Corrego Fundo - MG': null,
  'Corrego Novo - MG': null,
  'Couto de Magalhaes de Minas - MG': null,
  'Crisolita - MG': null,
  'Cristais - MG': null,
  'Cristalia - MG': null,
  'Cristiano Otoni - MG': null,
  'Cristina - MG': null,
  'Crucilandia - MG': null,
  'Cruzeiro da Fortaleza - MG': null,
  'Cruzilia - MG': null,
  'Cuparaque - MG': null,
  'Curral de Dentro - MG': null,
  'Curvelo - MG': null,
  'Datas - MG': null,
  'Delfim Moreira - MG': null,
  'Delfinopolis - MG': null,
  'Delta - MG': null,
  'Descoberto - MG': null,
  'Desterro de Entre Rios - MG': null,
  'Desterro do Melo - MG': null,
  'Diamantina - MG': null,
  'Diogo de Vasconcelos - MG': null,
  'Dionisio - MG': null,
  'Divinesia - MG': null,
  'Divino - MG': null,
  'Divino das Laranjeiras - MG': null,
  'Divinolandia de Minas - MG': null,
  'Divinopolis - MG': null,
  'Divisa Alegre - MG': null,
  'Divisa Nova - MG': null,
  'Divisopolis - MG': null,
  'Dom Bosco - MG': null,
  'Dom Cavati - MG': null,
  'Dom Joaquim - MG': null,
  'Dom Silverio - MG': null,
  'Dom Vicoso - MG': null,
  'Dona Eusebia - MG': null,
  'Dores de Campos - MG': null,
  'Dores de Guanhaes - MG': null,
  'Dores do Indaia - MG': null,
  'Dores do Turvo - MG': null,
  'Doresopolis - MG': null,
  'Douradoquara - MG': null,
  'Durande - MG': null,
  'Eloi Mendes - MG': null,
  'Engenheiro Caldas - MG': null,
  'Engenheiro Navarro - MG': null,
  'Entre Folhas - MG': null,
  'Entre Rios de Minas - MG': null,
  'Ervalia - MG': null,
  'Esmeraldas - MG': null,
  'Espera Feliz - MG': null,
  'Espinosa - MG': null,
  'Espirito Santo do Dourado - MG': null,
  'Estiva - MG': null,
  'Estrela Dalva - MG': null,
  'Estrela do Indaia - MG': null,
  'Estrela do Sul - MG': null,
  'Eugenopolis - MG': null,
  'Ewbank da Camara - MG': null,
  'Extrema - MG': null,
  'Fama - MG': null,
  'Faria Lemos - MG': null,
  'Felicio dos Santos - MG': null,
  'Sao Goncalo do Rio Preto - MG': null,
  'Felisburgo - MG': null,
  'Felixlandia - MG': null,
  'Fernandes Tourinho - MG': null,
  'Ferros - MG': null,
  'Fervedouro - MG': null,
  'Florestal - MG': null,
  'Formiga - MG': null,
  'Formoso - MG': null,
  'Fortaleza de Minas - MG': null,
  'Fortuna de Minas - MG': null,
  'Francisco Badaro - MG': null,
  'Francisco Dumont - MG': null,
  'Francisco Sa - MG': null,
  'Franciscopolis - MG': null,
  'Frei Gaspar - MG': null,
  'Frei Inocencio - MG': null,
  'Frei Lagonegro - MG': null,
  'Fronteira - MG': null,
  'Fronteira dos Vales - MG': null,
  'Fruta de Leite - MG': null,
  'Frutal - MG': null,
  'Funilandia - MG': null,
  'Galileia - MG': null,
  'Gameleiras - MG': null,
  'Glaucilandia - MG': null,
  'Goiabeira - MG': null,
  'Goiana - MG': null,
  'Goncalves - MG': null,
  'Gonzaga - MG': null,
  'Gouveia - MG': null,
  'Governador Valadares - MG': null,
  'Grao Mogol - MG': null,
  'Grupiara - MG': null,
  'Guanhaes - MG': null,
  'Guape - MG': null,
  'Guaraciaba - MG': null,
  'Guaraciama - MG': null,
  'Guaranesia - MG': null,
  'Guarani - MG': null,
  'Guarara - MG': null,
  'Guarda-mor - MG': null,
  'Guaxupe - MG': null,
  'Guidoval - MG': null,
  'Guimarania - MG': null,
  'Guiricema - MG': null,
  'Gurinhata - MG': null,
  'Heliodora - MG': null,
  'Iapu - MG': null,
  'Ibertioga - MG': null,
  'Ibia - MG': null,
  'Ibiai - MG': null,
  'Ibiracatu - MG': null,
  'Ibiraci - MG': null,
  'Ibirite - MG': null,
  'Ibitiura de Minas - MG': null,
  'Ibituruna - MG': null,
  'Icarai de Minas - MG': null,
  'Igarape - MG': null,
  'Igaratinga - MG': null,
  'Iguatama - MG': null,
  'Ijaci - MG': null,
  'Ilicinea - MG': null,
  'Imbe de Minas - MG': null,
  'Inconfidentes - MG': null,
  'Indaiabira - MG': null,
  'Indianopolis - MG': null,
  'Ingai - MG': null,
  'Inhapim - MG': null,
  'Inhauma - MG': null,
  'Inimutaba - MG': null,
  'Ipaba - MG': null,
  'Ipanema - MG': null,
  'Ipatinga - MG': null,
  'Ipiacu - MG': null,
  'Ipuiuna - MG': null,
  'Irai de Minas - MG': null,
  'Itabira - MG': null,
  'Itabirinha - MG': null,
  'Itabirito - MG': null,
  'Itacambira - MG': null,
  'Itacarambi - MG': null,
  'Itaguara - MG': null,
  'Itaipe - MG': null,
  'Itajuba - MG': null,
  'Itamarandiba - MG': null,
  'Itamarati de Minas - MG': null,
  'Itambacuri - MG': null,
  'Itambe do Mato Dentro - MG': null,
  'Itamogi - MG': null,
  'Itamonte - MG': null,
  'Itanhandu - MG': null,
  'Itanhomi - MG': null,
  'Itaobim - MG': null,
  'Itapagipe - MG': null,
  'Itapecerica - MG': null,
  'Itapeva - MG': null,
  'Itatiaiucu - MG': null,
  'Itau de Minas - MG': null,
  'Itauna - MG': null,
  'Itaverava - MG': null,
  'Itinga - MG': null,
  'Itueta - MG': null,
  'Ituiutaba - MG': null,
  'Itumirim - MG': null,
  'Iturama - MG': null,
  'Itutinga - MG': null,
  'Jaboticatubas - MG': null,
  'Jacinto - MG': null,
  'Jacui - MG': null,
  'Jacutinga - MG': null,
  'Jaguaracu - MG': null,
  'Jaiba - MG': null,
  'Jampruca - MG': null,
  'Janauba - MG': null,
  'Januaria - MG': null,
  'Japaraiba - MG': null,
  'Japonvar - MG': null,
  'Jeceaba - MG': null,
  'Jenipapo de Minas - MG': null,
  'Jequeri - MG': null,
  'Jequitai - MG': null,
  'Jequitiba - MG': null,
  'Jequitinhonha - MG': null,
  'Jesuania - MG': null,
  'Joaima - MG': null,
  'Joanesia - MG': null,
  'Joao Monlevade - MG': null,
  'Joao Pinheiro - MG': null,
  'Joaquim Felicio - MG': null,
  'Jordania - MG': null,
  'Jose Goncalves de Minas - MG': null,
  'Jose Raydan - MG': null,
  'Josenopolis - MG': null,
  'Nova Uniao - MG': null,
  'Juatuba - MG': null,
  'Juiz de Fora - MG': null,
  'Juramento - MG': null,
  'Juruaia - MG': null,
  'Juvenilia - MG': null,
  'Ladainha - MG': null,
  'Lagamar - MG': null,
  'Lagoa da Prata - MG': null,
  'Lagoa dos Patos - MG': null,
  'Lagoa Dourada - MG': null,
  'Lagoa Formosa - MG': null,
  'Lagoa Grande - MG': null,
  'Lagoa Santa - MG': null,
  'Lajinha - MG': null,
  'Lambari - MG': null,
  'Lamim - MG': null,
  'Laranjal - MG': null,
  'Lassance - MG': null,
  'Lavras - MG': null,
  'Leandro Ferreira - MG': null,
  'Leme do Prado - MG': null,
  'Leopoldina - MG': null,
  'Liberdade - MG': null,
  'Lima Duarte - MG': null,
  'Limeira do Oeste - MG': null,
  'Lontra - MG': null,
  'Luisburgo - MG': null,
  'Luislandia - MG': null,
  'Luminarias - MG': null,
  'Luz - MG': null,
  'Machacalis - MG': null,
  'Machado - MG': null,
  'Madre de Deus de Minas - MG': null,
  'Malacacheta - MG': null,
  'Mamonas - MG': null,
  'Manga - MG': null,
  'Manhuacu - MG': null,
  'Manhumirim - MG': null,
  'Mantena - MG': null,
  'Maravilhas - MG': null,
  'Mar de Espanha - MG': null,
  'Maria da Fe - MG': null,
  'Mariana - MG': null,
  'Marilac - MG': null,
  'Mario Campos - MG': null,
  'Maripa de Minas - MG': null,
  'Marlieria - MG': null,
  'Marmelopolis - MG': null,
  'Martinho Campos - MG': null,
  'Martins Soares - MG': null,
  'Mata Verde - MG': null,
  'Materlandia - MG': null,
  'Mateus Leme - MG': null,
  'Matias Barbosa - MG': null,
  'Matias Cardoso - MG': null,
  'Matipo - MG': null,
  'Mato Verde - MG': null,
  'Matozinhos - MG': null,
  'Matutina - MG': null,
  'Medeiros - MG': null,
  'Medina - MG': null,
  'Mendes Pimentel - MG': null,
  'Merces - MG': null,
  'Mesquita - MG': null,
  'Minas Novas - MG': null,
  'Minduri - MG': null,
  'Mirabela - MG': null,
  'Miradouro - MG': null,
  'Mirai - MG': null,
  'Miravania - MG': null,
  'Moeda - MG': null,
  'Moema - MG': null,
  'Monjolos - MG': null,
  'Monsenhor Paulo - MG': null,
  'Montalvania - MG': null,
  'Monte Alegre de Minas - MG': null,
  'Monte Azul - MG': null,
  'Monte Belo - MG': null,
  'Monte Carmelo - MG': null,
  'Monte Formoso - MG': null,
  'Monte Santo de Minas - MG': null,
  'Montes Claros - MG': null,
  'Monte Siao - MG': null,
  'Montezuma - MG': null,
  'Morada Nova de Minas - MG': null,
  'Morro da Garca - MG': null,
  'Morro do Pilar - MG': null,
  'Munhoz - MG': null,
  'Muriae - MG': null,
  'Mutum - MG': null,
  'Muzambinho - MG': null,
  'Nacip Raydan - MG': null,
  'Nanuque - MG': null,
  'Naque - MG': null,
  'Natalandia - MG': null,
  'Natercia - MG': null,
  'Nazareno - MG': null,
  'Nepomuceno - MG': null,
  'Ninheira - MG': null,
  'Nova Belem - MG': null,
  'Nova Era - MG': null,
  'Nova Lima - MG': null,
  'Nova Modica - MG': null,
  'Nova Ponte - MG': null,
  'Nova Porteirinha - MG': null,
  'Nova Resende - MG': null,
  'Nova Serrana - MG': null,
  'Novo Cruzeiro - MG': null,
  'Novo Oriente de Minas - MG': null,
  'Novorizonte - MG': null,
  'Olaria - MG': null,
  "Olhos D'Agua - MG": null,
  'Olimpio Noronha - MG': null,
  'Oliveira - MG': null,
  'Oliveira Fortes - MG': null,
  'Onca de Pitangui - MG': null,
  'Oratorios - MG': null,
  'Orizania - MG': null,
  'Ouro Branco - MG': null,
  'Ouro Fino - MG': null,
  'Ouro Preto - MG': null,
  'Ouro Verde de Minas - MG': null,
  'Padre Carvalho - MG': null,
  'Padre Paraiso - MG': null,
  'Paineiras - MG': null,
  'Pains - MG': null,
  'Pai Pedro - MG': null,
  'Paiva - MG': null,
  'Palma - MG': null,
  'Palmopolis - MG': null,
  'Papagaios - MG': null,
  'Paracatu - MG': null,
  'Para de Minas - MG': null,
  'Paraguacu - MG': null,
  'Paraisopolis - MG': null,
  'Paraopeba - MG': null,
  'Passabem - MG': null,
  'Passa Quatro - MG': null,
  'Passa Tempo - MG': null,
  'Passa-vinte - MG': null,
  'Passos - MG': null,
  'Patis - MG': null,
  'Patos de Minas - MG': null,
  'Patrocinio - MG': null,
  'Patrocinio do Muriae - MG': null,
  'Paula Candido - MG': null,
  'Paulistas - MG': null,
  'Pavao - MG': null,
  'Pecanha - MG': null,
  'Pedra Azul - MG': null,
  'Pedra Bonita - MG': null,
  'Pedra do Anta - MG': null,
  'Pedra do Indaia - MG': null,
  'Pedra Dourada - MG': null,
  'Pedralva - MG': null,
  'Pedras de Maria da Cruz - MG': null,
  'Pedrinopolis - MG': null,
  'Pedro Leopoldo - MG': null,
  'Pedro Teixeira - MG': null,
  'Pequeri - MG': null,
  'Pequi - MG': null,
  'Perdigao - MG': null,
  'Perdizes - MG': null,
  'Perdoes - MG': null,
  'Periquito - MG': null,
  'Pescador - MG': null,
  'Piau - MG': null,
  'Piedade de Caratinga - MG': null,
  'Piedade de Ponte Nova - MG': null,
  'Piedade do Rio Grande - MG': null,
  'Piedade dos Gerais - MG': null,
  'Pimenta - MG': null,
  "Pingo-d'agua - MG": null,
  'Pintopolis - MG': null,
  'Piracema - MG': null,
  'Pirajuba - MG': null,
  'Piranga - MG': null,
  'Pirangucu - MG': null,
  'Piranguinho - MG': null,
  'Pirapetinga - MG': null,
  'Pirapora - MG': null,
  'Pirauba - MG': null,
  'Pitangui - MG': null,
  'Piumhi - MG': null,
  'Planura - MG': null,
  'Poco Fundo - MG': null,
  'Pocos de Caldas - MG': null,
  'Pocrane - MG': null,
  'Pompeu - MG': null,
  'Ponte Nova - MG': null,
  'Ponto Chique - MG': null,
  'Ponto dos Volantes - MG': null,
  'Porteirinha - MG': null,
  'Porto Firme - MG': null,
  'Pote - MG': null,
  'Pouso Alegre - MG': null,
  'Pouso Alto - MG': null,
  'Prados - MG': null,
  'Prata - MG': null,
  'Pratapolis - MG': null,
  'Pratinha - MG': null,
  'Presidente Bernardes - MG': null,
  'Presidente Juscelino - MG': null,
  'Presidente Kubitschek - MG': null,
  'Presidente Olegario - MG': null,
  'Alto Jequitiba - MG': null,
  'Prudente de Morais - MG': null,
  'Quartel Geral - MG': null,
  'Queluzito - MG': null,
  'Raposos - MG': null,
  'Raul Soares - MG': null,
  'Recreio - MG': null,
  'Reduto - MG': null,
  'Resende Costa - MG': null,
  'Resplendor - MG': null,
  'Ressaquinha - MG': null,
  'Riachinho - MG': null,
  'Riacho dos Machados - MG': null,
  'Ribeirao das Neves - MG': null,
  'Ribeirao Vermelho - MG': null,
  'Rio Acima - MG': null,
  'Rio Casca - MG': null,
  'Rio Doce - MG': null,
  'Rio do Prado - MG': null,
  'Rio Espera - MG': null,
  'Rio Manso - MG': null,
  'Rio Novo - MG': null,
  'Rio Paranaiba - MG': null,
  'Rio Pardo de Minas - MG': null,
  'Rio Piracicaba - MG': null,
  'Rio Pomba - MG': null,
  'Rio Preto - MG': null,
  'Rio Vermelho - MG': null,
  'Ritapolis - MG': null,
  'Rochedo de Minas - MG': null,
  'Rodeiro - MG': null,
  'Romaria - MG': null,
  'Rosario da Limeira - MG': null,
  'Rubelita - MG': null,
  'Rubim - MG': null,
  'Sabara - MG': null,
  'Sabinopolis - MG': null,
  'Sacramento - MG': null,
  'Salinas - MG': null,
  'Salto da Divisa - MG': null,
  'Santa Barbara - MG': null,
  'Santa Barbara do Leste - MG': null,
  'Santa Barbara do Monte Verde - MG': null,
  'Santa Barbara do Tugurio - MG': null,
  'Santa Cruz de Minas - MG': null,
  'Santa Cruz de Salinas - MG': null,
  'Santa Cruz do Escalvado - MG': null,
  'Santa Efigenia de Minas - MG': null,
  'Santa Fe de Minas - MG': null,
  'Santa Helena de Minas - MG': null,
  'Santa Juliana - MG': null,
  'Santa Luzia - MG': null,
  'Santa Margarida - MG': null,
  'Santa Maria de Itabira - MG': null,
  'Santa Maria do Salto - MG': null,
  'Santa Maria do Suacui - MG': null,
  'Santana da Vargem - MG': null,
  'Santana de Cataguases - MG': null,
  'Santana de Pirapama - MG': null,
  'Santana do Deserto - MG': null,
  'Santana do Garambeu - MG': null,
  'Santana do Jacare - MG': null,
  'Santana do Manhuacu - MG': null,
  'Santana do Paraiso - MG': null,
  'Santana do Riacho - MG': null,
  'Santana dos Montes - MG': null,
  'Santa Rita de Caldas - MG': null,
  'Santa Rita de Jacutinga - MG': null,
  'Santa Rita de Minas - MG': null,
  'Santa Rita de Ibitipoca - MG': null,
  'Santa Rita do Itueto - MG': null,
  'Santa Rita do Sapucai - MG': null,
  'Santa Rosa da Serra - MG': null,
  'Santa Vitoria - MG': null,
  'Santo Antonio do Amparo - MG': null,
  'Santo Antonio do Aventureiro - MG': null,
  'Santo Antonio do Grama - MG': null,
  'Santo Antonio do Itambe - MG': null,
  'Santo Antonio do Jacinto - MG': null,
  'Santo Antonio do Monte - MG': null,
  'Santo Antonio do Retiro - MG': null,
  'Santo Antonio do Rio Abaixo - MG': null,
  'Santo Hipolito - MG': null,
  'Santos Dumont - MG': null,
  'Sao Bento Abade - MG': null,
  'Sao Bras do Suacui - MG': null,
  'Sao Domingos das Dores - MG': null,
  'Sao Domingos do Prata - MG': null,
  'Sao Felix de Minas - MG': null,
  'Sao Francisco - MG': null,
  'Sao Francisco de Paula - MG': null,
  'Sao Francisco de Sales - MG': null,
  'Sao Francisco do Gloria - MG': null,
  'Sao Geraldo - MG': null,
  'Sao Geraldo da Piedade - MG': null,
  'Sao Geraldo do Baixio - MG': null,
  'Sao Goncalo do Abaete - MG': null,
  'Sao Goncalo do Para - MG': null,
  'Sao Goncalo do Rio Abaixo - MG': null,
  'Sao Goncalo do Sapucai - MG': null,
  'Sao Gotardo - MG': null,
  'Sao Joao Batista do Gloria - MG': null,
  'Sao Joao da Lagoa - MG': null,
  'Sao Joao da Mata - MG': null,
  'Sao Joao da Ponte - MG': null,
  'Sao Joao das Missoes - MG': null,
  'Sao Joao Del Rei - MG': null,
  'Sao Joao do Manhuacu - MG': null,
  'Sao Joao do Manteninha - MG': null,
  'Sao Joao do Oriente - MG': null,
  'Sao Joao do Pacui - MG': null,
  'Sao Joao do Paraiso - MG': null,
  'Sao Joao Evangelista - MG': null,
  'Sao Joao Nepomuceno - MG': null,
  'Sao Joaquim de Bicas - MG': null,
  'Sao Jose da Barra - MG': null,
  'Sao Jose da Lapa - MG': null,
  'Sao Jose da Safira - MG': null,
  'Sao Jose da Varginha - MG': null,
  'Sao Jose do Alegre - MG': null,
  'Sao Jose do Divino - MG': null,
  'Sao Jose do Goiabal - MG': null,
  'Sao Jose do Jacuri - MG': null,
  'Sao Jose do Mantimento - MG': null,
  'Sao Lourenco - MG': null,
  'Sao Miguel do Anta - MG': null,
  'Sao Pedro da Uniao - MG': null,
  'Sao Pedro dos Ferros - MG': null,
  'Sao Pedro do Suacui - MG': null,
  'Sao Romao - MG': null,
  'Sao Roque de Minas - MG': null,
  'Sao Sebastiao da Bela Vista - MG': null,
  'Sao Sebastiao da Vargem Alegre - MG': null,
  'Sao Sebastiao do Anta - MG': null,
  'Sao Sebastiao do Maranhao - MG': null,
  'Sao Sebastiao do Oeste - MG': null,
  'Sao Sebastiao do Paraiso - MG': null,
  'Sao Sebastiao do Rio Preto - MG': null,
  'Sao Sebastiao do Rio Verde - MG': null,
  'Sao Tiago - MG': null,
  'Sao Tomas de Aquino - MG': null,
  'Sao Thome das Letras - MG': null,
  'Sao Vicente de Minas - MG': null,
  'Sapucai-mirim - MG': null,
  'Sardoa - MG': null,
  'Sarzedo - MG': null,
  'Setubinha - MG': null,
  'Sem-peixe - MG': null,
  'Senador Amaral - MG': null,
  'Senador Cortes - MG': null,
  'Senador Firmino - MG': null,
  'Senador Jose Bento - MG': null,
  'Senador Modestino Goncalves - MG': null,
  'Senhora de Oliveira - MG': null,
  'Senhora do Porto - MG': null,
  'Senhora dos Remedios - MG': null,
  'Sericita - MG': null,
  'Seritinga - MG': null,
  'Serra Azul de Minas - MG': null,
  'Serra da Saudade - MG': null,
  'Serra dos Aimores - MG': null,
  'Serra do Salitre - MG': null,
  'Serrania - MG': null,
  'Serranopolis de Minas - MG': null,
  'Serranos - MG': null,
  'Serro - MG': null,
  'Sete Lagoas - MG': null,
  'Silveirania - MG': null,
  'Silvianopolis - MG': null,
  'Simao Pereira - MG': null,
  'Simonesia - MG': null,
  'Sobralia - MG': null,
  'Soledade de Minas - MG': null,
  'Tabuleiro - MG': null,
  'Taiobeiras - MG': null,
  'Taparuba - MG': null,
  'Tapira - MG': null,
  'Tapirai - MG': null,
  'Taquaracu de Minas - MG': null,
  'Tarumirim - MG': null,
  'Teixeiras - MG': null,
  'Teofilo Otoni - MG': null,
  'Timoteo - MG': null,
  'Tiradentes - MG': null,
  'Tiros - MG': null,
  'Tocantins - MG': null,
  'Tocos do Moji - MG': null,
  'Toledo - MG': null,
  'Tombos - MG': null,
  'Tres Coracoes - MG': null,
  'Tres Marias - MG': null,
  'Tres Pontas - MG': null,
  'Tumiritinga - MG': null,
  'Tupaciguara - MG': null,
  'Turmalina - MG': null,
  'Turvolandia - MG': null,
  'Uba - MG': null,
  'Ubai - MG': null,
  'Ubaporanga - MG': null,
  'Uberaba - MG': null,
  'Uberlandia - MG': null,
  'Umburatiba - MG': null,
  'Unai - MG': null,
  'Uniao de Minas - MG': null,
  'Uruana de Minas - MG': null,
  'Urucania - MG': null,
  'Urucuia - MG': null,
  'Vargem Alegre - MG': null,
  'Vargem Bonita - MG': null,
  'Vargem Grande do Rio Pardo - MG': null,
  'Varginha - MG': null,
  'Varjao de Minas - MG': null,
  'Varzea da Palma - MG': null,
  'Varzelandia - MG': null,
  'Vazante - MG': null,
  'Verdelandia - MG': null,
  'Veredinha - MG': null,
  'Verissimo - MG': null,
  'Vermelho Novo - MG': null,
  'Vespasiano - MG': null,
  'Vicosa - MG': null,
  'Vieiras - MG': null,
  'Mathias Lobato - MG': null,
  'Virgem da Lapa - MG': null,
  'Virginia - MG': null,
  'Virginopolis - MG': null,
  'Virgolandia - MG': null,
  'Visconde do Rio Branco - MG': null,
  'Volta Grande - MG': null,
  'Wenceslau Braz - MG': null,
  'Afonso Claudio - ES': null,
  'Aguia Branca - ES': null,
  'Agua Doce do Norte - ES': null,
  'Alegre - ES': null,
  'Alfredo Chaves - ES': null,
  'Alto Rio Novo - ES': null,
  'Anchieta - ES': null,
  'Apiaca - ES': null,
  'Aracruz - ES': null,
  'Atilio Vivacqua - ES': null,
  'Baixo Guandu - ES': null,
  'Barra de Sao Francisco - ES': null,
  'Boa Esperanca - ES': null,
  'Bom Jesus do Norte - ES': null,
  'Brejetuba - ES': null,
  'Cachoeiro de Itapemirim - ES': null,
  'Cariacica - ES': null,
  'Castelo - ES': null,
  'Colatina - ES': null,
  'Conceicao da Barra - ES': null,
  'Conceicao do Castelo - ES': null,
  'Divino de Sao Lourenco - ES': null,
  'Domingos Martins - ES': null,
  'Dores do Rio Preto - ES': null,
  'Ecoporanga - ES': null,
  'Fundao - ES': null,
  'Governador Lindenberg - ES': null,
  'Guacui - ES': null,
  'Guarapari - ES': null,
  'Ibatiba - ES': null,
  'Ibiracu - ES': null,
  'Ibitirama - ES': null,
  'Iconha - ES': null,
  'Irupi - ES': null,
  'Itaguacu - ES': null,
  'Itapemirim - ES': null,
  'Itarana - ES': null,
  'Iuna - ES': null,
  'Jaguare - ES': null,
  'Jeronimo Monteiro - ES': null,
  'Joao Neiva - ES': null,
  'Laranja da Terra - ES': null,
  'Linhares - ES': null,
  'Mantenopolis - ES': null,
  'Marataizes - ES': null,
  'Marechal Floriano - ES': null,
  'Marilandia - ES': null,
  'Mimoso do Sul - ES': null,
  'Montanha - ES': null,
  'Mucurici - ES': null,
  'Muniz Freire - ES': null,
  'Muqui - ES': null,
  'Nova Venecia - ES': null,
  'Pancas - ES': null,
  'Pedro Canario - ES': null,
  'Pinheiros - ES': null,
  'Piuma - ES': null,
  'Ponto Belo - ES': null,
  'Presidente Kennedy - ES': null,
  'Rio Bananal - ES': null,
  'Rio Novo do Sul - ES': null,
  'Santa Leopoldina - ES': null,
  'Santa Maria de Jetiba - ES': null,
  'Santa Teresa - ES': null,
  'Sao Domingos do Norte - ES': null,
  'Sao Gabriel da Palha - ES': null,
  'Sao Jose do Calcado - ES': null,
  'Sao Mateus - ES': null,
  'Sao Roque do Canaa - ES': null,
  'Serra - ES': null,
  'Sooretama - ES': null,
  'Vargem Alta - ES': null,
  'Venda Nova do Imigrante - ES': null,
  'Viana - ES': null,
  'Vila Pavao - ES': null,
  'Vila Valerio - ES': null,
  'Vila Velha - ES': null,
  'Vitoria - ES': null,
  'Angra dos Reis - RJ': null,
  'Aperibe - RJ': null,
  'Araruama - RJ': null,
  'Areal - RJ': null,
  'Armacao dos Buzios - RJ': null,
  'Arraial do Cabo - RJ': null,
  'Barra do Pirai - RJ': null,
  'Barra Mansa - RJ': null,
  'Belford Roxo - RJ': null,
  'Bom Jardim - RJ': null,
  'Bom Jesus do Itabapoana - RJ': null,
  'Cabo Frio - RJ': null,
  'Cachoeiras de Macacu - RJ': null,
  'Cambuci - RJ': null,
  'Carapebus - RJ': null,
  'Comendador Levy Gasparian - RJ': null,
  'Campos dos Goytacazes - RJ': null,
  'Cantagalo - RJ': null,
  'Cardoso Moreira - RJ': null,
  'Carmo - RJ': null,
  'Casimiro de Abreu - RJ': null,
  'Conceicao de Macabu - RJ': null,
  'Cordeiro - RJ': null,
  'Duas Barras - RJ': null,
  'Duque de Caxias - RJ': null,
  'Engenheiro Paulo de Frontin - RJ': null,
  'Guapimirim - RJ': null,
  'Iguaba Grande - RJ': null,
  'Itaborai - RJ': null,
  'Itaguai - RJ': null,
  'Italva - RJ': null,
  'Itaocara - RJ': null,
  'Itaperuna - RJ': null,
  'Itatiaia - RJ': null,
  'Japeri - RJ': null,
  'Laje do Muriae - RJ': null,
  'Macae - RJ': null,
  'Macuco - RJ': null,
  'Mage - RJ': null,
  'Mangaratiba - RJ': null,
  'Marica - RJ': null,
  'Mendes - RJ': null,
  'Mesquita - RJ': null,
  'Miguel Pereira - RJ': null,
  'Miracema - RJ': null,
  'Natividade - RJ': null,
  'Nilopolis - RJ': null,
  'Niteroi - RJ': null,
  'Nova Friburgo - RJ': null,
  'Nova Iguacu - RJ': null,
  'Paracambi - RJ': null,
  'Paraiba do Sul - RJ': null,
  'Paraty - RJ': null,
  'Paty do Alferes - RJ': null,
  'Petropolis - RJ': null,
  'Pinheiral - RJ': null,
  'Pirai - RJ': null,
  'Porciuncula - RJ': null,
  'Porto Real - RJ': null,
  'Quatis - RJ': null,
  'Queimados - RJ': null,
  'Quissama - RJ': null,
  'Resende - RJ': null,
  'Rio Bonito - RJ': null,
  'Rio Claro - RJ': null,
  'Rio das Flores - RJ': null,
  'Rio das Ostras - RJ': null,
  'Rio de Janeiro - RJ': null,
  'Santa Maria Madalena - RJ': null,
  'Santo Antonio de Padua - RJ': null,
  'Sao Francisco de Itabapoana - RJ': null,
  'Sao Fidelis - RJ': null,
  'Sao Goncalo - RJ': null,
  'Sao Joao da Barra - RJ': null,
  'Sao Joao de Meriti - RJ': null,
  'Sao Jose de Uba - RJ': null,
  'Sao Jose do Vale do Rio Preto - RJ': null,
  'Sao Pedro da Aldeia - RJ': null,
  'Sao Sebastiao do Alto - RJ': null,
  'Sapucaia - RJ': null,
  'Saquarema - RJ': null,
  'Seropedica - RJ': null,
  'Silva Jardim - RJ': null,
  'Sumidouro - RJ': null,
  'Tangua - RJ': null,
  'Teresopolis - RJ': null,
  'Trajano de Moraes - RJ': null,
  'Tres Rios - RJ': null,
  'Valenca - RJ': null,
  'Varre-sai - RJ': null,
  'Vassouras - RJ': null,
  'Volta Redonda - RJ': null,
  'Adamantina - SP': null,
  'Adolfo - SP': null,
  'Aguai - SP': null,
  'Aguas da Prata - SP': null,
  'Aguas de Lindoia - SP': null,
  'Aguas de Santa Barbara - SP': null,
  'Aguas de Sao Pedro - SP': null,
  'Agudos - SP': null,
  'Alambari - SP': null,
  'Alfredo Marcondes - SP': null,
  'Altair - SP': null,
  'Altinopolis - SP': null,
  'Alto Alegre - SP': null,
  'Aluminio - SP': null,
  'Alvares Florence - SP': null,
  'Alvares Machado - SP': null,
  'Alvaro de Carvalho - SP': null,
  'Alvinlandia - SP': null,
  'Americana - SP': null,
  'Americo Brasiliense - SP': null,
  'Americo de Campos - SP': null,
  'Amparo - SP': null,
  'Analandia - SP': null,
  'Andradina - SP': null,
  'Angatuba - SP': null,
  'Anhembi - SP': null,
  'Anhumas - SP': null,
  'Aparecida - SP': null,
  "Aparecida D'Oeste - SP": null,
  'Apiai - SP': null,
  'Aracariguama - SP': null,
  'Aracatuba - SP': null,
  'Aracoiaba da Serra - SP': null,
  'Aramina - SP': null,
  'Arandu - SP': null,
  'Arapei - SP': null,
  'Araraquara - SP': null,
  'Araras - SP': null,
  'Arco-iris - SP': null,
  'Arealva - SP': null,
  'Areias - SP': null,
  'Areiopolis - SP': null,
  'Ariranha - SP': null,
  'Artur Nogueira - SP': null,
  'Aruja - SP': null,
  'Aspasia - SP': null,
  'Assis - SP': null,
  'Atibaia - SP': null,
  'Auriflama - SP': null,
  'Avai - SP': null,
  'Avanhandava - SP': null,
  'Avare - SP': null,
  'Bady Bassitt - SP': null,
  'Balbinos - SP': null,
  'Balsamo - SP': null,
  'Bananal - SP': null,
  'Barao de Antonina - SP': null,
  'Barbosa - SP': null,
  'Bariri - SP': null,
  'Barra Bonita - SP': null,
  'Barra do Chapeu - SP': null,
  'Barra do Turvo - SP': null,
  'Barretos - SP': null,
  'Barrinha - SP': null,
  'Barueri - SP': null,
  'Bastos - SP': null,
  'Batatais - SP': null,
  'Bauru - SP': null,
  'Bebedouro - SP': null,
  'Bento de Abreu - SP': null,
  'Bernardino de Campos - SP': null,
  'Bertioga - SP': null,
  'Bilac - SP': null,
  'Birigui - SP': null,
  'Biritiba-mirim - SP': null,
  'Boa Esperanca do Sul - SP': null,
  'Bocaina - SP': null,
  'Bofete - SP': null,
  'Boituva - SP': null,
  'Bom Jesus dos Perdoes - SP': null,
  'Bom Sucesso de Itarare - SP': null,
  'Bora - SP': null,
  'Boraceia - SP': null,
  'Borborema - SP': null,
  'Borebi - SP': null,
  'Botucatu - SP': null,
  'Braganca Paulista - SP': null,
  'Brauna - SP': null,
  'Brejo Alegre - SP': null,
  'Brodowski - SP': null,
  'Brotas - SP': null,
  'Buri - SP': null,
  'Buritama - SP': null,
  'Buritizal - SP': null,
  'Cabralia Paulista - SP': null,
  'Cabreuva - SP': null,
  'Cacapava - SP': null,
  'Cachoeira Paulista - SP': null,
  'Caconde - SP': null,
  'Cafelandia - SP': null,
  'Caiabu - SP': null,
  'Caieiras - SP': null,
  'Caiua - SP': null,
  'Cajamar - SP': null,
  'Cajati - SP': null,
  'Cajobi - SP': null,
  'Cajuru - SP': null,
  'Campina do Monte Alegre - SP': null,
  'Campinas - SP': null,
  'Campo Limpo Paulista - SP': null,
  'Campos do Jordao - SP': null,
  'Campos Novos Paulista - SP': null,
  'Cananeia - SP': null,
  'Canas - SP': null,
  'Candido Mota - SP': null,
  'Candido Rodrigues - SP': null,
  'Canitar - SP': null,
  'Capao Bonito - SP': null,
  'Capela do Alto - SP': null,
  'Capivari - SP': null,
  'Caraguatatuba - SP': null,
  'Carapicuiba - SP': null,
  'Cardoso - SP': null,
  'Casa Branca - SP': null,
  'Cassia dos Coqueiros - SP': null,
  'Castilho - SP': null,
  'Catanduva - SP': null,
  'Catigua - SP': null,
  'Cedral - SP': null,
  'Cerqueira Cesar - SP': null,
  'Cerquilho - SP': null,
  'Cesario Lange - SP': null,
  'Charqueada - SP': null,
  'Clementina - SP': null,
  'Colina - SP': null,
  'Colombia - SP': null,
  'Conchal - SP': null,
  'Conchas - SP': null,
  'Cordeiropolis - SP': null,
  'Coroados - SP': null,
  'Coronel Macedo - SP': null,
  'Corumbatai - SP': null,
  'Cosmopolis - SP': null,
  'Cosmorama - SP': null,
  'Cotia - SP': null,
  'Cravinhos - SP': null,
  'Cristais Paulista - SP': null,
  'Cruzalia - SP': null,
  'Cruzeiro - SP': null,
  'Cubatao - SP': null,
  'Cunha - SP': null,
  'Descalvado - SP': null,
  'Diadema - SP': null,
  'Dirce Reis - SP': null,
  'Divinolandia - SP': null,
  'Dobrada - SP': null,
  'Dois Corregos - SP': null,
  'Dolcinopolis - SP': null,
  'Dourado - SP': null,
  'Dracena - SP': null,
  'Duartina - SP': null,
  'Dumont - SP': null,
  'Echapora - SP': null,
  'Eldorado - SP': null,
  'Elias Fausto - SP': null,
  'Elisiario - SP': null,
  'Embauba - SP': null,
  'Embu - SP': null,
  'Embu-guacu - SP': null,
  'Emilianopolis - SP': null,
  'Engenheiro Coelho - SP': null,
  'Espirito Santo do Pinhal - SP': null,
  'Espirito Santo do Turvo - SP': null,
  "Estrela D'Oeste - SP": null,
  'Estrela do Norte - SP': null,
  'Euclides da Cunha Paulista - SP': null,
  'Fartura - SP': null,
  'Fernandopolis - SP': null,
  'Fernando Prestes - SP': null,
  'Fernao - SP': null,
  'Ferraz de Vasconcelos - SP': null,
  'Flora Rica - SP': null,
  'Floreal - SP': null,
  'Florida Paulista - SP': null,
  'Florinia - SP': null,
  'Franca - SP': null,
  'Francisco Morato - SP': null,
  'Franco da Rocha - SP': null,
  'Gabriel Monteiro - SP': null,
  'Galia - SP': null,
  'Garca - SP': null,
  'Gastao Vidigal - SP': null,
  'Gaviao Peixoto - SP': null,
  'General Salgado - SP': null,
  'Getulina - SP': null,
  'Glicerio - SP': null,
  'Guaicara - SP': null,
  'Guaimbe - SP': null,
  'Guaira - SP': null,
  'Guapiacu - SP': null,
  'Guapiara - SP': null,
  'Guara - SP': null,
  'Guaracai - SP': null,
  'Guaraci - SP': null,
  "Guarani D'Oeste - SP": null,
  'Guaranta - SP': null,
  'Guararapes - SP': null,
  'Guararema - SP': null,
  'Guaratingueta - SP': null,
  'Guarei - SP': null,
  'Guariba - SP': null,
  'Guaruja - SP': null,
  'Guarulhos - SP': null,
  'Guatapara - SP': null,
  'Guzolandia - SP': null,
  'Herculandia - SP': null,
  'Holambra - SP': null,
  'Hortolandia - SP': null,
  'Iacanga - SP': null,
  'Iacri - SP': null,
  'Iaras - SP': null,
  'Ibate - SP': null,
  'Ibira - SP': null,
  'Ibirarema - SP': null,
  'Ibitinga - SP': null,
  'Ibiuna - SP': null,
  'Icem - SP': null,
  'Iepe - SP': null,
  'Igaracu do Tiete - SP': null,
  'Igarapava - SP': null,
  'Igarata - SP': null,
  'Iguape - SP': null,
  'Ilhabela - SP': null,
  'Ilha Comprida - SP': null,
  'Ilha Solteira - SP': null,
  'Indaiatuba - SP': null,
  'Indiana - SP': null,
  'Indiapora - SP': null,
  'Inubia Paulista - SP': null,
  'Ipaussu - SP': null,
  'Ipero - SP': null,
  'Ipeuna - SP': null,
  'Ipigua - SP': null,
  'Iporanga - SP': null,
  'Ipua - SP': null,
  'Iracemapolis - SP': null,
  'Irapua - SP': null,
  'Irapuru - SP': null,
  'Itabera - SP': null,
  'Itai - SP': null,
  'Itajobi - SP': null,
  'Itaju - SP': null,
  'Itanhaem - SP': null,
  'Itaoca - SP': null,
  'Itapecerica da Serra - SP': null,
  'Itapetininga - SP': null,
  'Itapeva - SP': null,
  'Itapevi - SP': null,
  'Itapira - SP': null,
  'Itapirapua Paulista - SP': null,
  'Itapolis - SP': null,
  'Itaporanga - SP': null,
  'Itapui - SP': null,
  'Itapura - SP': null,
  'Itaquaquecetuba - SP': null,
  'Itarare - SP': null,
  'Itariri - SP': null,
  'Itatiba - SP': null,
  'Itatinga - SP': null,
  'Itirapina - SP': null,
  'Itirapua - SP': null,
  'Itobi - SP': null,
  'Itu - SP': null,
  'Itupeva - SP': null,
  'Ituverava - SP': null,
  'Jaborandi - SP': null,
  'Jaboticabal - SP': null,
  'Jacarei - SP': null,
  'Jaci - SP': null,
  'Jacupiranga - SP': null,
  'Jaguariuna - SP': null,
  'Jales - SP': null,
  'Jambeiro - SP': null,
  'Jandira - SP': null,
  'Jardinopolis - SP': null,
  'Jarinu - SP': null,
  'Jau - SP': null,
  'Jeriquara - SP': null,
  'Joanopolis - SP': null,
  'Joao Ramalho - SP': null,
  'Jose Bonifacio - SP': null,
  'Julio Mesquita - SP': null,
  'Jumirim - SP': null,
  'Jundiai - SP': null,
  'Junqueiropolis - SP': null,
  'Juquia - SP': null,
  'Juquitiba - SP': null,
  'Lagoinha - SP': null,
  'Laranjal Paulista - SP': null,
  'Lavinia - SP': null,
  'Lavrinhas - SP': null,
  'Leme - SP': null,
  'Lencois Paulista - SP': null,
  'Limeira - SP': null,
  'Lindoia - SP': null,
  'Lins - SP': null,
  'Lorena - SP': null,
  'Lourdes - SP': null,
  'Louveira - SP': null,
  'Lucelia - SP': null,
  'Lucianopolis - SP': null,
  'Luis Antonio - SP': null,
  'Luiziania - SP': null,
  'Lupercio - SP': null,
  'Lutecia - SP': null,
  'Macatuba - SP': null,
  'Macaubal - SP': null,
  'Macedonia - SP': null,
  'Magda - SP': null,
  'Mairinque - SP': null,
  'Mairipora - SP': null,
  'Manduri - SP': null,
  'Maraba Paulista - SP': null,
  'Maracai - SP': null,
  'Marapoama - SP': null,
  'Mariapolis - SP': null,
  'Marilia - SP': null,
  'Marinopolis - SP': null,
  'Martinopolis - SP': null,
  'Matao - SP': null,
  'Maua - SP': null,
  'Mendonca - SP': null,
  'Meridiano - SP': null,
  'Mesopolis - SP': null,
  'Miguelopolis - SP': null,
  'Mineiros do Tiete - SP': null,
  'Miracatu - SP': null,
  'Mira Estrela - SP': null,
  'Mirandopolis - SP': null,
  'Mirante do Paranapanema - SP': null,
  'Mirassol - SP': null,
  'Mirassolandia - SP': null,
  'Mococa - SP': null,
  'Mogi das Cruzes - SP': null,
  'Mogi Guacu - SP': null,
  'Moji Mirim - SP': null,
  'Mombuca - SP': null,
  'Moncoes - SP': null,
  'Mongagua - SP': null,
  'Monte Alegre do Sul - SP': null,
  'Monte Alto - SP': null,
  'Monte Aprazivel - SP': null,
  'Monte Azul Paulista - SP': null,
  'Monte Castelo - SP': null,
  'Monteiro Lobato - SP': null,
  'Monte Mor - SP': null,
  'Morro Agudo - SP': null,
  'Morungaba - SP': null,
  'Motuca - SP': null,
  'Murutinga do Sul - SP': null,
  'Nantes - SP': null,
  'Narandiba - SP': null,
  'Natividade da Serra - SP': null,
  'Nazare Paulista - SP': null,
  'Neves Paulista - SP': null,
  'Nhandeara - SP': null,
  'Nipoa - SP': null,
  'Nova Alianca - SP': null,
  'Nova Campina - SP': null,
  'Nova Canaa Paulista - SP': null,
  'Nova Castilho - SP': null,
  'Nova Europa - SP': null,
  'Nova Granada - SP': null,
  'Nova Guataporanga - SP': null,
  'Nova Independencia - SP': null,
  'Novais - SP': null,
  'Nova Luzitania - SP': null,
  'Nova Odessa - SP': null,
  'Novo Horizonte - SP': null,
  'Nuporanga - SP': null,
  'Ocaucu - SP': null,
  'Oleo - SP': null,
  'Olimpia - SP': null,
  'Onda Verde - SP': null,
  'Oriente - SP': null,
  'Orindiuva - SP': null,
  'Orlandia - SP': null,
  'Osasco - SP': null,
  'Oscar Bressane - SP': null,
  'Osvaldo Cruz - SP': null,
  'Ourinhos - SP': null,
  'Ouroeste - SP': null,
  'Ouro Verde - SP': null,
  'Pacaembu - SP': null,
  'Palestina - SP': null,
  'Palmares Paulista - SP': null,
  "Palmeira D'Oeste - SP": null,
  'Palmital - SP': null,
  'Panorama - SP': null,
  'Paraguacu Paulista - SP': null,
  'Paraibuna - SP': null,
  'Paraiso - SP': null,
  'Paranapanema - SP': null,
  'Paranapua - SP': null,
  'Parapua - SP': null,
  'Pardinho - SP': null,
  'Pariquera-acu - SP': null,
  'Parisi - SP': null,
  'Patrocinio Paulista - SP': null,
  'Pauliceia - SP': null,
  'Paulinia - SP': null,
  'Paulistania - SP': null,
  'Paulo de Faria - SP': null,
  'Pederneiras - SP': null,
  'Pedra Bela - SP': null,
  'Pedranopolis - SP': null,
  'Pedregulho - SP': null,
  'Pedreira - SP': null,
  'Pedrinhas Paulista - SP': null,
  'Pedro de Toledo - SP': null,
  'Penapolis - SP': null,
  'Pereira Barreto - SP': null,
  'Pereiras - SP': null,
  'Peruibe - SP': null,
  'Piacatu - SP': null,
  'Piedade - SP': null,
  'Pilar do Sul - SP': null,
  'Pindamonhangaba - SP': null,
  'Pindorama - SP': null,
  'Pinhalzinho - SP': null,
  'Piquerobi - SP': null,
  'Piquete - SP': null,
  'Piracaia - SP': null,
  'Piracicaba - SP': null,
  'Piraju - SP': null,
  'Pirajui - SP': null,
  'Pirangi - SP': null,
  'Pirapora do Bom Jesus - SP': null,
  'Pirapozinho - SP': null,
  'Pirassununga - SP': null,
  'Piratininga - SP': null,
  'Pitangueiras - SP': null,
  'Planalto - SP': null,
  'Platina - SP': null,
  'Poa - SP': null,
  'Poloni - SP': null,
  'Pompeia - SP': null,
  'Pongai - SP': null,
  'Pontal - SP': null,
  'Pontalinda - SP': null,
  'Pontes Gestal - SP': null,
  'Populina - SP': null,
  'Porangaba - SP': null,
  'Porto Feliz - SP': null,
  'Porto Ferreira - SP': null,
  'Potim - SP': null,
  'Potirendaba - SP': null,
  'Pracinha - SP': null,
  'Pradopolis - SP': null,
  'Praia Grande - SP': null,
  'Pratania - SP': null,
  'Presidente Alves - SP': null,
  'Presidente Bernardes - SP': null,
  'Presidente Epitacio - SP': null,
  'Presidente Prudente - SP': null,
  'Presidente Venceslau - SP': null,
  'Promissao - SP': null,
  'Quadra - SP': null,
  'Quata - SP': null,
  'Queiroz - SP': null,
  'Queluz - SP': null,
  'Quintana - SP': null,
  'Rafard - SP': null,
  'Rancharia - SP': null,
  'Redencao da Serra - SP': null,
  'Regente Feijo - SP': null,
  'Reginopolis - SP': null,
  'Registro - SP': null,
  'Restinga - SP': null,
  'Ribeira - SP': null,
  'Ribeirao Bonito - SP': null,
  'Ribeirao Branco - SP': null,
  'Ribeirao Corrente - SP': null,
  'Ribeirao do Sul - SP': null,
  'Ribeirao dos Indios - SP': null,
  'Ribeirao Grande - SP': null,
  'Ribeirao Pires - SP': null,
  'Ribeirao Preto - SP': null,
  'Riversul - SP': null,
  'Rifaina - SP': null,
  'Rincao - SP': null,
  'Rinopolis - SP': null,
  'Rio Claro - SP': null,
  'Rio das Pedras - SP': null,
  'Rio Grande da Serra - SP': null,
  'Riolandia - SP': null,
  'Rosana - SP': null,
  'Roseira - SP': null,
  'Rubiacea - SP': null,
  'Rubineia - SP': null,
  'Sabino - SP': null,
  'Sagres - SP': null,
  'Sales - SP': null,
  'Sales Oliveira - SP': null,
  'Salesopolis - SP': null,
  'Salmourao - SP': null,
  'Saltinho - SP': null,
  'Salto - SP': null,
  'Salto de Pirapora - SP': null,
  'Salto Grande - SP': null,
  'Sandovalina - SP': null,
  'Santa Adelia - SP': null,
  'Santa Albertina - SP': null,
  "Santa Barbara D'Oeste - SP": null,
  'Santa Branca - SP': null,
  "Santa Clara D'Oeste - SP": null,
  'Santa Cruz da Conceicao - SP': null,
  'Santa Cruz da Esperanca - SP': null,
  'Santa Cruz das Palmeiras - SP': null,
  'Santa Cruz do Rio Pardo - SP': null,
  'Santa Ernestina - SP': null,
  'Santa Fe do Sul - SP': null,
  'Santa Gertrudes - SP': null,
  'Santa Isabel - SP': null,
  'Santa Lucia - SP': null,
  'Santa Maria da Serra - SP': null,
  'Santa Mercedes - SP': null,
  'Santana da Ponte Pensa - SP': null,
  'Santana de Parnaiba - SP': null,
  "Santa Rita D'Oeste - SP": null,
  'Santa Rita do Passa Quatro - SP': null,
  'Santa Rosa de Viterbo - SP': null,
  'Santa Salete - SP': null,
  'Santo Anastacio - SP': null,
  'Santo Andre - SP': null,
  'Santo Antonio da Alegria - SP': null,
  'Santo Antonio de Posse - SP': null,
  'Santo Antonio do Aracangua - SP': null,
  'Santo Antonio do Jardim - SP': null,
  'Santo Antonio do Pinhal - SP': null,
  'Santo Expedito - SP': null,
  'Santopolis do Aguapei - SP': null,
  'Santos - SP': null,
  'Sao Bento do Sapucai - SP': null,
  'Sao Bernardo do Campo - SP': null,
  'Sao Caetano do Sul - SP': null,
  'Sao Carlos - SP': null,
  'Sao Francisco - SP': null,
  'Sao Joao da Boa Vista - SP': null,
  'Sao Joao das Duas Pontes - SP': null,
  'Sao Joao de Iracema - SP': null,
  "Sao Joao do Pau D'Alho - SP": null,
  'Sao Joaquim da Barra - SP': null,
  'Sao Jose da Bela Vista - SP': null,
  'Sao Jose do Barreiro - SP': null,
  'Sao Jose do Rio Pardo - SP': null,
  'Sao Jose do Rio Preto - SP': null,
  'Sao Jose dos Campos - SP': null,
  'Sao Lourenco da Serra - SP': null,
  'Sao Luis do Paraitinga - SP': null,
  'Sao Manuel - SP': null,
  'Sao Miguel Arcanjo - SP': null,
  'Sao Paulo - SP': null,
  'Sao Pedro - SP': null,
  'Sao Pedro do Turvo - SP': null,
  'Sao Roque - SP': null,
  'Sao Sebastiao - SP': null,
  'Sao Sebastiao da Grama - SP': null,
  'Sao Simao - SP': null,
  'Sao Vicente - SP': null,
  'Sarapui - SP': null,
  'Sarutaia - SP': null,
  'Sebastianopolis do Sul - SP': null,
  'Serra Azul - SP': null,
  'Serrana - SP': null,
  'Serra Negra - SP': null,
  'Sertaozinho - SP': null,
  'Sete Barras - SP': null,
  'Severinia - SP': null,
  'Silveiras - SP': null,
  'Socorro - SP': null,
  'Sorocaba - SP': null,
  'Sud Mennucci - SP': null,
  'Sumare - SP': null,
  'Suzano - SP': null,
  'Suzanapolis - SP': null,
  'Tabapua - SP': null,
  'Tabatinga - SP': null,
  'Taboao da Serra - SP': null,
  'Taciba - SP': null,
  'Taguai - SP': null,
  'Taiacu - SP': null,
  'Taiuva - SP': null,
  'Tambau - SP': null,
  'Tanabi - SP': null,
  'Tapirai - SP': null,
  'Tapiratiba - SP': null,
  'Taquaral - SP': null,
  'Taquaritinga - SP': null,
  'Taquarituba - SP': null,
  'Taquarivai - SP': null,
  'Tarabai - SP': null,
  'Taruma - SP': null,
  'Tatui - SP': null,
  'Taubate - SP': null,
  'Tejupa - SP': null,
  'Teodoro Sampaio - SP': null,
  'Terra Roxa - SP': null,
  'Tiete - SP': null,
  'Timburi - SP': null,
  'Torre de Pedra - SP': null,
  'Torrinha - SP': null,
  'Trabiju - SP': null,
  'Tremembe - SP': null,
  'Tres Fronteiras - SP': null,
  'Tuiuti - SP': null,
  'Tupa - SP': null,
  'Tupi Paulista - SP': null,
  'Turiuba - SP': null,
  'Turmalina - SP': null,
  'Ubarana - SP': null,
  'Ubatuba - SP': null,
  'Ubirajara - SP': null,
  'Uchoa - SP': null,
  'Uniao Paulista - SP': null,
  'Urania - SP': null,
  'Uru - SP': null,
  'Urupes - SP': null,
  'Valentim Gentil - SP': null,
  'Valinhos - SP': null,
  'Valparaiso - SP': null,
  'Vargem - SP': null,
  'Vargem Grande do Sul - SP': null,
  'Vargem Grande Paulista - SP': null,
  'Varzea Paulista - SP': null,
  'Vera Cruz - SP': null,
  'Vinhedo - SP': null,
  'Viradouro - SP': null,
  'Vista Alegre do Alto - SP': null,
  'Vitoria Brasil - SP': null,
  'Votorantim - SP': null,
  'Votuporanga - SP': null,
  'Zacarias - SP': null,
  'Chavantes - SP': null,
  'Estiva Gerbi - SP': null,
  'Abatia - PR': null,
  'Adrianopolis - PR': null,
  'Agudos do Sul - PR': null,
  'Almirante Tamandare - PR': null,
  'Altamira do Parana - PR': null,
  'Altonia - PR': null,
  'Alto Parana - PR': null,
  'Alto Piquiri - PR': null,
  'Alvorada do Sul - PR': null,
  'Amapora - PR': null,
  'Ampere - PR': null,
  'Anahy - PR': null,
  'Andira - PR': null,
  'Angulo - PR': null,
  'Antonina - PR': null,
  'Antonio Olinto - PR': null,
  'Apucarana - PR': null,
  'Arapongas - PR': null,
  'Arapoti - PR': null,
  'Arapua - PR': null,
  'Araruna - PR': null,
  'Araucaria - PR': null,
  'Ariranha do Ivai - PR': null,
  'Assai - PR': null,
  'Assis Chateaubriand - PR': null,
  'Astorga - PR': null,
  'Atalaia - PR': null,
  'Balsa Nova - PR': null,
  'Bandeirantes - PR': null,
  'Barbosa Ferraz - PR': null,
  'Barracao - PR': null,
  'Barra do Jacare - PR': null,
  'Bela Vista da Caroba - PR': null,
  'Bela Vista do Paraiso - PR': null,
  'Bituruna - PR': null,
  'Boa Esperanca - PR': null,
  'Boa Esperanca do Iguacu - PR': null,
  'Boa Ventura de Sao Roque - PR': null,
  'Boa Vista da Aparecida - PR': null,
  'Bocaiuva do Sul - PR': null,
  'Bom Jesus do Sul - PR': null,
  'Bom Sucesso - PR': null,
  'Bom Sucesso do Sul - PR': null,
  'Borrazopolis - PR': null,
  'Braganey - PR': null,
  'Brasilandia do Sul - PR': null,
  'Cafeara - PR': null,
  'Cafelandia - PR': null,
  'Cafezal do Sul - PR': null,
  'California - PR': null,
  'Cambara - PR': null,
  'Cambe - PR': null,
  'Cambira - PR': null,
  'Campina da Lagoa - PR': null,
  'Campina do Simao - PR': null,
  'Campina Grande do Sul - PR': null,
  'Campo Bonito - PR': null,
  'Campo do Tenente - PR': null,
  'Campo Largo - PR': null,
  'Campo Magro - PR': null,
  'Campo Mourao - PR': null,
  'Candido de Abreu - PR': null,
  'Candoi - PR': null,
  'Cantagalo - PR': null,
  'Capanema - PR': null,
  'Capitao Leonidas Marques - PR': null,
  'Carambei - PR': null,
  'Carlopolis - PR': null,
  'Cascavel - PR': null,
  'Castro - PR': null,
  'Catanduvas - PR': null,
  'Centenario do Sul - PR': null,
  'Cerro Azul - PR': null,
  'Ceu Azul - PR': null,
  'Chopinzinho - PR': null,
  'Cianorte - PR': null,
  'Cidade Gaucha - PR': null,
  'Clevelandia - PR': null,
  'Colombo - PR': null,
  'Colorado - PR': null,
  'Congonhinhas - PR': null,
  'Conselheiro Mairinck - PR': null,
  'Contenda - PR': null,
  'Corbelia - PR': null,
  'Cornelio Procopio - PR': null,
  'Coronel Domingos Soares - PR': null,
  'Coronel Vivida - PR': null,
  'Corumbatai do Sul - PR': null,
  'Cruzeiro do Iguacu - PR': null,
  'Cruzeiro do Oeste - PR': null,
  'Cruzeiro do Sul - PR': null,
  'Cruz Machado - PR': null,
  'Cruzmaltina - PR': null,
  'Curitiba - PR': null,
  'Curiuva - PR': null,
  'Diamante do Norte - PR': null,
  'Diamante do Sul - PR': null,
  "Diamante D'Oeste - PR": null,
  'Dois Vizinhos - PR': null,
  'Douradina - PR': null,
  'Doutor Camargo - PR': null,
  'Eneas Marques - PR': null,
  'Engenheiro Beltrao - PR': null,
  'Esperanca Nova - PR': null,
  'Entre Rios do Oeste - PR': null,
  'Espigao Alto do Iguacu - PR': null,
  'Farol - PR': null,
  'Faxinal - PR': null,
  'Fazenda Rio Grande - PR': null,
  'Fenix - PR': null,
  'Fernandes Pinheiro - PR': null,
  'Figueira - PR': null,
  'Florai - PR': null,
  'Flor da Serra do Sul - PR': null,
  'Floresta - PR': null,
  'Florestopolis - PR': null,
  'Florida - PR': null,
  'Formosa do Oeste - PR': null,
  'Foz do Iguacu - PR': null,
  'Francisco Alves - PR': null,
  'Francisco Beltrao - PR': null,
  'Foz do Jordao - PR': null,
  'General Carneiro - PR': null,
  'Godoy Moreira - PR': null,
  'Goioere - PR': null,
  'Goioxim - PR': null,
  'Grandes Rios - PR': null,
  'Guaira - PR': null,
  'Guairaca - PR': null,
  'Guamiranga - PR': null,
  'Guapirama - PR': null,
  'Guaporema - PR': null,
  'Guaraci - PR': null,
  'Guaraniacu - PR': null,
  'Guarapuava - PR': null,
  'Guaraquecaba - PR': null,
  'Guaratuba - PR': null,
  'Honorio Serpa - PR': null,
  'Ibaiti - PR': null,
  'Ibema - PR': null,
  'Ibipora - PR': null,
  'Icaraima - PR': null,
  'Iguaracu - PR': null,
  'Iguatu - PR': null,
  'Imbau - PR': null,
  'Imbituva - PR': null,
  'Inacio Martins - PR': null,
  'Inaja - PR': null,
  'Indianopolis - PR': null,
  'Ipiranga - PR': null,
  'Ipora - PR': null,
  'Iracema do Oeste - PR': null,
  'Irati - PR': null,
  'Iretama - PR': null,
  'Itaguaje - PR': null,
  'Itaipulandia - PR': null,
  'Itambaraca - PR': null,
  'Itambe - PR': null,
  "Itapejara D'Oeste - PR": null,
  'Itaperucu - PR': null,
  'Itauna do Sul - PR': null,
  'Ivai - PR': null,
  'Ivaipora - PR': null,
  'Ivate - PR': null,
  'Ivatuba - PR': null,
  'Jaboti - PR': null,
  'Jacarezinho - PR': null,
  'Jaguapita - PR': null,
  'Jaguariaiva - PR': null,
  'Jandaia do Sul - PR': null,
  'Janiopolis - PR': null,
  'Japira - PR': null,
  'Japura - PR': null,
  'Jardim Alegre - PR': null,
  'Jardim Olinda - PR': null,
  'Jataizinho - PR': null,
  'Jesuitas - PR': null,
  'Joaquim Tavora - PR': null,
  'Jundiai do Sul - PR': null,
  'Juranda - PR': null,
  'Jussara - PR': null,
  'Kalore - PR': null,
  'Lapa - PR': null,
  'Laranjal - PR': null,
  'Laranjeiras do Sul - PR': null,
  'Leopolis - PR': null,
  'Lidianopolis - PR': null,
  'Lindoeste - PR': null,
  'Loanda - PR': null,
  'Lobato - PR': null,
  'Londrina - PR': null,
  'Luiziana - PR': null,
  'Lunardelli - PR': null,
  'Lupionopolis - PR': null,
  'Mallet - PR': null,
  'Mambore - PR': null,
  'Mandaguacu - PR': null,
  'Mandaguari - PR': null,
  'Mandirituba - PR': null,
  'Manfrinopolis - PR': null,
  'Mangueirinha - PR': null,
  'Manoel Ribas - PR': null,
  'Marechal Candido Rondon - PR': null,
  'Maria Helena - PR': null,
  'Marialva - PR': null,
  'Marilandia do Sul - PR': null,
  'Marilena - PR': null,
  'Mariluz - PR': null,
  'Maringa - PR': null,
  'Mariopolis - PR': null,
  'Maripa - PR': null,
  'Marmeleiro - PR': null,
  'Marquinho - PR': null,
  'Marumbi - PR': null,
  'Matelandia - PR': null,
  'Matinhos - PR': null,
  'Mato Rico - PR': null,
  'Maua da Serra - PR': null,
  'Medianeira - PR': null,
  'Mercedes - PR': null,
  'Mirador - PR': null,
  'Miraselva - PR': null,
  'Missal - PR': null,
  'Moreira Sales - PR': null,
  'Morretes - PR': null,
  'Munhoz de Melo - PR': null,
  'Nossa Senhora das Gracas - PR': null,
  'Nova Alianca do Ivai - PR': null,
  'Nova America da Colina - PR': null,
  'Nova Aurora - PR': null,
  'Nova Cantu - PR': null,
  'Nova Esperanca - PR': null,
  'Nova Esperanca do Sudoeste - PR': null,
  'Nova Fatima - PR': null,
  'Nova Laranjeiras - PR': null,
  'Nova Londrina - PR': null,
  'Nova Olimpia - PR': null,
  'Nova Santa Barbara - PR': null,
  'Nova Santa Rosa - PR': null,
  'Nova Prata do Iguacu - PR': null,
  'Nova Tebas - PR': null,
  'Novo Itacolomi - PR': null,
  'Ortigueira - PR': null,
  'Ourizona - PR': null,
  'Ouro Verde do Oeste - PR': null,
  'Paicandu - PR': null,
  'Palmas - PR': null,
  'Palmeira - PR': null,
  'Palmital - PR': null,
  'Palotina - PR': null,
  'Paraiso do Norte - PR': null,
  'Paranacity - PR': null,
  'Paranagua - PR': null,
  'Paranapoema - PR': null,
  'Paranavai - PR': null,
  'Pato Bragado - PR': null,
  'Pato Branco - PR': null,
  'Paula Freitas - PR': null,
  'Paulo Frontin - PR': null,
  'Peabiru - PR': null,
  'Perobal - PR': null,
  'Perola - PR': null,
  "Perola D'Oeste - PR": null,
  'Pien - PR': null,
  'Pinhais - PR': null,
  'Pinhalao - PR': null,
  'Pinhal de Sao Bento - PR': null,
  'Pinhao - PR': null,
  'Pirai do Sul - PR': null,
  'Piraquara - PR': null,
  'Pitanga - PR': null,
  'Pitangueiras - PR': null,
  'Planaltina do Parana - PR': null,
  'Planalto - PR': null,
  'Ponta Grossa - PR': null,
  'Pontal do Parana - PR': null,
  'Porecatu - PR': null,
  'Porto Amazonas - PR': null,
  'Porto Barreiro - PR': null,
  'Porto Rico - PR': null,
  'Porto Vitoria - PR': null,
  'Prado Ferreira - PR': null,
  'Pranchita - PR': null,
  'Presidente Castelo Branco - PR': null,
  'Primeiro de Maio - PR': null,
  'Prudentopolis - PR': null,
  'Quarto Centenario - PR': null,
  'Quatigua - PR': null,
  'Quatro Barras - PR': null,
  'Quatro Pontes - PR': null,
  'Quedas do Iguacu - PR': null,
  'Querencia do Norte - PR': null,
  'Quinta do Sol - PR': null,
  'Quitandinha - PR': null,
  'Ramilandia - PR': null,
  'Rancho Alegre - PR': null,
  "Rancho Alegre D'Oeste - PR": null,
  'Realeza - PR': null,
  'Reboucas - PR': null,
  'Renascenca - PR': null,
  'Reserva - PR': null,
  'Reserva do Iguacu - PR': null,
  'Ribeirao Claro - PR': null,
  'Ribeirao do Pinhal - PR': null,
  'Rio Azul - PR': null,
  'Rio Bom - PR': null,
  'Rio Bonito do Iguacu - PR': null,
  'Rio Branco do Ivai - PR': null,
  'Rio Branco do Sul - PR': null,
  'Rio Negro - PR': null,
  'Rolandia - PR': null,
  'Roncador - PR': null,
  'Rondon - PR': null,
  'Rosario do Ivai - PR': null,
  'Sabaudia - PR': null,
  'Salgado Filho - PR': null,
  'Salto do Itarare - PR': null,
  'Salto do Lontra - PR': null,
  'Santa Amelia - PR': null,
  'Santa Cecilia do Pavao - PR': null,
  'Santa Cruz de Monte Castelo - PR': null,
  'Santa Fe - PR': null,
  'Santa Helena - PR': null,
  'Santa Ines - PR': null,
  'Santa Isabel do Ivai - PR': null,
  'Santa Izabel do Oeste - PR': null,
  'Santa Lucia - PR': null,
  'Santa Maria do Oeste - PR': null,
  'Santa Mariana - PR': null,
  'Santa Monica - PR': null,
  'Santana do Itarare - PR': null,
  'Santa Tereza do Oeste - PR': null,
  'Santa Terezinha de Itaipu - PR': null,
  'Santo Antonio da Platina - PR': null,
  'Santo Antonio do Caiua - PR': null,
  'Santo Antonio do Paraiso - PR': null,
  'Santo Antonio do Sudoeste - PR': null,
  'Santo Inacio - PR': null,
  'Sao Carlos do Ivai - PR': null,
  'Sao Jeronimo da Serra - PR': null,
  'Sao Joao - PR': null,
  'Sao Joao do Caiua - PR': null,
  'Sao Joao do Ivai - PR': null,
  'Sao Joao do Triunfo - PR': null,
  "Sao Jorge D'Oeste - PR": null,
  'Sao Jorge do Ivai - PR': null,
  'Sao Jorge do Patrocinio - PR': null,
  'Sao Jose da Boa Vista - PR': null,
  'Sao Jose das Palmeiras - PR': null,
  'Sao Jose dos Pinhais - PR': null,
  'Sao Manoel do Parana - PR': null,
  'Sao Mateus do Sul - PR': null,
  'Sao Miguel do Iguacu - PR': null,
  'Sao Pedro do Iguacu - PR': null,
  'Sao Pedro do Ivai - PR': null,
  'Sao Pedro do Parana - PR': null,
  'Sao Sebastiao da Amoreira - PR': null,
  'Sao Tome - PR': null,
  'Sapopema - PR': null,
  'Sarandi - PR': null,
  'Saudade do Iguacu - PR': null,
  'Senges - PR': null,
  'Serranopolis do Iguacu - PR': null,
  'Sertaneja - PR': null,
  'Sertanopolis - PR': null,
  'Siqueira Campos - PR': null,
  'Sulina - PR': null,
  'Tamarana - PR': null,
  'Tamboara - PR': null,
  'Tapejara - PR': null,
  'Tapira - PR': null,
  'Teixeira Soares - PR': null,
  'Telemaco Borba - PR': null,
  'Terra Boa - PR': null,
  'Terra Rica - PR': null,
  'Terra Roxa - PR': null,
  'Tibagi - PR': null,
  'Tijucas do Sul - PR': null,
  'Toledo - PR': null,
  'Tomazina - PR': null,
  'Tres Barras do Parana - PR': null,
  'Tunas do Parana - PR': null,
  'Tuneiras do Oeste - PR': null,
  'Tupassi - PR': null,
  'Turvo - PR': null,
  'Ubirata - PR': null,
  'Umuarama - PR': null,
  'Uniao da Vitoria - PR': null,
  'Uniflor - PR': null,
  'Urai - PR': null,
  'Wenceslau Braz - PR': null,
  'Ventania - PR': null,
  'Vera Cruz do Oeste - PR': null,
  'Vere - PR': null,
  'Alto Paraiso - PR': null,
  'Doutor Ulysses - PR': null,
  'Virmond - PR': null,
  'Vitorino - PR': null,
  'Xambre - PR': null,
  'Abdon Batista - SC': null,
  'Abelardo Luz - SC': null,
  'Agrolandia - SC': null,
  'Agronomica - SC': null,
  'Agua Doce - SC': null,
  'Aguas de Chapeco - SC': null,
  'Aguas Frias - SC': null,
  'Aguas Mornas - SC': null,
  'Alfredo Wagner - SC': null,
  'Alto Bela Vista - SC': null,
  'Anchieta - SC': null,
  'Angelina - SC': null,
  'Anita Garibaldi - SC': null,
  'Anitapolis - SC': null,
  'Antonio Carlos - SC': null,
  'Apiuna - SC': null,
  'Arabuta - SC': null,
  'Araquari - SC': null,
  'Ararangua - SC': null,
  'Armazem - SC': null,
  'Arroio Trinta - SC': null,
  'Arvoredo - SC': null,
  'Ascurra - SC': null,
  'Atalanta - SC': null,
  'Aurora - SC': null,
  'Balneario Arroio do Silva - SC': null,
  'Balneario Camboriu - SC': null,
  'Balneario Barra do Sul - SC': null,
  'Balneario Gaivota - SC': null,
  'Bandeirante - SC': null,
  'Barra Bonita - SC': null,
  'Barra Velha - SC': null,
  'Bela Vista do Toldo - SC': null,
  'Belmonte - SC': null,
  'Benedito Novo - SC': null,
  'Biguacu - SC': null,
  'Blumenau - SC': null,
  'Bocaina do Sul - SC': null,
  'Bombinhas - SC': null,
  'Bom Jardim da Serra - SC': null,
  'Bom Jesus - SC': null,
  'Bom Jesus do Oeste - SC': null,
  'Bom Retiro - SC': null,
  'Botuvera - SC': null,
  'Braco do Norte - SC': null,
  'Braco do Trombudo - SC': null,
  'Brunopolis - SC': null,
  'Brusque - SC': null,
  'Cacador - SC': null,
  'Caibi - SC': null,
  'Calmon - SC': null,
  'Camboriu - SC': null,
  'Capao Alto - SC': null,
  'Campo Alegre - SC': null,
  'Campo Belo do Sul - SC': null,
  'Campo Ere - SC': null,
  'Campos Novos - SC': null,
  'Canelinha - SC': null,
  'Canoinhas - SC': null,
  'Capinzal - SC': null,
  'Capivari de Baixo - SC': null,
  'Catanduvas - SC': null,
  'Caxambu do Sul - SC': null,
  'Celso Ramos - SC': null,
  'Cerro Negro - SC': null,
  'Chapadao do Lageado - SC': null,
  'Chapeco - SC': null,
  'Cocal do Sul - SC': null,
  'Concordia - SC': null,
  'Cordilheira Alta - SC': null,
  'Coronel Freitas - SC': null,
  'Coronel Martins - SC': null,
  'Corupa - SC': null,
  'Correia Pinto - SC': null,
  'Criciuma - SC': null,
  'Cunha Pora - SC': null,
  'Cunhatai - SC': null,
  'Curitibanos - SC': null,
  'Descanso - SC': null,
  'Dionisio Cerqueira - SC': null,
  'Dona Emma - SC': null,
  'Doutor Pedrinho - SC': null,
  'Entre Rios - SC': null,
  'Ermo - SC': null,
  'Erval Velho - SC': null,
  'Faxinal dos Guedes - SC': null,
  'Flor do Sertao - SC': null,
  'Florianopolis - SC': null,
  'Formosa do Sul - SC': null,
  'Forquilhinha - SC': null,
  'Fraiburgo - SC': null,
  'Frei Rogerio - SC': null,
  'Galvao - SC': null,
  'Garopaba - SC': null,
  'Garuva - SC': null,
  'Gaspar - SC': null,
  'Governador Celso Ramos - SC': null,
  'Grao Para - SC': null,
  'Gravatal - SC': null,
  'Guabiruba - SC': null,
  'Guaraciaba - SC': null,
  'Guaramirim - SC': null,
  'Guaruja do Sul - SC': null,
  'Guatambu - SC': null,
  "Herval D'Oeste - SC": null,
  'Ibiam - SC': null,
  'Ibicare - SC': null,
  'Ibirama - SC': null,
  'Icara - SC': null,
  'Ilhota - SC': null,
  'Imarui - SC': null,
  'Imbituba - SC': null,
  'Imbuia - SC': null,
  'Indaial - SC': null,
  'Iomere - SC': null,
  'Ipira - SC': null,
  'Ipora do Oeste - SC': null,
  'Ipuacu - SC': null,
  'Ipumirim - SC': null,
  'Iraceminha - SC': null,
  'Irani - SC': null,
  'Irati - SC': null,
  'Irineopolis - SC': null,
  'Ita - SC': null,
  'Itaiopolis - SC': null,
  'Itajai - SC': null,
  'Itapema - SC': null,
  'Itapiranga - SC': null,
  'Itapoa - SC': null,
  'Ituporanga - SC': null,
  'Jabora - SC': null,
  'Jacinto Machado - SC': null,
  'Jaguaruna - SC': null,
  'Jaragua do Sul - SC': null,
  'Jardinopolis - SC': null,
  'Joacaba - SC': null,
  'Joinville - SC': null,
  'Jose Boiteux - SC': null,
  'Jupia - SC': null,
  'Lacerdopolis - SC': null,
  'Lages - SC': null,
  'Laguna - SC': null,
  'Lajeado Grande - SC': null,
  'Laurentino - SC': null,
  'Lauro Muller - SC': null,
  'Lebon Regis - SC': null,
  'Leoberto Leal - SC': null,
  'Lindoia do Sul - SC': null,
  'Lontras - SC': null,
  'Luiz Alves - SC': null,
  'Luzerna - SC': null,
  'Macieira - SC': null,
  'Mafra - SC': null,
  'Major Gercino - SC': null,
  'Major Vieira - SC': null,
  'Maracaja - SC': null,
  'Maravilha - SC': null,
  'Marema - SC': null,
  'Massaranduba - SC': null,
  'Matos Costa - SC': null,
  'Meleiro - SC': null,
  'Mirim Doce - SC': null,
  'Modelo - SC': null,
  'Mondai - SC': null,
  'Monte Carlo - SC': null,
  'Monte Castelo - SC': null,
  'Morro da Fumaca - SC': null,
  'Morro Grande - SC': null,
  'Navegantes - SC': null,
  'Nova Erechim - SC': null,
  'Nova Itaberaba - SC': null,
  'Nova Trento - SC': null,
  'Nova Veneza - SC': null,
  'Novo Horizonte - SC': null,
  'Orleans - SC': null,
  'Otacilio Costa - SC': null,
  'Ouro - SC': null,
  'Ouro Verde - SC': null,
  'Paial - SC': null,
  'Painel - SC': null,
  'Palhoca - SC': null,
  'Palma Sola - SC': null,
  'Palmeira - SC': null,
  'Palmitos - SC': null,
  'Papanduva - SC': null,
  'Paraiso - SC': null,
  'Passo de Torres - SC': null,
  'Passos Maia - SC': null,
  'Paulo Lopes - SC': null,
  'Pedras Grandes - SC': null,
  'Penha - SC': null,
  'Peritiba - SC': null,
  'Petrolandia - SC': null,
  'Balneario Picarras - SC': null,
  'Pinhalzinho - SC': null,
  'Pinheiro Preto - SC': null,
  'Piratuba - SC': null,
  'Planalto Alegre - SC': null,
  'Pomerode - SC': null,
  'Ponte Alta - SC': null,
  'Ponte Alta do Norte - SC': null,
  'Ponte Serrada - SC': null,
  'Porto Belo - SC': null,
  'Porto Uniao - SC': null,
  'Pouso Redondo - SC': null,
  'Praia Grande - SC': null,
  'Presidente Castello Branco - SC': null,
  'Presidente Getulio - SC': null,
  'Presidente Nereu - SC': null,
  'Princesa - SC': null,
  'Quilombo - SC': null,
  'Rancho Queimado - SC': null,
  'Rio das Antas - SC': null,
  'Rio do Campo - SC': null,
  'Rio do Oeste - SC': null,
  'Rio dos Cedros - SC': null,
  'Rio do Sul - SC': null,
  'Rio Fortuna - SC': null,
  'Rio Negrinho - SC': null,
  'Rio Rufino - SC': null,
  'Riqueza - SC': null,
  'Rodeio - SC': null,
  'Romelandia - SC': null,
  'Salete - SC': null,
  'Saltinho - SC': null,
  'Salto Veloso - SC': null,
  'Sangao - SC': null,
  'Santa Cecilia - SC': null,
  'Santa Helena - SC': null,
  'Santa Rosa de Lima - SC': null,
  'Santa Rosa do Sul - SC': null,
  'Santa Terezinha - SC': null,
  'Santa Terezinha do Progresso - SC': null,
  'Santiago do Sul - SC': null,
  'Santo Amaro da Imperatriz - SC': null,
  'Sao Bernardino - SC': null,
  'Sao Bento do Sul - SC': null,
  'Sao Bonifacio - SC': null,
  'Sao Carlos - SC': null,
  'Sao Cristovao do Sul - SC': null,
  'Sao Domingos - SC': null,
  'Sao Francisco do Sul - SC': null,
  'Sao Joao do Oeste - SC': null,
  'Sao Joao Batista - SC': null,
  'Sao Joao do Itaperiu - SC': null,
  'Sao Joao do Sul - SC': null,
  'Sao Joaquim - SC': null,
  'Sao Jose - SC': null,
  'Sao Jose do Cedro - SC': null,
  'Sao Jose do Cerrito - SC': null,
  'Sao Lourenco do Oeste - SC': null,
  'Sao Ludgero - SC': null,
  'Sao Martinho - SC': null,
  'Sao Miguel da Boa Vista - SC': null,
  'Sao Miguel do Oeste - SC': null,
  'Sao Pedro de Alcantara - SC': null,
  'Saudades - SC': null,
  'Schroeder - SC': null,
  'Seara - SC': null,
  'Serra Alta - SC': null,
  'Sideropolis - SC': null,
  'Sombrio - SC': null,
  'Sul Brasil - SC': null,
  'Taio - SC': null,
  'Tangara - SC': null,
  'Tigrinhos - SC': null,
  'Tijucas - SC': null,
  'Timbe do Sul - SC': null,
  'Timbo - SC': null,
  'Timbo Grande - SC': null,
  'Tres Barras - SC': null,
  'Treviso - SC': null,
  'Treze de Maio - SC': null,
  'Treze Tilias - SC': null,
  'Trombudo Central - SC': null,
  'Tubarao - SC': null,
  'Tunapolis - SC': null,
  'Turvo - SC': null,
  'Uniao do Oeste - SC': null,
  'Urubici - SC': null,
  'Urupema - SC': null,
  'Urussanga - SC': null,
  'Vargeao - SC': null,
  'Vargem - SC': null,
  'Vargem Bonita - SC': null,
  'Vidal Ramos - SC': null,
  'Videira - SC': null,
  'Vitor Meireles - SC': null,
  'Witmarsum - SC': null,
  'Xanxere - SC': null,
  'Xavantina - SC': null,
  'Xaxim - SC': null,
  'Zortea - SC': null,
  'Acegua - RS': null,
  'Agua Santa - RS': null,
  'Agudo - RS': null,
  'Ajuricaba - RS': null,
  'Alecrim - RS': null,
  'Alegrete - RS': null,
  'Alegria - RS': null,
  'Almirante Tamandare do Sul - RS': null,
  'Alpestre - RS': null,
  'Alto Alegre - RS': null,
  'Alto Feliz - RS': null,
  'Alvorada - RS': null,
  'Amaral Ferrador - RS': null,
  'Ametista do Sul - RS': null,
  'Andre da Rocha - RS': null,
  'Anta Gorda - RS': null,
  'Antonio Prado - RS': null,
  'Arambare - RS': null,
  'Ararica - RS': null,
  'Aratiba - RS': null,
  'Arroio do Meio - RS': null,
  'Arroio do Sal - RS': null,
  'Arroio do Padre - RS': null,
  'Arroio dos Ratos - RS': null,
  'Arroio do Tigre - RS': null,
  'Arroio Grande - RS': null,
  'Arvorezinha - RS': null,
  'Augusto Pestana - RS': null,
  'Aurea - RS': null,
  'Bage - RS': null,
  'Balneario Pinhal - RS': null,
  'Barao - RS': null,
  'Barao de Cotegipe - RS': null,
  'Barao do Triunfo - RS': null,
  'Barracao - RS': null,
  'Barra do Guarita - RS': null,
  'Barra do Quarai - RS': null,
  'Barra do Ribeiro - RS': null,
  'Barra do Rio Azul - RS': null,
  'Barra Funda - RS': null,
  'Barros Cassal - RS': null,
  'Benjamin Constant do Sul - RS': null,
  'Bento Goncalves - RS': null,
  'Boa Vista das Missoes - RS': null,
  'Boa Vista do Burica - RS': null,
  'Boa Vista do Cadeado - RS': null,
  'Boa Vista do Incra - RS': null,
  'Boa Vista do Sul - RS': null,
  'Bom Jesus - RS': null,
  'Bom Principio - RS': null,
  'Bom Progresso - RS': null,
  'Bom Retiro do Sul - RS': null,
  'Boqueirao do Leao - RS': null,
  'Bossoroca - RS': null,
  'Bozano - RS': null,
  'Braga - RS': null,
  'Brochier - RS': null,
  'Butia - RS': null,
  'Cacapava do Sul - RS': null,
  'Cacequi - RS': null,
  'Cachoeira do Sul - RS': null,
  'Cachoeirinha - RS': null,
  'Cacique Doble - RS': null,
  'Caibate - RS': null,
  'Caicara - RS': null,
  'Camaqua - RS': null,
  'Camargo - RS': null,
  'Cambara do Sul - RS': null,
  'Campestre da Serra - RS': null,
  'Campina das Missoes - RS': null,
  'Campinas do Sul - RS': null,
  'Campo Bom - RS': null,
  'Campo Novo - RS': null,
  'Campos Borges - RS': null,
  'Candelaria - RS': null,
  'Candido Godoi - RS': null,
  'Candiota - RS': null,
  'Canela - RS': null,
  'Cangucu - RS': null,
  'Canoas - RS': null,
  'Canudos do Vale - RS': null,
  'Capao Bonito do Sul - RS': null,
  'Capao da Canoa - RS': null,
  'Capao do Cipo - RS': null,
  'Capao do Leao - RS': null,
  'Capivari do Sul - RS': null,
  'Capela de Santana - RS': null,
  'Capitao - RS': null,
  'Carazinho - RS': null,
  'Caraa - RS': null,
  'Carlos Barbosa - RS': null,
  'Carlos Gomes - RS': null,
  'Casca - RS': null,
  'Caseiros - RS': null,
  'Catuipe - RS': null,
  'Caxias do Sul - RS': null,
  'Centenario - RS': null,
  'Cerrito - RS': null,
  'Cerro Branco - RS': null,
  'Cerro Grande - RS': null,
  'Cerro Grande do Sul - RS': null,
  'Cerro Largo - RS': null,
  'Chapada - RS': null,
  'Charqueadas - RS': null,
  'Charrua - RS': null,
  'Chiapetta - RS': null,
  'Chui - RS': null,
  'Chuvisca - RS': null,
  'Cidreira - RS': null,
  'Ciriaco - RS': null,
  'Colinas - RS': null,
  'Colorado - RS': null,
  'Condor - RS': null,
  'Constantina - RS': null,
  'Coqueiro Baixo - RS': null,
  'Coqueiros do Sul - RS': null,
  'Coronel Barros - RS': null,
  'Coronel Bicaco - RS': null,
  'Coronel Pilar - RS': null,
  'Cotipora - RS': null,
  'Coxilha - RS': null,
  'Crissiumal - RS': null,
  'Cristal - RS': null,
  'Cristal do Sul - RS': null,
  'Cruz Alta - RS': null,
  'Cruzaltense - RS': null,
  'Cruzeiro do Sul - RS': null,
  'David Canabarro - RS': null,
  'Derrubadas - RS': null,
  'Dezesseis de Novembro - RS': null,
  'Dilermando de Aguiar - RS': null,
  'Dois Irmaos - RS': null,
  'Dois Irmaos das Missoes - RS': null,
  'Dois Lajeados - RS': null,
  'Dom Feliciano - RS': null,
  'Dom Pedro de Alcantara - RS': null,
  'Dom Pedrito - RS': null,
  'Dona Francisca - RS': null,
  'Doutor Mauricio Cardoso - RS': null,
  'Doutor Ricardo - RS': null,
  'Eldorado do Sul - RS': null,
  'Encantado - RS': null,
  'Encruzilhada do Sul - RS': null,
  'Engenho Velho - RS': null,
  'Entre-ijuis - RS': null,
  'Entre Rios do Sul - RS': null,
  'Erebango - RS': null,
  'Erechim - RS': null,
  'Ernestina - RS': null,
  'Herval - RS': null,
  'Erval Grande - RS': null,
  'Erval Seco - RS': null,
  'Esmeralda - RS': null,
  'Esperanca do Sul - RS': null,
  'Espumoso - RS': null,
  'Estacao - RS': null,
  'Estancia Velha - RS': null,
  'Esteio - RS': null,
  'Estrela - RS': null,
  'Estrela Velha - RS': null,
  'Eugenio de Castro - RS': null,
  'Fagundes Varela - RS': null,
  'Farroupilha - RS': null,
  'Faxinal do Soturno - RS': null,
  'Faxinalzinho - RS': null,
  'Fazenda Vilanova - RS': null,
  'Feliz - RS': null,
  'Flores da Cunha - RS': null,
  'Floriano Peixoto - RS': null,
  'Fontoura Xavier - RS': null,
  'Formigueiro - RS': null,
  'Forquetinha - RS': null,
  'Fortaleza dos Valos - RS': null,
  'Frederico Westphalen - RS': null,
  'Garibaldi - RS': null,
  'Garruchos - RS': null,
  'Gaurama - RS': null,
  'General Camara - RS': null,
  'Gentil - RS': null,
  'Getulio Vargas - RS': null,
  'Girua - RS': null,
  'Glorinha - RS': null,
  'Gramado - RS': null,
  'Gramado dos Loureiros - RS': null,
  'Gramado Xavier - RS': null,
  'Gravatai - RS': null,
  'Guabiju - RS': null,
  'Guaiba - RS': null,
  'Guapore - RS': null,
  'Guarani das Missoes - RS': null,
  'Harmonia - RS': null,
  'Herveiras - RS': null,
  'Horizontina - RS': null,
  'Hulha Negra - RS': null,
  'Humaita - RS': null,
  'Ibarama - RS': null,
  'Ibiaca - RS': null,
  'Ibiraiaras - RS': null,
  'Ibirapuita - RS': null,
  'Ibiruba - RS': null,
  'Igrejinha - RS': null,
  'Ijui - RS': null,
  'Ilopolis - RS': null,
  'Imbe - RS': null,
  'Imigrante - RS': null,
  'Independencia - RS': null,
  'Inhacora - RS': null,
  'Ipe - RS': null,
  'Ipiranga do Sul - RS': null,
  'Irai - RS': null,
  'Itaara - RS': null,
  'Itacurubi - RS': null,
  'Itapuca - RS': null,
  'Itaqui - RS': null,
  'Itati - RS': null,
  'Itatiba do Sul - RS': null,
  'Ivora - RS': null,
  'Ivoti - RS': null,
  'Jaboticaba - RS': null,
  'Jacuizinho - RS': null,
  'Jacutinga - RS': null,
  'Jaguarao - RS': null,
  'Jaguari - RS': null,
  'Jaquirana - RS': null,
  'Jari - RS': null,
  'Joia - RS': null,
  'Julio de Castilhos - RS': null,
  'Lagoa Bonita do Sul - RS': null,
  'Lagoao - RS': null,
  'Lagoa dos Tres Cantos - RS': null,
  'Lagoa Vermelha - RS': null,
  'Lajeado - RS': null,
  'Lajeado do Bugre - RS': null,
  'Lavras do Sul - RS': null,
  'Liberato Salzano - RS': null,
  'Lindolfo Collor - RS': null,
  'Linha Nova - RS': null,
  'Machadinho - RS': null,
  'Macambara - RS': null,
  'Mampituba - RS': null,
  'Manoel Viana - RS': null,
  'Maquine - RS': null,
  'Marata - RS': null,
  'Marau - RS': null,
  'Marcelino Ramos - RS': null,
  'Mariana Pimentel - RS': null,
  'Mariano Moro - RS': null,
  'Marques de Souza - RS': null,
  'Mata - RS': null,
  'Mato Castelhano - RS': null,
  'Mato Leitao - RS': null,
  'Mato Queimado - RS': null,
  'Maximiliano de Almeida - RS': null,
  'Minas do Leao - RS': null,
  'Miraguai - RS': null,
  'Montauri - RS': null,
  'Monte Alegre dos Campos - RS': null,
  'Monte Belo do Sul - RS': null,
  'Montenegro - RS': null,
  'Mormaco - RS': null,
  'Morrinhos do Sul - RS': null,
  'Morro Redondo - RS': null,
  'Morro Reuter - RS': null,
  'Mostardas - RS': null,
  'Mucum - RS': null,
  'Muitos Capoes - RS': null,
  'Muliterno - RS': null,
  'Nao-me-toque - RS': null,
  'Nicolau Vergueiro - RS': null,
  'Nonoai - RS': null,
  'Nova Alvorada - RS': null,
  'Nova Araca - RS': null,
  'Nova Bassano - RS': null,
  'Nova Boa Vista - RS': null,
  'Nova Brescia - RS': null,
  'Nova Candelaria - RS': null,
  'Nova Esperanca do Sul - RS': null,
  'Nova Hartz - RS': null,
  'Nova Padua - RS': null,
  'Nova Palma - RS': null,
  'Nova Petropolis - RS': null,
  'Nova Prata - RS': null,
  'Nova Ramada - RS': null,
  'Nova Roma do Sul - RS': null,
  'Nova Santa Rita - RS': null,
  'Novo Cabrais - RS': null,
  'Novo Hamburgo - RS': null,
  'Novo Machado - RS': null,
  'Novo Tiradentes - RS': null,
  'Novo Xingu - RS': null,
  'Novo Barreiro - RS': null,
  'Osorio - RS': null,
  'Paim Filho - RS': null,
  'Palmares do Sul - RS': null,
  'Palmeira das Missoes - RS': null,
  'Palmitinho - RS': null,
  'Panambi - RS': null,
  'Pantano Grande - RS': null,
  'Parai - RS': null,
  'Paraiso do Sul - RS': null,
  'Pareci Novo - RS': null,
  'Parobe - RS': null,
  'Passa Sete - RS': null,
  'Passo do Sobrado - RS': null,
  'Passo Fundo - RS': null,
  'Paulo Bento - RS': null,
  'Paverama - RS': null,
  'Pedras Altas - RS': null,
  'Pedro Osorio - RS': null,
  'Pejucara - RS': null,
  'Pelotas - RS': null,
  'Picada Cafe - RS': null,
  'Pinhal - RS': null,
  'Pinhal da Serra - RS': null,
  'Pinhal Grande - RS': null,
  'Pinheirinho do Vale - RS': null,
  'Pinheiro Machado - RS': null,
  'Pirapo - RS': null,
  'Piratini - RS': null,
  'Planalto - RS': null,
  'Poco das Antas - RS': null,
  'Pontao - RS': null,
  'Ponte Preta - RS': null,
  'Portao - RS': null,
  'Porto Alegre - RS': null,
  'Porto Lucena - RS': null,
  'Porto Maua - RS': null,
  'Porto Vera Cruz - RS': null,
  'Porto Xavier - RS': null,
  'Pouso Novo - RS': null,
  'Presidente Lucena - RS': null,
  'Progresso - RS': null,
  'Protasio Alves - RS': null,
  'Putinga - RS': null,
  'Quarai - RS': null,
  'Quatro Irmaos - RS': null,
  'Quevedos - RS': null,
  'Quinze de Novembro - RS': null,
  'Redentora - RS': null,
  'Relvado - RS': null,
  'Restinga Seca - RS': null,
  'Rio dos Indios - RS': null,
  'Rio Grande - RS': null,
  'Rio Pardo - RS': null,
  'Riozinho - RS': null,
  'Roca Sales - RS': null,
  'Rodeio Bonito - RS': null,
  'Rolador - RS': null,
  'Rolante - RS': null,
  'Ronda Alta - RS': null,
  'Rondinha - RS': null,
  'Roque Gonzales - RS': null,
  'Rosario do Sul - RS': null,
  'Sagrada Familia - RS': null,
  'Saldanha Marinho - RS': null,
  'Salto do Jacui - RS': null,
  'Salvador das Missoes - RS': null,
  'Salvador do Sul - RS': null,
  'Sananduva - RS': null,
  'Santa Barbara do Sul - RS': null,
  'Santa Cecilia do Sul - RS': null,
  'Santa Clara do Sul - RS': null,
  'Santa Cruz do Sul - RS': null,
  'Santa Maria - RS': null,
  'Santa Maria do Herval - RS': null,
  'Santa Margarida do Sul - RS': null,
  'Santana da Boa Vista - RS': null,
  "Sant'ana do Livramento - RS": null,
  'Santa Rosa - RS': null,
  'Santa Tereza - RS': null,
  'Santa Vitoria do Palmar - RS': null,
  'Santiago - RS': null,
  'Santo Angelo - RS': null,
  'Santo Antonio do Palma - RS': null,
  'Santo Antonio da Patrulha - RS': null,
  'Santo Antonio das Missoes - RS': null,
  'Santo Antonio do Planalto - RS': null,
  'Santo Augusto - RS': null,
  'Santo Cristo - RS': null,
  'Santo Expedito do Sul - RS': null,
  'Sao Borja - RS': null,
  'Sao Domingos do Sul - RS': null,
  'Sao Francisco de Assis - RS': null,
  'Sao Francisco de Paula - RS': null,
  'Sao Gabriel - RS': null,
  'Sao Jeronimo - RS': null,
  'Sao Joao da Urtiga - RS': null,
  'Sao Joao do Polesine - RS': null,
  'Sao Jorge - RS': null,
  'Sao Jose das Missoes - RS': null,
  'Sao Jose do Herval - RS': null,
  'Sao Jose do Hortencio - RS': null,
  'Sao Jose do Inhacora - RS': null,
  'Sao Jose do Norte - RS': null,
  'Sao Jose do Ouro - RS': null,
  'Sao Jose do Sul - RS': null,
  'Sao Jose dos Ausentes - RS': null,
  'Sao Leopoldo - RS': null,
  'Sao Lourenco do Sul - RS': null,
  'Sao Luiz Gonzaga - RS': null,
  'Sao Marcos - RS': null,
  'Sao Martinho - RS': null,
  'Sao Martinho da Serra - RS': null,
  'Sao Miguel das Missoes - RS': null,
  'Sao Nicolau - RS': null,
  'Sao Paulo das Missoes - RS': null,
  'Sao Pedro da Serra - RS': null,
  'Sao Pedro das Missoes - RS': null,
  'Sao Pedro do Butia - RS': null,
  'Sao Pedro do Sul - RS': null,
  'Sao Sebastiao do Cai - RS': null,
  'Sao Sepe - RS': null,
  'Sao Valentim - RS': null,
  'Sao Valentim do Sul - RS': null,
  'Sao Valerio do Sul - RS': null,
  'Sao Vendelino - RS': null,
  'Sao Vicente do Sul - RS': null,
  'Sapiranga - RS': null,
  'Sapucaia do Sul - RS': null,
  'Sarandi - RS': null,
  'Seberi - RS': null,
  'Sede Nova - RS': null,
  'Segredo - RS': null,
  'Selbach - RS': null,
  'Senador Salgado Filho - RS': null,
  'Sentinela do Sul - RS': null,
  'Serafina Correa - RS': null,
  'Serio - RS': null,
  'Sertao - RS': null,
  'Sertao Santana - RS': null,
  'Sete de Setembro - RS': null,
  'Severiano de Almeida - RS': null,
  'Silveira Martins - RS': null,
  'Sinimbu - RS': null,
  'Sobradinho - RS': null,
  'Soledade - RS': null,
  'Tabai - RS': null,
  'Tapejara - RS': null,
  'Tapera - RS': null,
  'Tapes - RS': null,
  'Taquara - RS': null,
  'Taquari - RS': null,
  'Taquarucu do Sul - RS': null,
  'Tavares - RS': null,
  'Tenente Portela - RS': null,
  'Terra de Areia - RS': null,
  'Teutonia - RS': null,
  'Tio Hugo - RS': null,
  'Tiradentes do Sul - RS': null,
  'Toropi - RS': null,
  'Torres - RS': null,
  'Tramandai - RS': null,
  'Travesseiro - RS': null,
  'Tres Arroios - RS': null,
  'Tres Cachoeiras - RS': null,
  'Tres Coroas - RS': null,
  'Tres de Maio - RS': null,
  'Tres Forquilhas - RS': null,
  'Tres Palmeiras - RS': null,
  'Tres Passos - RS': null,
  'Trindade do Sul - RS': null,
  'Triunfo - RS': null,
  'Tucunduva - RS': null,
  'Tunas - RS': null,
  'Tupanci do Sul - RS': null,
  'Tupancireta - RS': null,
  'Tupandi - RS': null,
  'Tuparendi - RS': null,
  'Turucu - RS': null,
  'Ubiretama - RS': null,
  'Uniao da Serra - RS': null,
  'Unistalda - RS': null,
  'Uruguaiana - RS': null,
  'Vacaria - RS': null,
  'Vale Verde - RS': null,
  'Vale do Sol - RS': null,
  'Vale Real - RS': null,
  'Vanini - RS': null,
  'Venancio Aires - RS': null,
  'Vera Cruz - RS': null,
  'Veranopolis - RS': null,
  'Vespasiano Correa - RS': null,
  'Viadutos - RS': null,
  'Viamao - RS': null,
  'Vicente Dutra - RS': null,
  'Victor Graeff - RS': null,
  'Vila Flores - RS': null,
  'Vila Langaro - RS': null,
  'Vila Maria - RS': null,
  'Vila Nova do Sul - RS': null,
  'Vista Alegre - RS': null,
  'Vista Alegre do Prata - RS': null,
  'Vista Gaucha - RS': null,
  'Vitoria das Missoes - RS': null,
  'Westfalia - RS': null,
  'Xangri-la - RS': null,
  'Agua Clara - MS': null,
  'Alcinopolis - MS': null,
  'Amambai - MS': null,
  'Anastacio - MS': null,
  'Anaurilandia - MS': null,
  'Angelica - MS': null,
  'Antonio Joao - MS': null,
  'Aparecida do Taboado - MS': null,
  'Aquidauana - MS': null,
  'Aral Moreira - MS': null,
  'Bandeirantes - MS': null,
  'Bataguassu - MS': null,
  'Bataypora - MS': null,
  'Bela Vista - MS': null,
  'Bodoquena - MS': null,
  'Bonito - MS': null,
  'Brasilandia - MS': null,
  'Caarapo - MS': null,
  'Camapua - MS': null,
  'Campo Grande - MS': null,
  'Caracol - MS': null,
  'Cassilandia - MS': null,
  'Chapadao do Sul - MS': null,
  'Corguinho - MS': null,
  'Coronel Sapucaia - MS': null,
  'Corumba - MS': null,
  'Costa Rica - MS': null,
  'Coxim - MS': null,
  'Deodapolis - MS': null,
  'Dois Irmaos do Buriti - MS': null,
  'Douradina - MS': null,
  'Dourados - MS': null,
  'Eldorado - MS': null,
  'Fatima do Sul - MS': null,
  'Figueirao - MS': null,
  'Gloria de Dourados - MS': null,
  'Guia Lopes da Laguna - MS': null,
  'Iguatemi - MS': null,
  'Inocencia - MS': null,
  'Itapora - MS': null,
  'Itaquirai - MS': null,
  'Ivinhema - MS': null,
  'Japora - MS': null,
  'Jaraguari - MS': null,
  'Jardim - MS': null,
  'Jatei - MS': null,
  'Juti - MS': null,
  'Ladario - MS': null,
  'Laguna Carapa - MS': null,
  'Maracaju - MS': null,
  'Miranda - MS': null,
  'Mundo Novo - MS': null,
  'Navirai - MS': null,
  'Nioaque - MS': null,
  'Nova Alvorada do Sul - MS': null,
  'Nova Andradina - MS': null,
  'Novo Horizonte do Sul - MS': null,
  'Paranaiba - MS': null,
  'Paranhos - MS': null,
  'Pedro Gomes - MS': null,
  'Ponta Pora - MS': null,
  'Porto Murtinho - MS': null,
  'Ribas do Rio Pardo - MS': null,
  'Rio Brilhante - MS': null,
  'Rio Negro - MS': null,
  'Rio Verde de Mato Grosso - MS': null,
  'Rochedo - MS': null,
  'Santa Rita do Pardo - MS': null,
  'Sao Gabriel do Oeste - MS': null,
  'Sete Quedas - MS': null,
  'Selviria - MS': null,
  'Sidrolandia - MS': null,
  'Sonora - MS': null,
  'Tacuru - MS': null,
  'Taquarussu - MS': null,
  'Terenos - MS': null,
  'Tres Lagoas - MS': null,
  'Vicentina - MS': null,
  'Acorizal - MT': null,
  'Agua Boa - MT': null,
  'Alta Floresta - MT': null,
  'Alto Araguaia - MT': null,
  'Alto Boa Vista - MT': null,
  'Alto Garcas - MT': null,
  'Alto Paraguai - MT': null,
  'Alto Taquari - MT': null,
  'Apiacas - MT': null,
  'Araguaiana - MT': null,
  'Araguainha - MT': null,
  'Araputanga - MT': null,
  'Arenapolis - MT': null,
  'Aripuana - MT': null,
  'Barao de Melgaco - MT': null,
  'Barra do Bugres - MT': null,
  'Barra do Garcas - MT': null,
  'Bom Jesus do Araguaia - MT': null,
  'Brasnorte - MT': null,
  'Caceres - MT': null,
  'Campinapolis - MT': null,
  'Campo Novo do Parecis - MT': null,
  'Campo Verde - MT': null,
  'Campos de Julio - MT': null,
  'Canabrava do Norte - MT': null,
  'Canarana - MT': null,
  'Carlinda - MT': null,
  'Castanheira - MT': null,
  'Chapada dos Guimaraes - MT': null,
  'Claudia - MT': null,
  'Cocalinho - MT': null,
  'Colider - MT': null,
  'Colniza - MT': null,
  'Comodoro - MT': null,
  'Confresa - MT': null,
  "Conquista D'Oeste - MT": null,
  'Cotriguacu - MT': null,
  'Cuiaba - MT': null,
  'Curvelandia - MT': null,
  'Denise - MT': null,
  'Diamantino - MT': null,
  'Dom Aquino - MT': null,
  'Feliz Natal - MT': null,
  "Figueiropolis D'Oeste - MT": null,
  'Gaucha do Norte - MT': null,
  'General Carneiro - MT': null,
  "Gloria D'Oeste - MT": null,
  'Guaranta do Norte - MT': null,
  'Guiratinga - MT': null,
  'Indiavai - MT': null,
  'Ipiranga do Norte - MT': null,
  'Itanhanga - MT': null,
  'Itauba - MT': null,
  'Itiquira - MT': null,
  'Jaciara - MT': null,
  'Jangada - MT': null,
  'Jauru - MT': null,
  'Juara - MT': null,
  'Juina - MT': null,
  'Juruena - MT': null,
  'Juscimeira - MT': null,
  "Lambari D'Oeste - MT": null,
  'Lucas do Rio Verde - MT': null,
  'Luciara - MT': null,
  'Vila Bela da Santissima Trindade - MT': null,
  'Marcelandia - MT': null,
  'Matupa - MT': null,
  "Mirassol D'Oeste - MT": null,
  'Nobres - MT': null,
  'Nortelandia - MT': null,
  'Nossa Senhora do Livramento - MT': null,
  'Nova Bandeirantes - MT': null,
  'Nova Nazare - MT': null,
  'Nova Lacerda - MT': null,
  'Nova Santa Helena - MT': null,
  'Nova Brasilandia - MT': null,
  'Nova Canaa do Norte - MT': null,
  'Nova Mutum - MT': null,
  'Nova Olimpia - MT': null,
  'Nova Ubirata - MT': null,
  'Nova Xavantina - MT': null,
  'Novo Mundo - MT': null,
  'Novo Horizonte do Norte - MT': null,
  'Novo Sao Joaquim - MT': null,
  'Paranaita - MT': null,
  'Paranatinga - MT': null,
  'Novo Santo Antonio - MT': null,
  'Pedra Preta - MT': null,
  'Peixoto de Azevedo - MT': null,
  'Planalto da Serra - MT': null,
  'Pocone - MT': null,
  'Pontal do Araguaia - MT': null,
  'Ponte Branca - MT': null,
  'Pontes E Lacerda - MT': null,
  'Porto Alegre do Norte - MT': null,
  'Porto dos Gauchos - MT': null,
  'Porto Esperidiao - MT': null,
  'Porto Estrela - MT': null,
  'Poxoreo - MT': null,
  'Primavera do Leste - MT': null,
  'Querencia - MT': null,
  'Sao Jose dos Quatro Marcos - MT': null,
  'Reserva do Cabacal - MT': null,
  'Ribeirao Cascalheira - MT': null,
  'Ribeiraozinho - MT': null,
  'Rio Branco - MT': null,
  'Santa Carmem - MT': null,
  'Santo Afonso - MT': null,
  'Sao Jose do Povo - MT': null,
  'Sao Jose do Rio Claro - MT': null,
  'Sao Jose do Xingu - MT': null,
  'Sao Pedro da Cipa - MT': null,
  'Rondolandia - MT': null,
  'Rondonopolis - MT': null,
  'Rosario Oeste - MT': null,
  'Santa Cruz do Xingu - MT': null,
  'Salto do Ceu - MT': null,
  'Santa Rita do Trivelato - MT': null,
  'Santa Terezinha - MT': null,
  'Santo Antonio do Leste - MT': null,
  'Santo Antonio do Leverger - MT': null,
  'Sao Felix do Araguaia - MT': null,
  'Sapezal - MT': null,
  'Serra Nova Dourada - MT': null,
  'Sinop - MT': null,
  'Sorriso - MT': null,
  'Tabapora - MT': null,
  'Tangara da Serra - MT': null,
  'Tapurah - MT': null,
  'Terra Nova do Norte - MT': null,
  'Tesouro - MT': null,
  'Torixoreu - MT': null,
  'Uniao do Sul - MT': null,
  'Vale de Sao Domingos - MT': null,
  'Varzea Grande - MT': null,
  'Vera - MT': null,
  'Vila Rica - MT': null,
  'Nova Guarita - MT': null,
  'Nova Marilandia - MT': null,
  'Nova Maringa - MT': null,
  'Nova Monte Verde - MT': null,
  'Abadia de Goias - GO': null,
  'Abadiania - GO': null,
  'Acreuna - GO': null,
  'Adelandia - GO': null,
  'Agua Fria de Goias - GO': null,
  'Agua Limpa - GO': null,
  'Aguas Lindas de Goias - GO': null,
  'Alexania - GO': null,
  'Aloandia - GO': null,
  'Alto Horizonte - GO': null,
  'Alto Paraiso de Goias - GO': null,
  'Alvorada do Norte - GO': null,
  'Amaralina - GO': null,
  'Americano do Brasil - GO': null,
  'Amorinopolis - GO': null,
  'Anapolis - GO': null,
  'Anhanguera - GO': null,
  'Anicuns - GO': null,
  'Aparecida de Goiania - GO': null,
  'Aparecida do Rio Doce - GO': null,
  'Apore - GO': null,
  'Aracu - GO': null,
  'Aragarcas - GO': null,
  'Aragoiania - GO': null,
  'Araguapaz - GO': null,
  'Arenopolis - GO': null,
  'Aruana - GO': null,
  'Aurilandia - GO': null,
  'Avelinopolis - GO': null,
  'Baliza - GO': null,
  'Barro Alto - GO': null,
  'Bela Vista de Goias - GO': null,
  'Bom Jardim de Goias - GO': null,
  'Bom Jesus de Goias - GO': null,
  'Bonfinopolis - GO': null,
  'Bonopolis - GO': null,
  'Brazabrantes - GO': null,
  'Britania - GO': null,
  'Buriti Alegre - GO': null,
  'Buriti de Goias - GO': null,
  'Buritinopolis - GO': null,
  'Cabeceiras - GO': null,
  'Cachoeira Alta - GO': null,
  'Cachoeira de Goias - GO': null,
  'Cachoeira Dourada - GO': null,
  'Cacu - GO': null,
  'Caiaponia - GO': null,
  'Caldas Novas - GO': null,
  'Caldazinha - GO': null,
  'Campestre de Goias - GO': null,
  'Campinacu - GO': null,
  'Campinorte - GO': null,
  'Campo Alegre de Goias - GO': null,
  'Campo Limpo de Goias - GO': null,
  'Campos Belos - GO': null,
  'Campos Verdes - GO': null,
  'Carmo do Rio Verde - GO': null,
  'Castelandia - GO': null,
  'Catalao - GO': null,
  'Caturai - GO': null,
  'Cavalcante - GO': null,
  'Ceres - GO': null,
  'Cezarina - GO': null,
  'Chapadao do Ceu - GO': null,
  'Cidade Ocidental - GO': null,
  'Cocalzinho de Goias - GO': null,
  'Colinas do Sul - GO': null,
  'Corrego do Ouro - GO': null,
  'Corumba de Goias - GO': null,
  'Corumbaiba - GO': null,
  'Cristalina - GO': null,
  'Cristianopolis - GO': null,
  'Crixas - GO': null,
  'Crominia - GO': null,
  'Cumari - GO': null,
  'Damianopolis - GO': null,
  'Damolandia - GO': null,
  'Davinopolis - GO': null,
  'Diorama - GO': null,
  'Doverlandia - GO': null,
  'Edealina - GO': null,
  'Edeia - GO': null,
  'Estrela do Norte - GO': null,
  'Faina - GO': null,
  'Fazenda Nova - GO': null,
  'Firminopolis - GO': null,
  'Flores de Goias - GO': null,
  'Formosa - GO': null,
  'Formoso - GO': null,
  'Gameleira de Goias - GO': null,
  'Divinopolis de Goias - GO': null,
  'Goianapolis - GO': null,
  'Goiandira - GO': null,
  'Goianesia - GO': null,
  'Goiania - GO': null,
  'Goianira - GO': null,
  'Goias - GO': null,
  'Goiatuba - GO': null,
  'Gouvelandia - GO': null,
  'Guapo - GO': null,
  'Guaraita - GO': null,
  'Guarani de Goias - GO': null,
  'Guarinos - GO': null,
  'Heitorai - GO': null,
  'Hidrolandia - GO': null,
  'Hidrolina - GO': null,
  'Iaciara - GO': null,
  'Inaciolandia - GO': null,
  'Indiara - GO': null,
  'Inhumas - GO': null,
  'Ipameri - GO': null,
  'Ipiranga de Goias - GO': null,
  'Ipora - GO': null,
  'Israelandia - GO': null,
  'Itaberai - GO': null,
  'Itaguari - GO': null,
  'Itaguaru - GO': null,
  'Itaja - GO': null,
  'Itapaci - GO': null,
  'Itapirapua - GO': null,
  'Itapuranga - GO': null,
  'Itaruma - GO': null,
  'Itaucu - GO': null,
  'Itumbiara - GO': null,
  'Ivolandia - GO': null,
  'Jandaia - GO': null,
  'Jaragua - GO': null,
  'Jatai - GO': null,
  'Jaupaci - GO': null,
  'Jesupolis - GO': null,
  'Joviania - GO': null,
  'Jussara - GO': null,
  'Lagoa Santa - GO': null,
  'Leopoldo de Bulhoes - GO': null,
  'Luziania - GO': null,
  'Mairipotaba - GO': null,
  'Mambai - GO': null,
  'Mara Rosa - GO': null,
  'Marzagao - GO': null,
  'Matrincha - GO': null,
  'Maurilandia - GO': null,
  'Mimoso de Goias - GO': null,
  'Minacu - GO': null,
  'Mineiros - GO': null,
  'Moipora - GO': null,
  'Monte Alegre de Goias - GO': null,
  'Montes Claros de Goias - GO': null,
  'Montividiu - GO': null,
  'Montividiu do Norte - GO': null,
  'Morrinhos - GO': null,
  'Morro Agudo de Goias - GO': null,
  'Mossamedes - GO': null,
  'Mozarlandia - GO': null,
  'Mundo Novo - GO': null,
  'Mutunopolis - GO': null,
  'Nazario - GO': null,
  'Neropolis - GO': null,
  'Niquelandia - GO': null,
  'Nova America - GO': null,
  'Nova Aurora - GO': null,
  'Nova Crixas - GO': null,
  'Nova Gloria - GO': null,
  'Nova Iguacu de Goias - GO': null,
  'Nova Roma - GO': null,
  'Nova Veneza - GO': null,
  'Novo Brasil - GO': null,
  'Novo Gama - GO': null,
  'Novo Planalto - GO': null,
  'Orizona - GO': null,
  'Ouro Verde de Goias - GO': null,
  'Ouvidor - GO': null,
  'Padre Bernardo - GO': null,
  'Palestina de Goias - GO': null,
  'Palmeiras de Goias - GO': null,
  'Palmelo - GO': null,
  'Palminopolis - GO': null,
  'Panama - GO': null,
  'Paranaiguara - GO': null,
  'Parauna - GO': null,
  'Perolandia - GO': null,
  'Petrolina de Goias - GO': null,
  'Pilar de Goias - GO': null,
  'Piracanjuba - GO': null,
  'Piranhas - GO': null,
  'Pirenopolis - GO': null,
  'Pires do Rio - GO': null,
  'Planaltina - GO': null,
  'Pontalina - GO': null,
  'Porangatu - GO': null,
  'Porteirao - GO': null,
  'Portelandia - GO': null,
  'Posse - GO': null,
  'Professor Jamil - GO': null,
  'Quirinopolis - GO': null,
  'Rialma - GO': null,
  'Rianapolis - GO': null,
  'Rio Quente - GO': null,
  'Rio Verde - GO': null,
  'Rubiataba - GO': null,
  'Sanclerlandia - GO': null,
  'Santa Barbara de Goias - GO': null,
  'Santa Cruz de Goias - GO': null,
  'Santa Fe de Goias - GO': null,
  'Santa Helena de Goias - GO': null,
  'Santa Isabel - GO': null,
  'Santa Rita do Araguaia - GO': null,
  'Santa Rita do Novo Destino - GO': null,
  'Santa Rosa de Goias - GO': null,
  'Santa Tereza de Goias - GO': null,
  'Santa Terezinha de Goias - GO': null,
  'Santo Antonio da Barra - GO': null,
  'Santo Antonio de Goias - GO': null,
  'Santo Antonio do Descoberto - GO': null,
  'Sao Domingos - GO': null,
  'Sao Francisco de Goias - GO': null,
  "Sao Joao D'Alianca - GO": null,
  'Sao Joao da Parauna - GO': null,
  'Sao Luis de Montes Belos - GO': null,
  'Sao Luiz do Norte - GO': null,
  'Sao Miguel do Araguaia - GO': null,
  'Sao Miguel do Passa Quatro - GO': null,
  'Sao Patricio - GO': null,
  'Sao Simao - GO': null,
  'Senador Canedo - GO': null,
  'Serranopolis - GO': null,
  'Silvania - GO': null,
  'Simolandia - GO': null,
  "Sitio D'Abadia - GO": null,
  'Taquaral de Goias - GO': null,
  'Teresina de Goias - GO': null,
  'Terezopolis de Goias - GO': null,
  'Tres Ranchos - GO': null,
  'Trindade - GO': null,
  'Trombas - GO': null,
  'Turvania - GO': null,
  'Turvelandia - GO': null,
  'Uirapuru - GO': null,
  'Uruacu - GO': null,
  'Uruana - GO': null,
  'Urutai - GO': null,
  'Valparaiso de Goias - GO': null,
  'Varjao - GO': null,
  'Vianopolis - GO': null,
  'Vicentinopolis - GO': null,
  'Vila Boa - GO': null,
  'Vila Propicio - GO': null,
  'Brasilia - DF': null
}

export const cityOptions = Object.keys(cities).map(city => ({ value: city, label: city }))
