import React from 'react'
import { Controller } from 'react-hook-form'

import InputBase from './InputBase'

const InputFee = ({
  name,
  label,
  inputType,
  defaultValue,
  helperText,
  control,
  icon,
  suffix,
  prefix,
  children,
  mask,
  type,
  register,
  format,
  placeholder,
  disabled,
  inputProps,
  monthly = false,
  ...props
}) => {
  return (
    <Controller
      as={
        <InputBase
          type='number'
          ref={register}
          placeholder={monthly ? 'EX: 1,25' : ''}
          inputProps={monthly ? { step: 0.01, min: 0, max: '10.00', maxLength: '4' } : { step: 0.01 }}
          label={label}
          {...props}
        />
      }
      defaultValue={defaultValue}
      name={name}
      control={control}
      ref={register}
      disabled={disabled}
      fullWidth
    />
  )
}

export default InputFee
