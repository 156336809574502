import styled from 'styled-components'

export const Div = styled.div`
  display: flex;
  justify-content: center;

  div {
    height: 100%;
  }
`
