import styled from 'styled-components'
import { Dialog as MaterialModal } from '@material-ui/core'
import { palette, spacing, typography, borders, flexbox, shadows, sizing } from '@material-ui/system'

import { Row, Column } from 'components'
import { IconClear } from '../icons'

export const Modal = styled(MaterialModal)`
  div {
    &.MuiPaper-elevation24 {
      box-shadow: none;
      border-radius: 8px;
    }

    &.MuiDialog-paperWidthSm {
      max-width: ${({ campaign }) => (campaign === 'true' ? '1100px' : '600px')};
      ${({ invest }) => (invest === 'true' ? 'width:100%; max-width: 800px;' : '')};
    }

    &.MuiDialog-paper {
      margin: ${({ campaign }) => (campaign === 'true' ? '10px' : '32px')};
    }

    &.MuiDialog-paperWidthXl {
      width: 100%;
      max-width: 1000px;
    }
  }
`

export const ModalContainer = styled(Column)`
  ${palette};
  ${spacing};
  ${typography};
  ${borders};
  ${flexbox};
  ${shadows};
  ${sizing};
  padding: 24px;

  @media (max-width: 600px) {
    padding: ${({ campaign }) => (campaign === 'true' ? '16px' : '24px')};
  }
`
export const Close = styled(IconClear)`
  cursor: pointer;
`

export const StyledRow = styled(Row)`
  display: flex;
  justify-content: space-between;
  margin-bottom: 16px;
  flex-direction: ${props => props.flexDirection};
  width: 100%;
`
