import styled from 'styled-components'

const opacity = '15'

export const WrapperPagination = styled.div`
  display: ${({ isLoading }) => (isLoading ? 'none' : 'flex')};
  justify-content: center;
  width: 100%;
  margin: 24px 0px;
`

export const PaginationButton = styled.span`
  outline: none;
  width: 24px;
  height: 24px;
  border: none;
  border-radius: 4px;
  margin: 0 5px;
  display: flex;
  font-size: 14px;
  line-height: 24px;
  align-items: center;
  text-align: center;
  justify-content: center;
  cursor: pointer;
  ${({ isSelected, disabled, isLoading, theme: { palette } }) => `
      background: ${isSelected ? palette.primary.main : 'none'};
      color: ${isSelected ? palette.white : palette.gray['800']};
      opacity: ${disabled && !isSelected ? '0.5' : '1'};
      &:disabled {
        cursor: ${isSelected || isLoading ? 'pointer' : 'not-allowed'};
      }
      &:hover {
        background: ${palette.primary.main + (!isSelected ? opacity : '')};
      }
    `}

  &:first-of-type {
    margin-left: 0;
  }
  &:last-of-type {
    margin-right: 0;
  }
`

export const Control = styled.button`
  border: none;
  outline: none;
  width: 24px;
  height: 24px;
  background-color: transparent;
  display: flex;
  align-items: center;
  justify-content: center;
  &:first-of-type {
    margin-right: 8px;
  }
  &:last-of-type {
    margin-left: 8px;
  }
  ${({ disabled, isLoading }) => `
    cursor: ${disabled || isLoading ? 'not-allowed' : 'pointer'};
`}
`
