import client from '../../providers/fetchClient'

export const getAllInvestorStatuses = () => client.get('/v3/investor-statuses')

export const createInvestor = data => client.post('/v3/investors/signup', data)

export const updateInvestor = (id, data) => client.put(`/v3/investors/${id}`, data)

export const getInvestor = id => client.get(`/v3/investors/${id}`)

export const getIncomesReport = id => client.get(`/v3/incomes-report/${id}`)

export const createInvestorPartner = data =>
  client.post('/v3/investors/create-by-partner', { ...data, investor_status_id: 1 })
