import React from 'react'
import { SvgIcon } from '@material-ui/core'

const Upload = props => {
  return (
    <SvgIcon width='32' height='32' viewBox='0 0 32 32' fill='none' {...props}>
      <path d='M25.3333 17.3333V24C25.3333 24.7333 24.7333 25.3333 24 25.3333H8C7.26667 25.3333 6.66667 24.7333 6.66667 24V17.3333C6.66667 16.6 6.06667 16 5.33333 16C4.6 16 4 16.6 4 17.3333V25.3333C4 26.8 5.2 28 6.66667 28H25.3333C26.8 28 28 26.8 28 25.3333V17.3333C28 16.6 27.4 16 26.6667 16C25.9333 16 25.3333 16.6 25.3333 17.3333ZM14.6533 8.55L12.1467 11.0567C11.6267 11.5767 10.7867 11.5767 10.2667 11.0567C9.74667 10.5367 9.74667 9.69667 10.2667 9.17667L15.0533 4.39C15.5733 3.87 16.4133 3.87 16.9333 4.39L21.72 9.17667C22.24 9.69667 22.24 10.5367 21.72 11.0567C21.2 11.5767 20.36 11.5767 19.84 11.0567L17.32 8.55V20.11C17.32 20.8433 16.72 21.4433 15.9867 21.4433C15.2533 21.4433 14.6533 20.8433 14.6533 20.11V8.55Z' />
    </SvgIcon>
  )
}

export { Upload }
