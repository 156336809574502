import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Controller } from 'react-hook-form'
import { useDropzone } from 'react-dropzone'

import { Row } from 'components'
import {
  DragAndDrop,
  StyledUpload,
  Title,
  Underlined,
  Text,
  AcceptFile,
  AttachFileIcon,
  StyledRow,
  StyledButton,
  ErrorFile,
  ErrorMessage,
  FileWithError
} from './styles'

const Dropzone = ({
  onDrop,
  defaultValue,
  error,
  name,
  control,
  onDelete,
  multiple = false,
  admin = false,
  leadDocuments = false,
  ...props
}) => {
  const [archive, setArchive] = useState()

  const { getRootProps, getInputProps, acceptedFiles, fileRejections } = useDropzone({
    maxFiles: multiple ? 20 : 1,
    maxSize: 5242880,
    accept:
      'image/jpeg, image/png, application/pdf, application/pdf,text/csv,text/html,text/plain,text/tab-separated-values,application/xls,application/excel,application/vnd.ms-excel,application/vnd.ms-excel; charset=binary,application/msexcel,application/x-excel,application/x-msexcel,application/x-ms-excel,application/x-dos_ms_excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    onDrop: files => {
      setArchive(leadDocuments ? true : multiple ? false : true)
      onDrop(files)
    },
    multiple: multiple
  })

  const acceptedFileItems = acceptedFiles.map((file, index) => {
    return (
      <StyledRow key={file.path}>
        <Row>
          <AttachFileIcon fontSize='small' />
          <AcceptFile key={file.path}> {file.path}</AcceptFile>
        </Row>
        <div>
          <StyledButton onClick={() => window.open(URL.createObjectURL(file))} variant='text'>
            Visualizar
          </StyledButton>
          <StyledButton onClick={() => handleButtonClick(index)} variant='text'>
            Excluir
          </StyledButton>
        </div>
      </StyledRow>
    )
  })

  const fileRejectionItems = fileRejections.map(({ file, index, errors }) => (
    <ErrorFile key={file.path}>
      <div>
        <Row>
          <AttachFileIcon fontSize='small' />
          <FileWithError>{file.path}</FileWithError>
        </Row>
        <div>
          {errors.map(e => {
            if (e.code === 'file-too-large') {
              return <ErrorMessage key={e.code}>O tamanho máximo do arquivo é de 5MB.</ErrorMessage>
            } else {
              return <ErrorMessage key={e.code}>Erro na tentativa de upload. Tente novamente</ErrorMessage>
            }
          })}
        </div>
      </div>
      <StyledButton onClick={() => handleButtonClick(index)} variant='text' buttoncolor='#D93025'>
        Excluir
      </StyledButton>
    </ErrorFile>
  ))

  const handleButtonClick = file => {
    acceptedFiles.splice(file, 1)
    /*  setArchive() */
    onDelete(acceptedFiles)
  }

  return (
    <Controller
      as={
        <section>
          <div {...getRootProps()}>
            <input {...getInputProps()} />
            <DragAndDrop>
              <StyledUpload />
              <Title>{admin ? 'Anexar imagens' : 'Anexar arquivos'} </Title>
              <div>
                <Underlined>Selecione </Underlined>
                <Text> {admin ? 'ou arraste as imagens até aqui' : 'ou arraste os arquivos até aqui'}</Text>
              </div>
            </DragAndDrop>
          </div>
          {archive && acceptedFileItems}
          {archive && fileRejectionItems}
        </section>
      }
      defaultValue={defaultValue}
      error={error}
      name={name}
      control={control}
      {...props}
    />
  )
}

Dropzone.defaultProps = {
  defaultValue: ''
}

Dropzone.propTypes = {
  error: PropTypes.object,
  name: PropTypes.string,
  defaultValue: PropTypes.string,
  onDrop: PropTypes.func
}

export default Dropzone
