import { TramRounded } from '@material-ui/icons'
import * as yup from 'yup'

const regexUrl1 = new RegExp('kavodlending.com/campanhas/com-aval/*')
const regexUrl2 = new RegExp('kavodlending.com/campanhas/com-aval-e-garantia/*')

import { CEP_LENGTH, CNPJ_LENGTH } from '../constants'

yup.addMethod(yup.string, 'cpf', function () {
  return yup.mixed().test('validation cpf', 'Insira um CPF valido', cpf => {
    cpf = cpf.replace(/[\s.-]*/gim, '')
    if (
      cpf.length != 11 ||
      cpf == '00000000000' ||
      cpf == '11111111111' ||
      cpf == '22222222222' ||
      cpf == '33333333333' ||
      cpf == '44444444444' ||
      cpf == '55555555555' ||
      cpf == '66666666666' ||
      cpf == '77777777777' ||
      cpf == '88888888888' ||
      cpf == '99999999999'
    ) {
      return false
    }
    var soma = 0
    var resto
    for (var i = 1; i <= 9; i++) soma = soma + parseInt(cpf.substring(i - 1, i)) * (11 - i)
    resto = (soma * 10) % 11
    if (resto == 10 || resto == 11) resto = 0
    if (resto != parseInt(cpf.substring(9, 10))) return false
    soma = 0
    for (var i = 1; i <= 10; i++) soma = soma + parseInt(cpf.substring(i - 1, i)) * (12 - i)
    resto = (soma * 10) % 11
    if (resto == 10 || resto == 11) resto = 0
    if (resto != parseInt(cpf.substring(10, 11))) return false
    return true
  })
})

export const LoginResolver = yup.object().shape({
  document_number: yup.string().required('Campo obrigatório.'),
  password: yup.string().required('Campo obrigatório.')
})

export const RecoveryPassword = yup.object().shape({
  document_number: yup
    .string()
    .required('Campo obrigatório.')
    .test('validation cpf', 'Insira um CPF valido', cpf => {
      if (typeof cpf !== 'string') return false
      cpf = cpf.replace(/[\s.-]*/gim, '')
      if (
        !cpf ||
        cpf.length != 11 ||
        cpf == '00000000000' ||
        cpf == '11111111111' ||
        cpf == '22222222222' ||
        cpf == '33333333333' ||
        cpf == '44444444444' ||
        cpf == '55555555555' ||
        cpf == '66666666666' ||
        cpf == '77777777777' ||
        cpf == '88888888888' ||
        cpf == '99999999999'
      ) {
        return false
      }
      var soma = 0
      var resto
      for (var i = 1; i <= 9; i++) soma = soma + parseInt(cpf.substring(i - 1, i)) * (11 - i)
      resto = (soma * 10) % 11
      if (resto == 10 || resto == 11) resto = 0
      if (resto != parseInt(cpf.substring(9, 10))) return false
      soma = 0
      for (var i = 1; i <= 10; i++) soma = soma + parseInt(cpf.substring(i - 1, i)) * (12 - i)
      resto = (soma * 10) % 11
      if (resto == 10 || resto == 11) resto = 0
      if (resto != parseInt(cpf.substring(10, 11))) return false
      return true
    })
})
export const SignupResolver = yup.object().shape({
  document_number: yup.string().cpf().required('Campo obrigatório'),
  password: yup
    .string()
    .required('Campo obrigatório')
    .min(8, 'A senha deve ter no mínimo oito caracteres')
    .matches(/[a-z]/, 'A senha deve ter pelo menos uma letra minúscula')
    .matches(/[A-Z]/, 'A senha deve ter pelo menos uma letra maiúscula')
    .matches(/[0-9]/, 'A senha deve ter pelo menos um número'),
  first_name: yup.string().required('Campo obrigatório'),
  last_name: yup.string().required('Campo obrigatório'),
  email: yup.string().required('Campo obrigatório').email('Insira um email válido')
})

export const RecoveryPasswordToken = yup.object().shape({
  password: yup
    .string()
    .required('Campo obrigatório')
    .min(8, 'A senha deve ter no mínimo oito caracteres')
    .matches(/[a-z]/, 'A senha deve ter pelo menos uma letra minúscula')
    .matches(/[A-Z]/, 'A senha deve ter pelo menos uma letra maiúscula')
    .matches(/[0-9]/, 'A senha deve ter pelo menos um número'),
  passwordConfirmation: yup.string().oneOf([yup.ref('password'), null], 'A senha deve ser igual.')
})

export const PartnerInvestorRegister = yup
  .object()
  .shape({ document_number: yup.string().cpf().required('Campo obrigatório') })

export const PartnerCompanyDetailsResolver = yup.object().shape({
  cnpj: yup
    .string()
    .required('Campo obrigatório')
    .test({
      name: 'cnpj',
      message: 'CNPJ incompleto',
      test: value => !value || value.replace(/\D/g, '').length === 14
    }),
  company_name: yup.string().required('Campo obrigatório'),
  telephone: yup.string().required('Campo obrigatório'),
  zip_code: yup
    .string()
    .required('Campo obrigatório')
    .test('cep', 'CEP incompleto', value => !value || value.replace(/\D/g, '').length === 8),
  company_street: yup.string().required('Campo obrigatório'),
  company_number: yup.string().required('Campo obrigatório'),
  company_complement: yup.string(),
  company_city: yup.string().required('Campo obrigatório'),
  company_district: yup.string().required('Campo obrigatório'),
  check_term: yup
    .boolean()
    .required('Os termos e condições devem ser aceitos.')
    .oneOf([true], 'Os termos e condições devem ser aceitos.'),
  check_term2: yup
    .boolean()
    .required('Os termos e condições devem ser aceitos.')
    .oneOf([true], 'Os termos e condições devem ser aceitos.')
})

export const PartnerContactDetailsResolver = yup.object().shape({
  document_number: yup.string().cpf().required('Campo obrigatório'),
  password: yup
    .string()
    .required('Campo obrigatório')
    .min(8, 'A senha deve ter no mínimo oito caracteres')
    .matches(/[a-z]/, 'A senha deve ter pelo menos uma letra minúscula')
    .matches(/[A-Z]/, 'A senha deve ter pelo menos uma letra maiúscula')
    .matches(/[0-9]/, 'A senha deve ter pelo menos um número'),
  first_name: yup.string().required('Campo obrigatório'),
  last_name: yup.string().required('Campo obrigatório'),
  cell_number: yup.string().required('Campo obrigatório'),
  email: yup.string().required('Campo obrigatório').email('Insira um email válido')
})

export const PartnerContactDetailsResolverCPF = yup.object().shape({
  document_number: yup.string().cpf().required('Campo obrigatório')
})

export const ContactResolver = yup.object().shape({
  name: yup.string().required('Campo obrigatório.'),
  email: yup.string().required('Campo obrigatório.').email('Insira um email válido'),
  type: yup.string().required('Campo obrigatório.'),
  subject: yup.string().required('Campo obrigatório.'),
  message: yup.string().required('Campo obrigatório.')
})

export const WorkWithUsResolver = yup.object().shape({
  name: yup.string().required('Campo obrigatório.'),
  email: yup.string().required('Campo obrigatório.').email('Insira um email válido'),
  subject: yup.string().required('Campo obrigatório.'),
  body: yup.string().required('Campo obrigatório.'),
  area_type_ids: yup
    .object()
    .test('area_test', 'Campo obrigatório.', areas => Object.values(areas).some(areaSelected => areaSelected)),
  file: yup.array().required('Campo obrigatório').nullable()
})

export const modalRefuseProposalResolver = yup.object().shape({
  observation: yup.string().required('Campo obrigatório.')
})

export const modalAcceptProposalResolver = yup.object().shape({
  observation: yup.string().max(255, 'Deve ter no máximo 255 caracteres').nullable()
})

export const formCompanyNomination = yup.object().shape({
  first_name: yup.string().required('Campo obrigatório.'),
  last_name: yup.string().required('Campo obrigatório.'),
  celphone: yup
    .string()
    .test('celphone', 'Número invalido', value => {
      const celphone = value.replace(/\D/g, '')
      if (celphone.slice(2).length >= 8 && celphone.slice(0, 2).length === 2) {
        return true
      }
      return false
    })
    .required('Campo obrigatório.'),
  email: yup.string().email('Email inválido').required('Campo obrigatório.'),
  company_type: yup.string().required('Campo obrigatório.'),
  franchise_type: yup.string().when('company_type', {
    is: 'Franquia',
    then: yup.string().required('Campo obrigatório.')
  }),
  franchisor_name: yup.string().when(['franchisor', 'company_type'], {
    is: (franchisor, company_type) => {
      if (!franchisor && company_type === 'Franquia') {
        return true
      }
      return false
    },
    then: yup.string().required('Campo obrigatório.')
  }),
  franchise_description: yup.string().when(['franchise_type'], {
    is: franchise_type => {
      if (franchise_type === 'Nova' || franchise_type === 'Existente') {
        return true
      }
      return false
    },
    then: yup.string().required('Campo obrigatório.')
  }),
  company_history: yup.string().when(['company_type'], {
    is: company_type => {
      if (company_type === 'Geral') {
        return true
      }
      return false
    },
    then: yup.string().required('Campo obrigatório.')
  }),
  average_monthly_revenue: yup.string().required('Campo obrigatório.'),
  desired_amount: yup.string().required('Campo obrigatório.'),
  lending_reason: yup.string().required('Campo obrigatório.'),
  have_cnpj: yup.boolean(),
  cnpj: yup.string().when(['have_cnpj', 'company_type', 'franchise_type'], {
    is: (have_cnpj, company_type, franchise_type) => {
      if (have_cnpj || company_type === 'Geral' || franchise_type === 'Existente') {
        return true
      }
      return false
    },
    then: yup
      .string()
      .required('Campo obrigatório.')
      .test({
        name: 'cnpj',
        message: 'CNPJ incompleto',
        test: value => !value || value.replace(/\D/g, '').length === CNPJ_LENGTH
      })
  }),
  company_name: yup.string().when(['have_cnpj', 'company_type', 'franchise_type'], {
    is: (have_cnpj, company_type, franchise_type) => {
      if (have_cnpj || company_type === 'Geral' || franchise_type === 'Existente' || franchise_type === 'Nova') {
        return true
      }
      return false
    },
    then: yup.string().required('Campo obrigatório.')
  }),
  street: yup.string().when(['have_cnpj', 'company_type', 'franchise_type'], {
    is: (have_cnpj, company_type, franchise_type) => {
      if (have_cnpj || company_type === 'Geral' || franchise_type === 'Existente') {
        return true
      }
      return false
    },
    then: yup.string().required('Campo obrigatório.')
  }),
  zip_code: yup.string().when(['have_cnpj', 'company_type', 'franchise_type'], {
    is: (have_cnpj, company_type, franchise_type) => {
      if (have_cnpj || company_type === 'Geral' || franchise_type === 'Existente') {
        return true
      }
      return false
    },
    then: yup
      .string()
      .required('Campo obrigatório.')
      .test('cep', 'CEP incompleto', value => !value || value.replace(/\D/g, '').length === CEP_LENGTH)
  }),
  number: yup.string().when(['have_cnpj', 'company_type', 'franchise_type'], {
    is: (have_cnpj, company_type, franchise_type) => {
      if (have_cnpj || company_type === 'Geral' || franchise_type === 'Existente') {
        return true
      }
      return false
    },
    then: yup.string().required('Campo obrigatório.')
  }),
  city: yup.string().when(['have_cnpj', 'company_type', 'franchise_type'], {
    is: (have_cnpj, company_type, franchise_type) => {
      if (have_cnpj || company_type === 'Geral' || franchise_type === 'Existente') {
        return true
      }
      return false
    },
    then: yup.string().required('Campo obrigatório.')
  }),
  uf: yup.string().when(['have_cnpj', 'company_type', 'franchise_type'], {
    is: (have_cnpj, company_type, franchise_type) => {
      if (have_cnpj || company_type === 'Geral' || franchise_type === 'Existente') {
        return true
      }
      return false
    },
    then: yup.string().required()
  }),
  company_site: yup.string().when(['have_cnpj', 'company_type', 'franchise_type'], {
    is: (have_cnpj, company_type, franchise_type) => {
      if (have_cnpj || company_type === 'Geral' || franchise_type === 'Existente') {
        return true
      }
      return false
    },
    then: yup
      .string()
      .test(
        'site',
        'Digite uma URL válida',
        value =>
          !value ||
          /(https?:\/\/)?(www\.)?[-a-zA-Z0-9@:%._+~#=]{2,256}\.[a-z]{2,4}\b([-a-zA-Z0-9@:%_+.~#?&//=]*)/g.test(value)
      )
      .nullable()
  })
})

export const userPersonalDataSchema = yup.object().shape({
  first_name: yup.string().required('Campo obrigatório.'),
  last_name: yup.string().required('Campo obrigatório.'),
  celphone: yup.string().required('Campo obrigatório.'),
  nationality: yup.string().required('Campo obrigatório.'),
  gener: yup.string().required('Campo obrigatório.'),
  marital_status: yup.string().required('Campo obrigatório.'),
  rg: yup.string().required('Campo obrigatório.'),
  rg_agent: yup.string().required('Campo obrigatório.'),
  rg_uf: yup.string().required('Campo obrigatório.'),
  mother_name: yup.string().required('Campo obrigatório.'),
  father_name: yup.string().required('Campo obrigatório.'),
  email: yup.string().email('Email inválido').required(),
  naturality: yup.object().when(['nationality'], {
    is: nationality => {
      if (nationality === 'Estrangeiro') {
        return false
      }
      return true
    },
    then: yup.object().required('Campo obrigatório.')
  })
})

export const userPersonalPartnerDataSchema = yup.object().shape({
  first_name: yup.string().required('Campo obrigatório.'),
  last_name: yup.string().required('Campo obrigatório.'),
  celphone: yup.string().required('Campo obrigatório.'),
  document_number: yup.string().cpf().required('Campo obrigatório'),
  email: yup.string().email('Email inválido').required()
})

export const userAddressSchema = yup.object().shape({
  zip_code: yup
    .string()
    .required('Campo Obrigatório')
    .test('is_valid', 'CEP incompleto', value => value && value.replace(/\D/g, '').length === CEP_LENGTH),
  address_type: yup.string().required('Campo Obrigatório'),
  street: yup.string().required('Campo Obrigatório'),
  number: yup.string().required('Campo Obrigatório'),
  district: yup.string().required('Campo Obrigatório'),
  city: yup
    .object()
    .typeError('Campo Obrigatório')
    .test('required', 'Campo Obrigatório', ({ value }) => Boolean(value))
})

export const userFinancialInfoSchema = yup.object().shape({
  type: yup.string().required(),
  code: yup
    .object()
    .typeError('Campo Obrigatório')
    .test('required', 'Campo Obrigatório', ({ value }) => Boolean(value)),
  account: yup.string().required('Campo Obrigatório'),
  account_digit: yup.string().required('Campo Obrigatório'),
  agency: yup.string().required('Campo Obrigatório'),
  monthly_earn: yup.string().required('Campo Obrigatório'),
  property_value: yup.string().required('Campo Obrigatório')
})

export const userDocumentSchema = yup.object().shape({
  document_type: yup.string().required('Campo obrigatório.'),
  rg_type: yup.string().when(['document_type'], {
    is: document_type => {
      if (document_type === 'RG') {
        return true
      }
      return false
    },
    then: yup.string().required('Campo obrigatório.')
  }),
  file_document: yup.mixed().required('Campo obrigatório'),
  file_document2: yup.mixed().when(['rg_type'], {
    is: rg_type => {
      if (rg_type === 'RG_V') {
        return true
      }
      return false
    },
    then: yup.mixed().required('Campo obrigatório.')
  }),
  file_residence: yup.mixed().required('Campo obrigatório'),
  check_term: yup
    .boolean()
    .required('Os termos e condições devem ser aceitos.')
    .oneOf([true], 'Os termos e condições devem ser aceitos.'),
  check_term2: yup
    .boolean()
    .required('Os termos e condições devem ser aceitos.')
    .oneOf([true], 'Os termos e condições devem ser aceitos.')
})

export const userDocumentSchemaDoc = yup.object().shape({
  document_type: yup.string().required('Campo obrigatório.'),
  rg_type: yup.string().when(['document_type'], {
    is: document_type => {
      if (document_type === 'RG') {
        return true
      }
      return false
    },
    then: yup.string().required('Campo obrigatório.')
  }),
  check_term: yup
    .boolean()
    .required('Os termos e condições devem ser aceitos.')
    .oneOf([true], 'Os termos e condições devem ser aceitos.'),
  check_term2: yup
    .boolean()
    .required('Os termos e condições devem ser aceitos.')
    .oneOf([true], 'Os termos e condições devem ser aceitos.')
})

export const userCompanySchema = yup.object().shape({
  cnpj: yup
    .string()
    .required('Campo obrigatório')
    .test({
      name: 'cnpj',
      message: 'CNPJ incompleto',
      test: value => !value || value.replace(/\D/g, '').length === 14
    }),
  phone: yup
    .string()
    .test('celphone', 'Número invalido', value => {
      const celphone = value.replace(/\D/g, '')
      if (celphone.slice(2).length >= 8 && celphone.slice(0, 2).length === 2) {
        return true
      }
      return false
    })
    .required('Campo obrigatório.'),
  company_name: yup.string().required('Campo Obrigatório'),
  zip_code: yup
    .string()
    .required('Campo Obrigatório')
    .test('is_valid', 'CEP incompleto', value => value && value.replace(/\D/g, '').length === CEP_LENGTH),
  street: yup.string().required('Campo Obrigatório'),
  number: yup.string().required('Campo Obrigatório'),
  district: yup.string().required('Campo Obrigatório'),
  city: yup.string().required('Campo Obrigatório'),
  uf: yup.string().required('Campo Obrigatório'),
  complement: yup.string().nullable(true)
})

export const formCampaingSchema = yup.object().shape({
  amount: yup.string().required('Campo Obrigatório')
})

export const adminCampaingCreateSchema = yup.object().shape({
  document_number: yup
    .string()
    .required('Campo obrigatório')
    .test({
      name: 'cnpj',
      message: 'CNPJ incompleto',
      test: value => !value || value.replace(/\D/g, '').length === 14
    }),
  company_name: yup.string().required('Campo Obrigatório'),
  title: yup.string().required('Campo Obrigatório'),
  url: yup
    .string()
    .required('Campo Obrigatório')
    .test('celphone', 'URL invalida', value => {
      if (regexUrl1.test(value) && value.length > 36) {
        return true
      }
      if (regexUrl2.test(value) && value.length > 47) {
        return true
      }
      return false
    }),
  goal: yup.string().required('Campo Obrigatório'),
  min_value: yup.string().required('Campo Obrigatório'),
  max_value: yup.string().required('Campo Obrigatório'),
  fee_monthly: yup
    .string()
    .required('Campo Obrigatório')
    .min(0, 'Valor Negativo.')
    .max(10, 'Valor máximo acima de 10.')
    .typeError('Campo Obrigatório'),
  parcel_number: yup.string().required('Campo Obrigatório'),
  grace_period: yup.string().required('Campo Obrigatório'),
  tac: yup.string().required('Campo Obrigatório'),
  type: yup.string().required('Campo Obrigatório'),
  collateral: yup.string().when(['type'], {
    is: type => {
      if (type === '2') {
        return true
      }
      return false
    },
    then: yup.string().required('Campo obrigatório.')
  }),
  warranty: yup.string().when(['type'], {
    is: type => {
      if (type === '2') {
        return true
      }
      return false
    },
    then: yup.string().required('Campo obrigatório.')
  })
})

export const adminCampaingUpdateCreateSchema = yup.object().shape({
  title: yup.string().required('Campo Obrigatório'),
  desc: yup.string().required('Campo Obrigatório')
})

export const createAdministratorSchema = yup.object().shape({
  name: yup.string().required('Campo Obrigatório'),
  description: yup.string().required('Campo Obrigatório')
})

export const createFinancialSchema = yup.object().shape({
  year: yup.string().required('Campo Obrigatório'),
  month_start: yup.string(),
  month_end: yup.string(),
  billing: yup.string().required('Campo Obrigatório'),
  income: yup.string().required('Campo Obrigatório')
})

export const editOrderGalleryCampaingSchema = yup.object().shape({
  order: yup.string().required('Campo Obrigatório')
})

export const debtsCampaingSchema = yup.object().shape({
  short_term: yup.string().when(['haveDebt'], {
    is: haveDebt => {
      if (haveDebt === true) {
        return true
      }
      return false
    },
    then: yup.string().required('Campo obrigatório.')
  }),
  long_term: yup.string().when(['haveDebt'], {
    is: haveDebt => {
      if (haveDebt === true) {
        return true
      }
      return false
    },
    then: yup.string().required('Campo obrigatório.')
  }),
  note: yup.string().when(['haveDebt'], {
    is: haveDebt => {
      if (haveDebt === true) {
        return true
      }
      return false
    },
    then: yup.string()
  })
})

export const createShareholdersSchema = yup.object().shape({
  first_name: yup.string().required('Campo Obrigatório'),
  last_name: yup.string().when(['type'], {
    is: type => {
      if (type === '1') {
        return true
      }
      return false
    },
    then: yup.string().required('Campo obrigatório.')
  }),
  is_admin: yup.boolean().required('Campo Obrigatório'),
  identifier: yup.string().required('Campo Obrigatório')
})

export const createGuarantorsSchema = yup.object().shape({
  first_name: yup.string().required('Campo Obrigatório'),
  last_name: yup.string().when(['type'], {
    is: type => {
      if (type === 'PF') {
        return true
      }
      return false
    },
    then: yup.string()
  }),
  marital_status: yup.string().when(['type'], {
    is: type => {
      if (type === 'PF') {
        return true
      }
      return false
    },
    then: yup.string()
  }),
  union_type: yup.string().when(['marital_status'], {
    is: marital_status => {
      if (marital_status === '1') {
        return true
      }
      return false
    },
    then: yup.string()
  }),
  type: yup.string().required('Campo Obrigatório')
  /*   identifier: yup.string(),
  zip_code: yup
    .string()
    .required('Campo Obrigatório')
    .test('is_valid', 'CEP incompleto', value => value && value.replace(/\D/g, '').length === CEP_LENGTH),
  street: yup.string().required('Campo Obrigatório'),
  number: yup.string().required('Campo Obrigatório'),
  district: yup.string().required('Campo Obrigatório'),
  city: yup
    .object()
    .typeError('Campo Obrigatório')
    .test('required', 'Campo Obrigatório', ({ value }) => Boolean(value)) */
})

export const modalSendDocumentsResolver = yup.object().shape({
  observation: yup.string()
})

export const modalDeleteCampaingResolver = yup.object().shape({
  reason: yup.string().max(255, 'Deve ter no máximo 255 caracteres').nullable()
})
