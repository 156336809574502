import React, { useState, useEffect, useCallback } from 'react'
import { useMediaQuery } from '@material-ui/core'
import { pdf } from '@react-pdf/renderer'
import { useRouter } from 'next/router'

import { Button, IconButton, Row, Text, Link } from 'components'
import {
  Container,
  Menus,
  TotalHeader,
  ReportIncomeMobile,
  IconDownload,
  Image,
  ContainerIncome,
  LinkHeader,
  RowMenu
} from './styles'
import {
  IconClear,
  IconMenu,
  IconVisibility,
  IconVisibilityOff,
  IconExpandLess,
  IconExpandMore
} from 'components/icons'
import DesktopHeader from './DesktopHeader'
import TabletHeader from './TabletHeader'

import {
  publicMenus,
  defaultPrivateMenus,
  privateMenuPerRole,
  userOptionsPerRole,
  termsOfUseOptions,
  contactOptions,
  registrationOptions,
  defaultUserOptions
} from './constants'
import { formatCurrency, PDFIncomeReport } from 'helpers'
import { useAuth } from 'hooks'
import { useUser } from 'context/user-context'
import { getIncomesReport } from 'services/v3'

const Header = () => {
  const { user } = useUser()

  const [menuVisibility, setMenuVisibility] = useState(false)
  const [privateMenus, setPrivateMenus] = useState([])
  const [userOptions, setUserOptions] = useState([])
  const [values, setValues] = useState({
    values: '',
    totalInvested: formatCurrency(0),
    totalReceived: formatCurrency(0),
    totalReceivable: formatCurrency(0),
    showValues: true
  })
  const [reportIncomeActive, setReportIncomeActive] = useState(false)
  const [income, setIncome] = useState({})
  const [mobileShow, setMobileShow] = useState(false)

  const roles = user?.roles
  const { logout } = useAuth()
  const isDesktopMedium = useMediaQuery('(max-width:1269px)')

  useEffect(() => {
    if (roles) {
      const privateMenus = defaultPrivateMenus
        .concat(...roles.map(role => privateMenuPerRole[role.name]))
        .filter(item => item)
      setPrivateMenus(privateMenus)
    }
  }, [roles])

  useEffect(() => {
    if (roles) {
      const userOptions = defaultUserOptions
        .concat(...roles.map(role => userOptionsPerRole[role.name]))
        .filter(item => item)
      setUserOptions(userOptions)
    }
  }, [roles])

  useEffect(() => {
    if (user) {
      const featch = async () => {
        if (user.investor) {
          const responseIncome = await getIncomesReport(user.investor.id)
          if (!responseIncome || responseIncome.campaignsInfos.length === 0) {
            setReportIncomeActive(false)
          } else {
            setIncome({
              userInfos: {
                ...user.investor,
                cpf: user.document_number
              },
              year: String(new Date().getFullYear()),
              campaignsInfos: responseIncome.campaignsInfos,
              campaignsSum: responseIncome.campaignsSum
            })
            /*   change to false */
            setReportIncomeActive(true)
          }
        }
      }
      setValues({
        values: '',
        totalInvested: formatCurrency(!!user.total_invested ? user.total_invested : 0),
        totalReceived: formatCurrency(!!user.total_received ? user.total_received : 0),
        totalReceivable: formatCurrency(!!user.total_to_be_received ? user.total_to_be_received : 0),
        showValues: true
      })
      featch()
    }
  }, [user])

  const handleClickShowMobile = () => {
    setMobileShow(!mobileShow)
  }

  const handleClickShowValues = () => {
    setValues({ ...values, showValues: !values.showValues })
  }

  const handleMouseDownValues = event => {
    event.preventDefault()
  }

  const handleClickReportIncomeDownload = useCallback(async () => {
    try {
      const blob = await pdf(<PDFIncomeReport data={income} />).toBlob()
      window.open(URL.createObjectURL(blob), '_blank')
    } catch (error) {
      console.error(error.message)
      throw new Error('Erro ao realizar o download do informe.')
    }
  }, [income])

  const handleClicklogout = () => {
    setMenuVisibility(false)
    logout()
  }

  const handleLogoClick = () => {
    setMenuVisibility(false)
    router.push('/')
  }

  const router = useRouter()

  let nameUser = ''
  if (!!user) {
    if (!!user.investor) {
      nameUser = user.investor.first_name
    } else if (!!user.partner) {
      nameUser = user.partner.first_name
    }
  }

  return (
    <Container>
      <RowMenu>
        <Row justifyContent='space-between'>
          <Image src='/img/logo-kavod-header.svg' alt='Logo Kavod Lending' onClick={handleLogoClick} />
          <IconButton
            className='icon-mobile'
            variant='text'
            aria-label='Toggle menu visibility'
            onClick={() => setMenuVisibility(!menuVisibility)}
            marginright='0 !important'
            style={{ color: 'var(--gray)' }}
            icon={!menuVisibility ? <IconMenu /> : <IconClear />}
          />
        </Row>
        <Menus>
          {isDesktopMedium ? (
            <>
              {!!user && reportIncomeActive && !menuVisibility && (
                <ContainerIncome>
                  <Button
                    variant='outlined'
                    fontSize={14}
                    height={32}
                    width={233}
                    mr='32px'
                    border='2px solid !important'
                    onClick={handleClickReportIncomeDownload}
                  >
                    <IconDownload />
                    <Text style={{ fontWeight: 600, fontSize: '14px', lineHeight: '24px' }}>
                      Informe de Rendimentos
                    </Text>
                  </Button>
                </ContainerIncome>
              )}

              <IconButton
                className='button-tablet'
                variant='text'
                aria-label='Toggle menu visibility'
                onClick={() => setMenuVisibility(!menuVisibility)}
                marginright='0 !important'
                style={{ color: 'var(--gray)' }}
                icon={!menuVisibility ? <IconMenu /> : <IconClear />}
              />
            </>
          ) : (
            <DesktopHeader
              isLogged={!!user}
              userName={nameUser}
              publicMenus={publicMenus}
              privateMenus={privateMenus}
              termsOfUseOptions={termsOfUseOptions}
              contactOptions={contactOptions}
              registrationOptions={registrationOptions}
              userOptions={userOptions}
              logout={handleClicklogout}
              reportIncomeActivated={reportIncomeActive}
              ReportIncomeDownload={handleClickReportIncomeDownload}
            />
          )}
        </Menus>
      </RowMenu>
      {isDesktopMedium && menuVisibility && (
        <TabletHeader
          isLogged={!!user}
          userName={nameUser}
          publicMenus={publicMenus}
          privateMenus={privateMenus}
          termsOfUseOptions={termsOfUseOptions}
          contactOptions={contactOptions}
          registrationOptions={registrationOptions}
          userOptions={userOptions}
          logout={handleClicklogout}
          onClick={() => setMenuVisibility(false)}
        />
      )}

      {!!user && (
        <>
          <ReportIncomeMobile alignItems='center' paddingLeft={19} width='100vw' mobile={mobileShow}>
            <Row className='row-menu'>
              <Link
                textAlign='center'
                activeLinkColor='white'
                color='white'
                textDecoration='none'
                href={'/meus-investimentos'}
              >
                <Text style={{ fontWeight: 600, fontSize: '14px', lineHeight: '24px' }}>Meus Investimentos</Text>
              </Link>

              <div>
                <IconButton
                  variant='text'
                  aria-label='Toggle values visibility'
                  onClick={handleClickShowMobile}
                  onMouseDown={handleMouseDownValues}
                  marginright={0}
                  icon={
                    mobileShow ? (
                      <IconVisibility style={{ color: 'white' }} />
                    ) : (
                      <IconVisibilityOff style={{ color: 'white' }} />
                    )
                  }
                />
                <IconButton
                  variant='text'
                  aria-label='Toggle values visibility'
                  onClick={handleClickShowMobile}
                  onMouseDown={handleMouseDownValues}
                  marginright={20}
                  icon={
                    mobileShow ? (
                      <IconExpandLess style={{ color: 'white' }} />
                    ) : (
                      <IconExpandMore style={{ color: 'white' }} />
                    )
                  }
                />
              </div>
            </Row>

            {mobileShow && (
              <>
                <Text variant='body2'>Total investido: {values.showValues ? values.totalInvested : '-'}</Text>
                <Text variant='body2'>Total recebido: {values.showValues ? values.totalReceived : '-'}</Text>
                <Text variant='body2'>Total à receber: {values.showValues ? values.totalReceivable : '-'}</Text>
              </>
            )}
          </ReportIncomeMobile>

          <TotalHeader justifyContent='center' alignItems='center' width='100vw'>
            <LinkHeader>
              <Link
                textAlign='center'
                color='white'
                textDecoration='none'
                activeLinkColor='#076F9F'
                href='/meus-investimentos'
              >
                Meus investimentos
              </Link>
            </LinkHeader>
            <Text variant='body2'>Total investido: {values.showValues ? values.totalInvested : '-'}</Text>
            <Text variant='body2'>Total recebido: {values.showValues ? values.totalReceived : '-'}</Text>
            <Text variant='body2'>Total à receber: {values.showValues ? values.totalReceivable : '-'}</Text>
            <IconButton
              variant='text'
              aria-label='Toggle values visibility'
              onClick={handleClickShowValues}
              onMouseDown={handleMouseDownValues}
              marginright={20}
              icon={
                values.showValues ? (
                  <IconVisibility style={{ color: 'white' }} />
                ) : (
                  <IconVisibilityOff style={{ color: 'white' }} />
                )
              }
            />
          </TotalHeader>
        </>
      )}
    </Container>
  )
}

export default Header
