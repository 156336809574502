import styled from 'styled-components'
import TableHead from '@material-ui/core/TableHead'
import TableCell from '@material-ui/core/TableCell'
import MaUTable from '@material-ui/core/Table'
import TableRow from '@material-ui/core/TableRow'

export const Container = styled.div`
  display: block;

  @media (max-width: ${({ hidden }) => hidden && hidden}px) {
    display: none;
  }
`

export const ReactTable = styled(MaUTable)`
  box-shadow: 0px 2px 8px rgba(117, 117, 117, 0.2);
  border-radius: 4px;
`

export const Head = styled(TableHead)`
  background-color: ${({ theme }) => theme.palette.primary.main};
`

export const HeadCell = styled(TableCell)`
  button {
    border: none;
    background: transparent;

    img {
      margin-left: 9.33px;

      :hover {
        transform: scale(1.2);
      }
    }
  }

  &&.MuiTableCell-head {
    color: ${({ theme }) => theme.palette.white};
    font-size: 1rem;
    font-weight: 600;
    line-height: 24px;
    padding: 16px 11px;

    &:first-child {
      border-top-left-radius: 4px;
    }

    &:last-child {
      border-top-right-radius: 4px;
    }
  }
`

export const HeadCellContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`

export const Row = styled(TableRow)`
  .MuiTableCell-root {
    font-size: 16px;
    padding: 13px;
  }

  .MuiTableCell-root:first-child {
    max-width: ${({ adminup, adminlist }) => (adminup === 'true' ? 'none' : adminlist ? '100px' : '200px')};
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: ${({ adminup, adminlist }) => (adminup === 'true' ? '20px' : adminlist ? '100px' : 'none')};
  }

  &:nth-of-type(odd) {
    background-color: #fafafa;
  }
`

export const RowCell = styled(TableCell)`
  white-space: ${({ column }) => (column === 'Responsável' || column === 'Razão Social' ? 'nowrap' : 'wrap')};

  overflow: ${({ column }) => (column === 'Responsável' || column === 'Razão Social' ? 'hidden' : 'none')};

  text-overflow: ${({ column }) => (column === 'Responsável' || column === 'Razão Social' ? 'ellipsis' : 'none')};
  max-width: ${({ column }) => (column === 'Responsável' || column === 'Razão Social' ? '100px' : '200px')};
`

export const WrapperPagination = styled.div`
  display: ${({ isLoading }) => (isLoading ? 'none' : 'flex')};
  justify-content: center;
  width: 100%;
  margin: 24px 0px;
`

export const PaginationButton = styled.span`
  outline: none;
  width: 24px;
  height: 24px;
  border: none;
  border-radius: 4px;
  margin: 0 5px;
  display: flex;
  font-size: 14px;
  line-height: 24px;
  align-items: center;
  text-align: center;
  justify-content: center;
  cursor: pointer;

  ${({ isSelected, disabled, isLoading, theme: { palette } }) => `
    background: ${isSelected ? palette.primary.main : 'none'};
    color: ${isSelected ? palette.white : palette.gray['800']};
    opacity: ${disabled && !isSelected ? '0.5' : '1'};
    &:disabled {
      cursor: ${isSelected || isLoading ? 'pointer' : 'not-allowed'};
    }
    &:hover {
      background: ${palette.primary.main + (!isSelected ? 15 : '')};
    }
  `}

  &:first-of-type {
    margin-left: 0;
  }

  &:last-of-type {
    margin-right: 0;
  }
`

export const Control = styled.button`
  border: none;
  outline: none;
  width: 24px;
  height: 24px;
  background-color: transparent;
  display: flex;
  align-items: center;
  justify-content: center;
  &:first-of-type {
    margin-right: 8px;
  }
  &:last-of-type {
    margin-left: 8px;
  }
  ${({ disabled, isLoading }) => `
    cursor: ${disabled || isLoading ? 'not-allowed' : 'pointer'};
  `}
`
