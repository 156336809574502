import React from 'react'
import PropTypes from 'prop-types'

import { Container } from './style'

const Badge = ({ color, status, title = '', campaing = false, before = false }) => {
  return (
    <Container color={color} title={title} campaing={campaing} before={before}>
      <label>{status}</label>
    </Container>
  )
}

Badge.propTypes = {
  color: PropTypes.oneOf(['blue', 'red', 'green', 'yellow', 'purple']).isRequired,
  status: PropTypes.string.isRequired
}

export default Badge
