export const nationalityOptions = [
  { value: 'Brasileiro', label: 'Brasileiro' },
  { value: 'Brasileiro Naturalizado', label: 'Brasileiro Naturalizado' },
  { value: 'Estrangeiro', label: 'Estrangeiro' }
]

export const nationalities = {
  BRAZILIAN: 'Brasileiro',
  NATURALIZED_BRAZILIAN: 'Brasileiro Naturalizado',
  FOREIGN: 'Estrangeiro'
}
