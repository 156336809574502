import React from 'react'
import { SvgIcon } from '@material-ui/core'

const Download = props => {
  return (
    <SvgIcon width='24' height='24' viewBox='0 0 24 24' fill='none' {...props}>
      <path
        d='M13.2333 12.8933V13.1348L13.404 12.964L15.9107 10.4574C16.3917 9.97643 17.1683 9.97643 17.6493 10.4574C18.1302 10.9383 18.1302 11.715 17.6493 12.196L12.8626 16.9826C12.3817 17.4636 11.605 17.4636 11.124 16.9826L6.33738 12.196C5.85643 11.715 5.85643 10.9383 6.33738 10.4574C6.81832 9.97643 7.59501 9.97643 8.07596 10.4574L8.07614 10.4576L10.5961 12.9642L10.7667 13.1339V12.8933V1.33333C10.7667 0.655228 11.3219 0.1 12 0.1C12.6781 0.1 13.2333 0.655229 13.2333 1.33333V12.8933ZM21.4333 20V13.3333C21.4333 12.6552 21.9886 12.1 22.6667 12.1C23.3448 12.1 23.9 12.6552 23.9 13.3333V21.3333C23.9 22.7448 22.7448 23.9 21.3333 23.9H2.66667C1.25523 23.9 0.1 22.7448 0.1 21.3333V13.3333C0.1 12.6552 0.655229 12.1 1.33333 12.1C2.01144 12.1 2.56667 12.6552 2.56667 13.3333V20C2.56667 20.7886 3.21144 21.4333 4 21.4333H20C20.7886 21.4333 21.4333 20.7886 21.4333 20Z'
        stroke='white'
        strokeWidth='0.2'
      />
    </SvgIcon>
  )
}

export { Download }
