import client from '../../providers/fetchClient'

export const getCampaigns = params => client.get('/v3/campaigns', { params })

export const getCompanyInfo = async cnpj => {
  try {
    const companyInfo = await client.get(`/v3/bmp/company-info/${cnpj}`)
    if (!companyInfo) throw new Error('Ocorreu um erro ao buscar o CNPJ')
    if (companyInfo.status === 'ERROR') throw new Error(companyInfo.message)
    return companyInfo
  } catch (error) {
    if (error.response) {
      if (error.response.status === 429) {
        throw new Error('Aguarde para realizar nova requisição de CNPJ.')
      } else {
        throw new Error(`Erro ao buscar o CNPJ: ${error.response.message}.`)
      }
    }
    throw new Error(`Erro ao buscar o CNPJ: ${error.message}.`)
  }
}

export const createCampaign = data => client.post('/v3/campaigns', data)

export const getCampaignById = id => client.get(`/v3/campaigns/${id}`)

export const updateCampaign = (id, data) => client.put(`/v3/campaigns/${id}`, data)

export const deleteCampaign = id => client.delete(`/v3/campaigns/${id}`)

export const getCampaignStatuses = () => client.get('/v3/campaign-statuses')

export const getCampaignTypes = () => client.get('/v3/campaign-types')

export const getCampaignUpdates = campaign_id => client.get(`/v3/campaigns/${campaign_id}/campaign-updates`)

export const getCampaignUpdate = id => client.get(`/v3/campaign-updates/${id}`)

export const createCampaignUpdate = data => client.post('/v3/campaign-updates', data)

export const updateCampaignUpdate = (id, data) => client.put(`/v3/campaign-updates/${id}`, data)

export const deleteCampaignUpdate = id => client.delete(`/v3/campaign-updates/${id}`)
