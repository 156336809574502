import client from '../../providers/fetchClient'

export const getCampaignPhotoTypes = () => client.get('/v3/campaign-photos-types')

export const getPhotos = params => client.get('/v3/campaign-photos', { params })

export const createCampaignPhoto = data => client.post('/v3/campaign-photos', data)

export const updateCampaignPhoto = (id, data) => client.put(`/v3/campaign-photos/${id}`, data)

export const deleteCampaignPhoto = id => client.delete(`/v3/campaign-photos/${id}`)
