import styled from 'styled-components'
import LinearProgress from '@material-ui/core/LinearProgress'
import { palette, spacing, typography, borders, flexbox, shadows, sizing } from '@material-ui/system'

export const MaterialLinearProgress = styled(LinearProgress)`
  &&.MuiLinearProgress-root {
    height: ${props => (props.thinbar ? '8px' : '16px')};
    border-radius: 12px;
    ${palette};
  }
  div {
    &.MuiLinearProgress-bar {
      border-radius: 12px;
    }
  }
`
export const ValueBox = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  color: ${({ theme }) => theme.palette.gray['800']};
  ${typography};
`

export const CurrentValue = styled.span`
  position: absolute;
  transform: translateX(-50%);
  left: 50%;
  top: 0px;
  color: ${({ theme }) => theme.palette.white};
  font-size: 12px;
  line-height: 16px;
`

export const BarBox = styled.div`
  flex: 1;
  position: relative;
  align-items: center;
`

export const Container = styled.div`
  ${spacing};
  ${borders};
  ${flexbox};
  ${shadows};
  ${sizing};
`
