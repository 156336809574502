import client from '../../providers/fetchClient'

export const getDebts = id => client.get(`/v3/campaign-debts?campaign_id=${id}`)

export const getDebtsById = id => client.get(`/v3/campaign-debts/${id}`)

export const createDebts = data => client.post('/v3/campaign-debts', data)

export const updateDebts = (id, data) => client.put(`/v3/campaign-debts/${id}`, data)

export const deleteDebts = id => client.delete(`/v3/campaign-debts/${id}`)
