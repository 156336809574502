import React from 'react'
import { SvgIcon } from '@material-ui/core'

const Export = props => {
  return (
    <SvgIcon width='22' height='30' viewBox='0 0 22 30' fill='none' {...props}>
      <path d='M11.0002 0.333328L5.66683 5.66666H9.66683V17.6667H12.3335V5.66666H16.3335L11.0002 0.333328ZM19.0002 29.6667H3.00016C1.52016 29.6667 0.333496 28.4667 0.333496 27V11C0.333496 10.2928 0.614448 9.61447 1.11454 9.11438C1.61464 8.61428 2.29292 8.33333 3.00016 8.33333H7.00016V11H3.00016V27H19.0002V11H15.0002V8.33333H19.0002C19.7074 8.33333 20.3857 8.61428 20.8858 9.11438C21.3859 9.61447 21.6668 10.2928 21.6668 11V27C21.6668 27.7072 21.3859 28.3855 20.8858 28.8856C20.3857 29.3857 19.7074 29.6667 19.0002 29.6667Z' />
    </SvgIcon>
  )
}

export { Export }
