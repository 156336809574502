import styled from 'styled-components'
import { display, positions, sizing, spacing } from '@material-ui/system'
import { lighten, makeStyles } from '@material-ui/core/styles'

import { FormHelperText } from '@material-ui/core'
import { Select as SelectMui, MenuItem } from '@material-ui/core'
import { InputLabel } from '@material-ui/core'

const Container = styled.div`
  ${sizing}
  ${display}
  ${positions}
  ${spacing}
`

const SelectInput = styled(SelectMui)``

const Label = styled(InputLabel)`
  color: ${({ theme }) => theme.palette.gray.main};
  &.MuiInputLabel-shrink {
    text-transform: uppercase;
    font-weight: 600;
    padding: 0px 4px;
    left: -4px;
    background: ${({ theme }) => theme.palette.white};
  }
`

const Item = styled(MenuItem)`
  &&.MuiMenuItem-root {
    font-weight: 400;
    font-size: 16px;
    color: ${({ theme }) => theme.palette.gray[800]};
  }
`

const HelperText = styled(FormHelperText)`
  && {
    margin: 4px 0px 0px 2px;
  }
`

const selectStyles = ({ palette }) => ({
  select: {
    '& svg': {
      color: 'transparent',
      fontSize: 0
    },
    border: `1px solid ${palette.gray[700]}`,
    fontWeight: 400,
    '&:focus': {
      background: palette.white,
      borderRadius: 4
    },
    '&:hover': {
      borderColor: 'transparent'
    },
    paddingTop: 9.5,
    paddingBottom: 9.5
  },
  icon: {},
  paper: {
    marginTop: 4,
    '&::-webkit-scrollbar': {
      width: 4
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: palette.gray.main + '!important',
      borderRadius: 4
    }
  },
  list: {
    paddingTop: 0,
    paddingBottom: 0,
    '& li': {
      background: palette.white,
      display: 'flex',
      justifyContent: 'space-between',
      width: '100%',
      '& svg': {
        color: 'transparent'
      }
    },
    '& li:hover': {
      background: lighten(palette.primary[400], 0.9)
    },
    '& li.Mui-selected': {
      backgroundColor: 'transparent',
      '& svg': {
        color: palette.primary[400]
      }
    },
    '& li.Mui-selected:hover': {
      background: 'transparent'
    }
  }
})

const useSelectStyles = makeStyles(selectStyles, { name: 'SelectStyles' })

export { Container, SelectInput, Label, Item, HelperText, selectStyles, useSelectStyles }
