import client from '../../providers/fetchClient'

export const getCampaignsFinancials = id => client.get(`/v3/campaign-financials?campaign_id=${id}`)

export const getCampaignsFinancialsById = id => client.get(`/v3/campaign-financials/${id}`)

export const createCampaignsFinancials = data => client.post('/v3/campaign-financials', data)

export const updateCampaignsFinancials = (id, data) => client.put(`/v3/campaign-financials/${id}`, data)

export const deleteCampaignsFinancials = id => client.delete(`/v3/campaign-financials/${id}`)
