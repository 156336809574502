import React from 'react'
import PropTypes from 'prop-types'
import { Row } from 'components'

import { H3 } from './styles'

const Title = ({ title, children, ...props }) => {
  return (
    <Row {...props}>
      <H3>{title}</H3>
      {children}
    </Row>
  )
}

Title.propTypes = {
  title: PropTypes.string,
  children: PropTypes.node
}

export default Title
