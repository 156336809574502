import React from 'react'
import { SvgIcon } from '@material-ui/core'

const Calculate = props => {
  return (
    <SvgIcon width='24' height='24' viewBox='0 0 24 24' fill='none' {...props}>
      <path d='M21.3333 0H2.66667C1.2 0 0 1.2 0 2.66667V21.3333C0 22.8 1.2 24 2.66667 24H21.3333C22.8 24 24 22.8 24 21.3333V2.66667C24 1.2 22.8 0 21.3333 0ZM14.08 4.70667C14.4667 4.32 15.1067 4.32 15.4933 4.70667L16.6667 5.88L17.84 4.70667C18.2267 4.32 18.8667 4.32 19.2533 4.70667C19.64 5.09333 19.64 5.73333 19.2533 6.12L18.08 7.29333L19.2533 8.46667C19.64 8.85333 19.64 9.49333 19.2533 9.88C18.8667 10.2667 18.2267 10.2667 17.84 9.88L16.6667 8.72L15.4933 9.89333C15.1067 10.28 14.4667 10.28 14.08 9.89333C13.6933 9.50667 13.6933 8.86667 14.08 8.48L15.2533 7.30667L14.08 6.13333C13.68 5.73333 13.68 5.09333 14.08 4.70667ZM5.33333 6.29333H10C10.5467 6.29333 11 6.74667 11 7.29333C11 7.84 10.5467 8.29333 10 8.29333H5.33333C4.78667 8.29333 4.33333 7.84 4.33333 7.29333C4.33333 6.74667 4.78667 6.29333 5.33333 6.29333ZM10.3333 17.3333H8.66667V19C8.66667 19.5467 8.21333 20 7.66667 20C7.12 20 6.66667 19.5467 6.66667 19V17.3333H5C4.45333 17.3333 4 16.88 4 16.3333C4 15.7867 4.45333 15.3333 5 15.3333H6.66667V13.6667C6.66667 13.12 7.12 12.6667 7.66667 12.6667C8.21333 12.6667 8.66667 13.12 8.66667 13.6667V15.3333H10.3333C10.88 15.3333 11.3333 15.7867 11.3333 16.3333C11.3333 16.88 10.88 17.3333 10.3333 17.3333ZM19 19H14.3333C13.7867 19 13.3333 18.5467 13.3333 18C13.3333 17.4533 13.7867 17 14.3333 17H19C19.5467 17 20 17.4533 20 18C20 18.5467 19.5467 19 19 19ZM19 15.6667H14.3333C13.7867 15.6667 13.3333 15.2133 13.3333 14.6667C13.3333 14.12 13.7867 13.6667 14.3333 13.6667H19C19.5467 13.6667 20 14.12 20 14.6667C20 15.2133 19.5467 15.6667 19 15.6667Z' />
    </SvgIcon>
  )
}

export { Calculate }
