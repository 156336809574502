import styled from 'styled-components'
import { Typography } from '@material-ui/core'
import { palette, typography, spacing, sizing } from '@material-ui/system'

export const MaterialTypography = styled(Typography)`
  &&.MuiTypography-root {
    ${palette};
    ${typography};
    ${spacing};
    ${sizing};
  }
`
