import client from '../../providers/fetchClient'

export const createLead = data => client.post('/v3/leads/site', data)

export const getLeadStatuses = () => client.get('/v3/lead-statuses')

export const createLeadsPartner = data => client.post('/v3/leads/partner', data)

export const getLeadDocuments = lead_id => client.get(`/v3/lead-documents?lead_id=${lead_id}`)

export const getLeadDocumentTypes = () => client.get('/v3/lead-document-types')

export const createLeadDocument = data => client.post('/v3/lead-documents', data)

export const getLeadDocumentUrl = (lead_id, document_id) =>
  client.get(`/v3/leads/${lead_id}/lead-documents/${document_id}`)

export const updateLeads = (id, body) => client.put(`/v3/leads/${id}`, body)
