import axios from 'axios'

const getEnviroment = () => {
  // return 'http://127.0.0.1:8082'

  switch (process.env.NEXT_PUBLIC_NODE_ENV) {
    case 'production':
      return 'https://api-prod.kavodlending.com'
    case 'homolog':
      return 'https://api-hmg.kavodlending.com'
    case 'dev':
    default:
      return 'https://api-dev.kavodlending.com'
  }
}

export const __API__ = getEnviroment()

export const getImagesHome = () => axios.get(__API__ + '/v3/fetch-images-home')
