import styled from 'styled-components'
import { Button as ButtonMaterial } from '@material-ui/core/'
import { palette, spacing, typography, borders, flexbox, shadows, sizing } from '@material-ui/system'

export const ButtonStyled = styled(ButtonMaterial)`
  white-space: nowrap;
  font-size: 16px;
  line-height: 24px;
  font-weight: 600;
  border-radius: 24px !important;

  &&.MuiButton-root {
    text-transform: none;
    box-shadow: none;

    &.Mui-disabled {
      background-color: ${({ theme }) => theme.palette.gray.main};
    }

    ${palette};
    ${spacing};
    ${typography};
    ${borders};
    ${flexbox};
    ${shadows};
    ${sizing};
  }
`

export const Label = styled.span``
