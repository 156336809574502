import styled from 'styled-components'

import { IconDownload as Download } from 'components/icons'
import { Row } from 'components'

export const Container = styled.header`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: fixed;
  z-index: 100;
  background-color: ${({ theme }) => theme.palette.white};

  span {
    text-transform: none;
  }

  box-shadow: 0px 4px 10px rgba(117, 117, 117, 0.2);

  .icon-mobile {
    display: none;
    background: white;
  }

  @media (max-width: 500px) {
    .icon-mobile {
      display: block;
    }
  }
`
export const Menus = styled.div`
  display: flex;

  .button-tablet {
    display: block;
    background: white;
  }

  @media (max-width: 500px) {
    margin-top: 10px;
    justify-content: center;

    .button-tablet {
      display: none;
    }

    button {
      margin-right: 0px !important;
    }
  }
`

export const TotalHeader = styled(Row)`
  background-color: ${({ theme }) => theme.palette.primary.main};
  height: 40px;
  color: ${({ theme }) => theme.palette.white};

  p {
    margin-right: 40px;
    min-width: 212px;
  }

  button {
    position: absolute;
    right: 20px;
  }

  @media (max-width: 990px) {
    p {
      margin-right: 0;
    }
  }

  @media (max-width: 820px) {
    display: none;
  }
`
export const LinkHeader = styled.div`
  padding: 0 87px;
  a {
    text-align: start;
  }

  @media (max-width: 1070px) {
    padding: 0px 50px 0px 10px;
  }

  @media (max-width: 990px) {
    padding: 0px 30px 0px 10px;
  }

  @media (max-width: 820px) {
    display: none;
  }
`

export const ReportIncomeMobile = styled(Row)`
  background-color: ${({ theme }) => theme.palette.primary.main};
  height: ${({ mobile }) => (mobile ? 'auto' : '40px')};
  padding: 0px 24px 5px 24px;

  display: none;
  align-items: ${({ mobile }) => (mobile ? 'flex-start' : 'center')};
  justify-content: center;

  p {
    color: white;
    margin-top: 8px;
  }

  span {
    color: ${({ theme }) => theme.palette.white};
  }

  @media (max-width: 820px) {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;

    .row-menu {
      justify-content: space-between;
      width: 100%;
    }
  }
`

export const IconDownload = styled(Download)`
  &&.MuiSvgIcon-root {
    margin-right: 11px;
    font-size: 16px;
  }
`

export const Image = styled.img`
  cursor: pointer;
`

export const RowMenu = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100vw;
  padding: 16px 40px;

  @media (max-width: 500px) {
    flex-direction: column;

    img {
      align-self: flex-start;
    }
  }
`

export const ContainerIncome = styled.div`
  display: inline;

  @media (max-width: 300px) {
    display: none;
  }
`
