import styled from 'styled-components'

export const Container = styled.div`
  margin: 0 10px;
  display: inline-flex;
`
export const Span = styled.span`
  border: 1px solid #f6b344;
  box-sizing: border-box;
  border-radius: 4px;
  padding: 7px;

  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  color: #f6b344;
`
