import { createGlobalStyle } from 'styled-components'

const GlobalStyle = createGlobalStyle`
  :root {
    --background: #fff;

    --blue: #076F9F;
    --green: #4AB34E;
    --red: #FD4646;
    --yellow: #F6B344;
    --purple: #663CCC;
    --gray: #424242;
    --gray-light: #e0e0e0;
    --black: #212121;
  }

  * {
    border: 0;
    padding: 0;
    margin: 0;
    box-sizing: border-box;
    outline: none;

    font-family: 'Open Sans', sans-serif;
  }

  html {
    font-size: 16px;

    @media (max-width: 1080px) {
      font-size: 93.75%;
    }

    @media (max-width: 720px) {
      font-size: 87.5%;
    }
  }

  body {
    background: var(--background);
    color: var(--black);
    -webkit-font-smoothing: antiliaseg;
  }

  body, input, textarea {
    color: var(--black);
    font-family: 'Open Sans', sans-serif;
  }

  button, a {
    color: var(--gray);
    cursor: pointer;
    &:disabled{
      cursor: not-allowed;
    }
  }

`
export default GlobalStyle
