import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  width: ${({ campaing }) => (campaing ? '120px' : '96px')};
  height: 32px;

  background: #ffffff;

  border-radius: 4px;

  ${({ campaing }) => (campaing ? 'position: relative' : '0')};
  ${({ campaing }) => (campaing ? 'top: 8px' : '0')};
  ${({ campaing }) => (campaing ? 'left: 8px' : '0')};

  label {
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;
    ${({ before }) => (before ? 'color: #000000;' : 'color: #ffffff;')};
  }

  ${({ color }) => (color == 'blue' ? ' background: var(--blue) ' : ' ')}
  ${({ color }) => (color == 'green' ? ' background: var(--green) ' : '')}
  ${({ color }) => (color == 'red' ? ' background: var(--red) ' : ' ')}
  ${({ color }) => (color == 'yellow' ? ' background: var(--yellow)' : ' ')}
  ${({ color }) => (color == 'purple' ? ' background: var(--purple)' : ' ')}
`
