import { Container, Span } from './styles'
import Tooltip from '@material-ui/core/Tooltip'
import { withStyles } from '@material-ui/core/styles'

const CustomTooltip = withStyles(() => ({
  arrow: {
    color: '#F49F14'
  },
  tooltip: {
    backgroundColor: '#F49F14',
    color: '#fafafa',
    fontSize: 12,
    fontWeight: 600,
    padding: 10
  }
}))(Tooltip)

const DelayedFlag = () => {
  return (
    <CustomTooltip title='Valor renegociado' aria-label='Valor renegociado' arrow>
      <Container>
        <Span>R</Span>
      </Container>
    </CustomTooltip>
  )
}

export default DelayedFlag
