import styled from 'styled-components'

import { IconDownload as Download } from 'components/icons'
import { Row } from 'components'

export const MenusDesktop = styled.div``

export const PublicMenus = styled(Row)`
  display: flex;
  align-items: center;

  > a:nth-child(-n + 3) {
    margin-right: 32px;
  }

  button {
    margin-right: 32px;
    padding: 0;
  }
`

export const PrivateMenus = styled.div`
  display: flex;
  align-items: center;
  a {
    margin-right: 32px;
  }

  button {
    padding: 0;
    margin-right: 32px;
  }

  button:nth-last-child(-n + 4) {
    &:last-child {
      margin-right: 0;
    }
  }
`

export const IconDownload = styled(Download)`
  &&.MuiSvgIcon-root {
    margin-right: 11px;
    font-size: 16px;
  }
`
