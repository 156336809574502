import styled from 'styled-components'

export const ValidationBox = styled.span`
  display: grid;
  grid-template-columns: 1fr 1fr;

  li {
    display: flex;
    justify-content: flex-start;
    align-items: center;

    &.validated * {
      color: ${({ theme }) => theme.palette.green[400]};
    }

    svg {
      margin-right: 2px;
    }
  }
`
export const Container = styled.div``
