import React from 'react'
import PropTypes from 'prop-types'

import { MaterialTypography } from './styles'

const Text = ({ variant, ...props }) => {
  return <MaterialTypography variant={variant} {...props} />
}

Text.defaultProps = {
  variant: 'body2'
}

Text.propTypes = {
  variant: PropTypes.oneOf([
    'h1',
    'h2',
    'h3',
    'h4',
    'h5',
    'h6',
    'subtitle1',
    'subtitle2',
    'body1',
    'body2',
    'caption',
    'button',
    'overline',
    'srOnly',
    'inherit',
    'semiBold'
  ])
}

export default Text
