import styled from 'styled-components'
import { palette, spacing, typography, borders, flexbox, shadows, sizing } from '@material-ui/system'

import { Chip as ChipMaterial } from '@material-ui/core'

export const Chip = styled(ChipMaterial)`
  &&.MuiChip-root {
    ${palette};
    border-radius: 4px;
    border: none;
    color: white;
    font-size: 12px;
    line-height: 16px;
    font-weight: 600;
    ${spacing};
    ${typography};
    ${borders};
    ${flexbox};
    ${shadows};
    ${sizing};

    ${props =>
      (props.size === 'medium' &&
        `
      min-width: 120px;
      min-height: 32px;
      `) ||
      (props.size === 'big' &&
        `
      font-size: 16px;
      line-height: 24px;
      min-width: 156px;
      min-height: 40px;
      `)}
  }
`
