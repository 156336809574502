import React, { useEffect } from 'react'
import { NextSeo } from 'next-seo'
import { useRouter } from 'next/router'

import { useUser } from 'context/user-context'

import { Main, Loader } from 'components'

import { Column } from './style'

const Campaigns = ({ header, children, padding = '' }) => {
  const { user } = useUser()
  const router = useRouter()

  useEffect(() => {
    if (user === null) {
      router.push('/login/?return=true')
    }
  }, [user])

  return (
    <Column alignItems='center' width='100%' paddingBottom={padding}>
      <NextSeo title={`Kavod Lending | ${header}`} description='KavodLending | Peer to Peer lending' />

      <Main>{!user ? <Loader /> : children}</Main>
    </Column>
  )
}

export default Campaigns
