import styled from 'styled-components'

import Text from 'components/Text'
import { typography, palette, spacing } from '@material-ui/system'

export const LinkText = styled(Text)`
  ${({ textDecoration }) => `
    text-decoration: ${textDecoration};
    `}

  ${typography}
  ${palette}
  ${spacing}
`
