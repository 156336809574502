import React, { createElement } from 'react'
import Link from 'next/link'
import PropTypes from 'prop-types'
import { useRouter } from 'next/router'

import { LinkText } from './styles'

const EXTERNAL = 'external'
const INTERNAL = 'internal'

const LinkComponent = ({ variant, textVariant, href, target, children, color, activeLinkColor, ...props }) => {
  const router = useRouter()
  const activeLink = router.pathname === href

  const ExternalLink = createElement(
    LinkText,
    {
      ...(variant === EXTERNAL && { href }),
      as: 'a',
      color: activeLink ? activeLinkColor : color,
      passHref: variant === EXTERNAL,
      target: (variant === EXTERNAL && '_blank') || target,
      ...props
    },
    children
  )

  switch (variant) {
    case INTERNAL:
    default:
      return (
        <Link href={href} {...props} passHref>
          {ExternalLink}
        </Link>
      )
    case EXTERNAL:
      return ExternalLink
  }
}

LinkComponent.defaultProps = {
  textDecoration: 'underline',
  variant: INTERNAL,
  color: 'primary.main',
  activeLinkColor: 'primary.main',
  target: '_self'
}

LinkComponent.propTypes = {
  variant: PropTypes.oneOf([EXTERNAL, INTERNAL]),
  textVariant: PropTypes.string,
  children: PropTypes.node,
  href: PropTypes.string,
  textDecoration: PropTypes.oneOf(['underline', 'none']),
  color: PropTypes.string,
  activeLinkColor: PropTypes.string,
  target: PropTypes.oneOf(['_blank', '_self', '_parent', '_top'])
}

export default LinkComponent
