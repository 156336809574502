import client from '../../providers/fetchClient'

export const getAllComplianceAgreementTypes = () => client.get('/v3/compliance-agreement-types')

export const getComplianceAgreementById = id => client.get(`/v3/compliance-agreements/${id}`)

export const getInvestorComplianceAgreements = id => client.get(`/v3/compliance-agreements?investor_id=${id}`)

export const createComplianceAgreement = data => client.post('/v3/compliance-agreements', data)

export const updateComplianceAgreement = (id, data) => client.put(`/v3/compliance-agreements/${id}`, data)
