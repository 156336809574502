import React from 'react'
import { CircularProgress } from '@material-ui/core'

import { Div } from './style'

const Loader = () => (
  <Div>
    <CircularProgress />
  </Div>
)

export default Loader
