import styled from 'styled-components'
import { display, positions, sizing, spacing } from '@material-ui/system'
import { createTheme, lighten } from '@material-ui/core'
import { KeyboardDatePicker } from '@material-ui/pickers'

import { styles } from 'theme'

const { palette } = styles
const customThemeDatePicker = {
  MuiFormControl: {
    root: {
      minWidth: '100%'
    }
  },
  MuiInputBase: {
    root: {
      color: palette.gray[800]
    },
    input: {
      width: '100%'
    }
  },
  MuiOutlinedInput: {
    root: {
      '&&.Mui-focused fieldset': {
        borderColor: palette.primary[400]
      }
    },
    notchedOutline: {
      borderColor: palette.gray[700]
    }
  },
  MuiFormLabel: {
    root: {
      color: palette.gray.main,
      '&.Mui-focused': {
        color: palette.primary[400]
      }
    }
  },
  MuiInputLabel: {
    shrink: {
      color: palette.gray[700],
      textTransform: 'uppercase',
      fontWeight: 600,
      paddingRight: 4,
      background: 'white'
    }
  },
  MuiFormHelperText: {
    root: {
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: 12
    },
    contained: {
      marginLeft: 0
    }
  },
  MuiPickersToolbar: {
    toolbar: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'space-between',
      minHeight: 32,
      maxHeight: 48,
      width: '100%',
      padding: '0 16px',
      backgroundColor: palette.secondary.main
    }
  },
  MuiPickersBasePicker: {
    container: {
      alignItems: 'center'
    },
    pickerView: {
      minWidth: 208,
      maxWidth: 208,
      minHeight: 130
    }
  },
  MuiPickersYear: {
    root: {
      fontStyle: 'normal',
      fontWeight: 'normal',
      fontSize: 16,
      height: 28,
      border: '1px solid transparent',
      borderRadius: 2,
      '&:hover': {
        border: `1px solid ${palette.gray[700]}`,
        boxSizing: 'border-box'
      },
      '&:focus': {
        color: palette.gray[800]
      }
    },
    yearSelected: {
      fontStyle: 'normal',
      fontWeight: 'normal',
      fontSize: 16,
      margin: 0,
      padding: 8,
      background: palette.primary[400],
      color: palette.white,
      borderRadius: 2
    }
  },
  MuiPickersYearSelection: {
    container: {
      display: 'flex',
      justifyContent: 'space-between',
      flexFlow: 'wrap',
      padding: 10,
      maxHeight: 210,
      '&::-webkit-scrollbar': {
        width: 4
      },
      '&::-webkit-scrollbar-thumb': {
        backgroundColor: palette.gray.main,
        borderRadius: 4
      }
    }
  },
  MuiTypography: {
    subtitle1: {
      padding: 8
    },
    body1: {
      fontSize: 14,
      fontWeight: 600,
      color: palette.primary[400]
    },
    body2: {
      fontSize: 16
    }
  },
  MuiPickersCalendar: {
    transitionContainer: {
      marginTop: 0,
      minHeight: 156
    }
  },
  MuiPickersCalendarHeader: {
    switchHeader: {
      marginTop: 0,
      marginBottom: 0
    }
  },
  MuiPickersDay: {
    day: {
      borderRadius: 2,
      width: 24,
      height: 24,

      '&:hover': {
        border: `1px solid ${palette.gray[700]}`,
        boxSizing: 'border-box',
        borderRadius: 2
      }
    },
    daySelected: {
      backgroundColor: palette.primary[400],
      borderRadius: 2,
      '&:hover': {
        backgroundColor: palette.primary[400]
      }
    },
    current: {
      backgroundColor: lighten(palette.primary[400], 0.9),
      color: palette.gray[800]
    }
  },
  MuiIconButton: {
    root: {
      color: palette.gray[800],
      transition: '',
      padding: 8
    }
  }
}

export const Container = styled.div`
  width: 180px;
  ${display}
  ${positions}
  ${sizing}
  ${spacing}
`

export const Picker = styled(KeyboardDatePicker)``

export const desktopTheme = createTheme({ overrides: { ...customThemeDatePicker } })

export const mobileTheme = createTheme({
  overrides: {
    ...customThemeDatePicker,
    MuiDialog: {
      paper: {
        margin: 0
      },
      paperScrollPaper: {
        maxHeight: 385
      }
    },
    MuiPickersBasePicker: {
      container: {
        alignItems: 'center'
      },
      pickerView: {
        minWidth: 328,
        maxWidth: 328,
        minHeight: 285,
        justifyContent: 'start'
      }
    },
    MuiPickersYear: {
      root: {
        fontStyle: 'normal',
        fontWeight: 'normal',
        fontSize: 16,
        height: 28,
        border: '1px solid transparent',
        borderRadius: 2,
        '&:focus': {
          color: palette.gray[800]
        }
      },
      yearSelected: {
        fontStyle: 'normal',
        fontWeight: 'normal',
        fontSize: 16,
        margin: 8,
        padding: 8,
        background: palette.primary[400],
        color: palette.white,
        borderRadius: 2
      }
    },
    MuiPickersYearSelection: {
      container: {
        display: 'flex',
        justifyContent: 'space-between',
        flexFlow: 'wrap',
        padding: 10,
        maxHeight: 285,
        '&::-webkit-scrollbar': {
          width: 4
        },
        '&::-webkit-scrollbar-thumb': {
          backgroundColor: palette.gray.main,
          borderRadius: 4
        }
      }
    },
    MuiTypography: {
      subtitle1: {
        margin: 8
      },
      body1: {
        fontSize: 14,
        fontWeight: 600,
        color: palette.primary[400]
      },
      body2: {
        fontSize: 16
      }
    },
    MuiPickersCalendar: {
      transitionContainer: {
        minHeight: 172
      },
      week: {
        justifyContent: 'space-between',
        margin: '8px 16px'
      }
    },
    MuiPickersCalendarHeader: {
      daysHeader: {
        justifyContent: 'space-between',
        margin: '0 10px'
      }
    },
    MuiButton: {
      root: {
        textTransform: 'capitalize',
        fontWeight: 600,
        fontSize: 14
      },
      textPrimary: {
        color: palette.primary.main
      }
    }
  }
})
