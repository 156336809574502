import styled from 'styled-components'
import {
  FormControlLabel as MaterialFormControlLabel,
  Checkbox as MaterialCheckbox,
  FormControl,
  FormGroup,
  FormLabel
} from '@material-ui/core/'
import { palette, spacing, typography, borders, flexbox, shadows, sizing } from '@material-ui/system'

export const StyledCheckbox = styled(MaterialCheckbox)`
  &&.MuiCheckbox-root {
    color: ${props => (props.viewonly ? props.theme.palette.gray[800] : '')};
  }
`

export const StyledFormControlLabel = styled(MaterialFormControlLabel)`
  .MuiFormControlLabel-label.Mui-disabled {
    color: ${props => (props.viewonly ? props.theme.palette.gray[800] : '')};
  }
  ${palette};
  ${typography};
  ${sizing};
`

export const StyledFormControl = styled(FormControl)`
  ${borders};
  ${flexbox};
  ${sizing};
  ${borders};
  ${spacing};
  ${shadows};
`
