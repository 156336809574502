import React, { createContext, useContext, useState, useEffect } from 'react'

import { getUser } from 'services/auth'
import { getToken } from 'helpers'

const UserContext = createContext()

const useUser = () => {
  const context = useContext(UserContext)

  if (context === undefined) {
    throw new Error('useUser must be used within a UserProvider')
  }

  return context
}

const UserProvider = props => {
  const [user, setUser] = useState(undefined)

  useEffect(() => {
    const token = getToken()

    if (token) {
      getUser()
        .then(response => {
          if (!!response) {
            setUser(response)
          }
        })
        .catch(() => setUser(null))
    } else {
      setUser(null)
    }
  }, [])

  return <UserContext.Provider value={{ user, setUser }} {...props} />
}

export { UserProvider, useUser }
