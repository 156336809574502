import PropTypes from 'prop-types'

import { Button, MenuButton, Link, Text } from 'components'
import { PrivateMenus, PublicMenus, IconDownload, MenusDesktop } from './styles'
import { IconExpandMore } from 'components/icons'

const DesktopHeader = ({
  isLogged,
  userName,
  logout,
  publicMenus,
  privateMenus,
  termsOfUseOptions,
  contactOptions,
  registrationOptions,
  userOptions,
  reportIncomeActivated,
  ReportIncomeDownload
}) => {
  return (
    <MenusDesktop>
      {!isLogged ? (
        <PublicMenus>
          {publicMenus.map(({ label, link }, index) => {
            return (
              index <= 0 && (
                <Link key={label} textAlign='center' textDecoration='none' color='gray.800' href={link}>
                  {label}
                </Link>
              )
            )
          })}
          {/*  <Link
            textAlign='center'
            textDecoration='none'
            color='gray.800'
            href={publicMenus[2].link}
            variant={'internal'}
          >
            {publicMenus[2].label}
          </Link> */}
          {/*    <Link
            textAlign='center'
            textDecoration='none'
            color='gray.800'
            href={publicMenus[3].link}
            variant={publicMenus[3].variant}
          >
            {publicMenus[3].label}
          </Link> */}
          <MenuButton
            fontSize={16}
            menuOptions={termsOfUseOptions}
            label={
              <>
                <Text variant='subtitle1'>Termos de uso</Text> <IconExpandMore />
              </>
            }
          />

          <Link textAlign='center' textDecoration='none' color='gray.800' href='/fale-conosco-info'>
            Contato
          </Link>
          {/*  <MenuButton
            menuOptions={contactOptions}
            fontSize={16}
            label={
              <>
                <Text variant='subtitle1'> Contato</Text> <IconExpandMore size={21} />
              </>
            }
          /> */}
          {/*  <MenuButton
            menuOptions={registrationOptions}
            fontSize={16}
            label={
              <>
                <Text variant='subtitle1'>Cadastre-se</Text> <IconExpandMore size={21} />
              </>
            }
          /> */}

          {/* <Button
            size='small'
            color='secondary'
            fontSize={14}
            fontWeight={600}
            width={143}
            m='0 14px 0 0px'
            style={{ minHeight: '32px' }}
          >
            {isLogged ? (
              <Link textAlign='center' color='white' textDecoration='none' activeLinkColor='white' href='/campanhas'>
                {publicMenus[1].label}
              </Link>
            ) : (
              <Link
                textAlign='center'
                color='white'
                activeLinkColor='white'
                textDecoration='none'
                href={publicMenus[1].link}
              >
                {publicMenus[1].label}
              </Link>
            )}
          </Button>

          <Button
            size='small'
            fontSize={14}
            fontWeight={600}
            width={177}
            m='0 14px 0 14px'
            style={{ minHeight: '32px' }}
          >
            <Link
              textAlign='center'
              color='white'
              activeLinkColor='white'
              textDecoration='none'
              href={publicMenus[1].link}
            >
              {publicMenus[1].label}
            </Link>
          </Button> */}
          <Button
            size='small'
            variant='outlined'
            fontSize={14}
            fontWeight={600}
            marginright='0'
            width={96}
            style={{ minHeight: '32px' }}
          >
            <Link
              textAlign='center'
              textDecoration='none'
              color='#076F9F'
              activeLinkColor='#076F9F'
              href={publicMenus[6].link}
            >
              {publicMenus[6].label}
            </Link>
          </Button>
        </PublicMenus>
      ) : (
        <PrivateMenus>
          {reportIncomeActivated && (
            <Button
              variant='outlined'
              fontSize={14}
              height={32}
              width={233}
              marginright='32px'
              border='2px solid !important'
              onClick={ReportIncomeDownload}
            >
              <IconDownload />
              <Text style={{ fontWeight: 600, fontSize: '14px', lineHeight: '24px' }}>Informe de Rendimentos</Text>
            </Button>
          )}
          {privateMenus.map(({ label, link, variant }, index) => (
            <Link
              key={index}
              textAlign='center'
              textDecoration='none'
              color='gray.800'
              href={link}
              variant={variant || 'internal'}
            >
              {label}
            </Link>
          ))}
          <Link textAlign='center' textDecoration='none' color='gray.800' href='/fale-conosco-info'>
            Contato
          </Link>
          {/*  <MenuButton
            menuOptions={contactOptions}
            fontSize={16}
            label={
              <>
                <Text variant='subtitle1'> Contato </Text> <IconExpandMore />
              </>
            }
          /> */}
          <MenuButton
            fontSize={16}
            menuOptions={userOptions}
            label={
              <>
                <Text variant='subtitle1'> Olá, {userName} </Text> <IconExpandMore />
              </>
            }
          >
            <Button variant='text' fontSize={16} fontWeight={400} onClick={logout} style={{ color: 'var(--gray)' }}>
              Sair
            </Button>
          </MenuButton>
        </PrivateMenus>
      )}
    </MenusDesktop>
  )
}

DesktopHeader.propTypes = {
  isLogged: PropTypes.bool,
  userName: PropTypes.string,
  logout: PropTypes.func,
  publicMenus: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      link: PropTypes.string,
      variant: PropTypes.oneOf(['internal', 'external'])
    })
  ),
  privateMenus: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      link: PropTypes.string,
      variant: PropTypes.oneOf(['internal', 'external'])
    })
  ),
  termsOfUseOptions: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      link: PropTypes.string,
      variant: PropTypes.oneOf(['internal', 'external'])
    })
  ),
  contactOptions: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      link: PropTypes.string,
      variant: PropTypes.oneOf(['internal', 'external'])
    })
  ),
  registrationOptions: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      link: PropTypes.string,
      variant: PropTypes.oneOf(['internal', 'external'])
    })
  ),
  userOptions: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      link: PropTypes.string,
      variant: PropTypes.oneOf(['internal', 'external'])
    })
  ),
  reportIncomeActivated: PropTypes.bool,
  ReportIncomeDownload: PropTypes.func
}

export default DesktopHeader
