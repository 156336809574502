import React from 'react'
import PropTypes from 'prop-types'
import { Menu, withStyles } from '@material-ui/core'
import MenuItem from '@material-ui/core/MenuItem'

import Button from './Button'
import { Link } from 'components'

const MenuContainer = withStyles({
  paper: {
    background: 'white',
    boxShadow: '0px 2px 4px rgba(33, 33, 33, 0.2)',
    borderRadius: ' 4px'
  }
})(props => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'left'
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'left'
    }}
    {...props}
  />
))

const MenuButton = ({ label, color, variant, menuOptions, children, ...props }) => {
  const [anchorEl, setAnchorEl] = React.useState(null)

  const handleClick = event => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  return (
    <>
      <Button
        aria-controls='simple-menu'
        style={{ color: 'var(--gray)' }}
        variant={variant}
        aria-haspopup='true'
        onClick={handleClick}
        {...props}
      >
        {label}
      </Button>
      <MenuContainer id='simple-menu' anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={handleClose}>
        {menuOptions.map(({ label, link, variant }, index) => (
          <MenuItem key={index} onClick={handleClose}>
            <Link textDecoration='none' color='gray.800' href={link} variant={variant || 'internal'}>
              {label}
            </Link>
          </MenuItem>
        ))}

        {children}
      </MenuContainer>
    </>
  )
}

MenuButton.defaultProps = {
  variant: 'text'
}

MenuButton.propTypes = {
  label: PropTypes.node,
  color: PropTypes.string,
  variant: PropTypes.string,
  menuOptions: PropTypes.array,
  children: PropTypes.node,
  variant: PropTypes.oneOf(['contained', 'outlined', 'text'])
}

export default MenuButton
