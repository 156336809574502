import { Container } from './styles'

const PaymentConfirmed = () => {
  return (
    <Container>
      <img src='/img/check.svg' alt='Parcela paga' />
    </Container>
  )
}

export default PaymentConfirmed
