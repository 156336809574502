import styled from 'styled-components'

import { Column } from 'components'

export const MenusTablet = styled.div`
  button {
    padding: 0;
    margin-bottom: 32px;
    text-align: center;
  }

  a {
    margin-bottom: 16px;
  }
`
export const PublicMenus = styled(Column)`
  align-items: center;

  a:nth-child(-n + 3) {
    margin-bottom: 32px;
    font-weight: 600;
  }

  a:nth-last-child(-n + 2) {
    margin: 0;
  }
`

export const PrivateMenus = styled(Column)`
  a:nth-child(-n + 2) {
    margin-bottom: 32px;
    font-weight: 600;
  }
`
