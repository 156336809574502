import React, { useCallback, useMemo, useEffect } from 'react'
import { useTable, usePagination, useGlobalFilter, useSortBy } from 'react-table'
import PropTypes from 'prop-types'
import TableBody from '@material-ui/core/TableBody'
import TableRow from '@material-ui/core/TableRow'

import { GlobalFilter } from 'components'
import { IconChevronLeft, IconChevronRight, IconExpandMore, IconExpandLess } from 'components/icons'

import {
  Head,
  HeadCell,
  ReactTable,
  Row,
  WrapperPagination,
  PaginationButton,
  Control,
  Container,
  RowCell,
  HeadCellContent
} from './styles'

const TableBase = ({
  hasPaginationTop,
  hasPaginationBottom,
  hasFilter,
  widthHidden,
  columns,
  data,
  length,
  children,
  adminup = '',
  adminlist = '',
  ...rest
}) => {
  const tableInstance = useTable(
    { columns, data, initialState: { pageIndex: 0 } },
    useGlobalFilter,
    useSortBy,
    usePagination
  )
  const {
    getTableProps,
    headerGroups,
    prepareRow,
    page,
    pageOptions,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state,
    setGlobalFilter,
    state: { pageIndex }
  } = tableInstance

  const { globalFilter } = state

  const navigation = useMemo(() => {
    try {
      if (pageOptions.length <= 5) {
        return pageOptions
      }

      const index = pageIndex
      const response = []

      let countLeft = 0
      let countRight = 0

      pageOptions.map(page => {
        if (page === index - 1 || page === index - 2) {
          countLeft = countLeft + 1

          response.push(page)
        }

        if (page === index) {
          response.push(page)
        }

        if (page === index + 1 || page === index + 2) {
          countRight = countRight + 1

          response.push(page)
        }
      })

      if (countLeft === 0) {
        response.push(index + 3)
        response.push(index + 4)
      } else if (countLeft === 1) {
        response.push(index + 3)
      }

      if (countRight === 0) {
        response.unshift(index - 3)
        response.unshift(index - 4)
      } else if (countRight === 1) {
        response.unshift(index - 3)
      }

      return response
    } catch (err) {
      return []
    }
  }, [pageIndex, renderPage, pageOptions])

  const renderPage = useCallback(
    page => (
      <PaginationButton
        variant='regular'
        as='button'
        isSelected={pageIndex + 1 === page}
        key={page}
        onClick={() => gotoPage(page - 1)}
      >
        {page}
      </PaginationButton>
    ),
    [pageIndex]
  )

  useEffect(() => {
    setPageSize(length)
  }, [length])

  return (
    <Container hidden={widthHidden}>
      {hasFilter && <GlobalFilter filter={globalFilter} setFilter={setGlobalFilter} />}

      {children}

      {pageOptions.length > 1 && hasPaginationTop && (
        <WrapperPagination justifyContent='center'>
          <Control disabled={pageIndex === 0} onClick={() => previousPage()}>
            <IconChevronLeft style={{ color: '#616161' }} />
          </Control>
          {navigation.map(page => renderPage(page + 1))}
          <Control disabled={pageIndex === pageOptions.length - 1} onClick={() => nextPage()}>
            <IconChevronRight style={{ color: '#616161' }} />
          </Control>
        </WrapperPagination>
      )}

      <ReactTable {...getTableProps()} {...rest}>
        <Head {...rest}>
          {headerGroups.map(headerGroup => (
            <TableRow key={headerGroup} {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map(column => (
                <HeadCell
                  key={column}
                  {...column.getHeaderProps(column.getSortByToggleProps({ title: column.render('Header') }))}
                >
                  <HeadCellContent>
                    {column.render('Header')}

                    {column.isSorted && !column.isSortedDesc && <IconExpandLess />}
                    {column.isSortedDesc && <IconExpandMore />}
                  </HeadCellContent>
                </HeadCell>
              ))}
            </TableRow>
          ))}
        </Head>
        <TableBody>
          {page.map((row, i) => {
            prepareRow(row)
            return (
              <Row {...row.getRowProps()} adminup={adminup} adminlist={adminlist}>
                {row.cells.map(cell => {
                  return (
                    <RowCell title={cell.value} key={cell} {...cell.getCellProps()} column={cell.column.Header}>
                      {cell.render('Cell')}
                    </RowCell>
                  )
                })}
              </Row>
            )
          })}
        </TableBody>
      </ReactTable>

      {pageOptions.length > 1 && hasPaginationBottom && (
        <WrapperPagination justifyContent='center'>
          <Control disabled={pageIndex === 0} onClick={() => previousPage()}>
            <IconChevronLeft style={{ color: '#616161' }} />
          </Control>
          {navigation.map(page => renderPage(page + 1))}
          <Control disabled={pageIndex === pageOptions.length - 1} onClick={() => nextPage()}>
            <IconChevronRight style={{ color: '#616161' }} />
          </Control>
        </WrapperPagination>
      )}
    </Container>
  )
}

TableBase.propTypes = {
  columns: PropTypes.array,
  data: PropTypes.array,
  onClick: PropTypes.func,
  information: PropTypes.bool,
  hasPaginationTop: PropTypes.bool,
  hasPaginationBottom: PropTypes.bool,
  hasFilter: PropTypes.bool
}

export default TableBase
