import React from 'react'
import PropTypes from 'prop-types'

import { ButtonStyled } from './styles'
import { SpinnerLoader } from 'components/Loader'

const Button = ({ children, icon, variant, color, disabled, loader, ...props }) => {
  return (
    <ButtonStyled startIcon={icon} variant={variant} disabled={disabled || loader} color={color} {...props}>
      {loader ? <SpinnerLoader /> : children}
    </ButtonStyled>
  )
}

Button.defaultProps = {
  color: 'primary',
  variant: 'contained'
}

Button.propTypes = {
  type: PropTypes.string,
  disabled: PropTypes.bool,
  icon: PropTypes.any,
  variant: PropTypes.oneOf(['contained', 'outlined', 'text']),
  color: PropTypes.string
}

export default Button
