import styled from 'styled-components'

import { Upload } from '../icons/custom'

export const FileUploadContainer = styled.section`
  position: relative;
  margin: 25px 0 15px;
  border: 2px dotted lightgray;
  border-color: #076f9f;
  padding: 35px 20px;
  border-radius: 6px;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: white;
`

export const FormField = styled.input`
  font-size: 18px;
  display: block;
  width: 100%;
  border: none;
  text-transform: none;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: 0;

  &:focus {
    outline: none;
  }
`

export const DragDropText = styled.p`
  font-weight: bold;
  letter-spacing: 2.2px;
  margin-top: 0;
  text-align: center;
`

export const UploadFileBtn = styled.button`
  box-sizing: border-box;
  appearance: none;
  background-color: transparent;
  border: 1px solid #076f9f;
  cursor: pointer;
  font-family: 'Open Sans', sans-serif;
  font-size: 0.875rem;
  font-weight: 600;
  line-height: 24px;
  padding: 1.1em 2.8em;
  text-align: center;
  text-transform: uppercase;
  font-weight: 700;
  border-radius: 24px;
  color: #076f9f;
  position: relative;
  overflow: hidden;
  z-index: 1;

  width: 100%;
  max-width: 220px;
  height: 40px;
  display: flex;
  align-items: center;
  padding-right: 0;
  justify-content: center;

  margin-top: 15px;

  &:after {
    background: #076f9f;
  }

  svg {
    font-size: 22px;
    margin-right: 5px;
    border-right: 2px solid;
    position: absolute;
    left: 0;
    right: 0;
    width: 20%;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  @media only screen and (max-width: 500px) {
    width: 70%;
  }

  @media only screen and (max-width: 350px) {
    width: 100%;
  }

  &:hover {
    outline: 0;
    background: #586a731a;

    &:after {
      width: 110%;
    }
  }

  &:focus {
    outline: 0;
    background: transparent;
  }

  &:disabled {
    opacity: 0.4;
    filter: grayscale(100%);
    pointer-events: none;
  }
`

export const FilePreviewContainer = styled.article`
  margin-bottom: 35px;

  span {
    font-size: 14px;
  }
`

export const PreviewList = styled.section`
  display: flex;
  flex-wrap: wrap;
  margin-top: 10px;
  flex-direction: column;

  @media only screen and (max-width: 400px) {
    flex-direction: column;
  }
`

export const FileMetaData = styled.div`
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  padding: 10px;
  border-radius: 6px;
  color: white;
  font-weight: bold;
  background-color: rgba(5, 5, 5, 0.55);

  aside {
    margin-top: auto;
    display: flex;
    justify-content: space-between;
  }

  span {
    text-overflow: ellipsis;
    overflow: hidden;
  }
`

export const FileMetaDataError = styled.div`
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  padding: 10px;
  border-radius: 6px;
  color: white;
  font-weight: bold;
  background-color: rgba(219, 43, 43, 0.55);

  aside {
    margin-top: auto;
    display: flex;
    justify-content: space-between;
  }

  span {
    text-overflow: ellipsis;
    overflow: hidden;
  }
`

export const RemoveFileIcon = styled.p`
  cursor: pointer;

  &:hover {
    transform: scale(1.3);
  }
`

export const PreviewContainer = styled.section`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 30px;

  padding: 0.25rem;
  width: 100%;
  height: 80px;
  border-radius: 6px;
  box-sizing: border-box;

  &:hover {
    opacity: 0.55;

    ${FileMetaData} {
      display: flex;
    }
  }

  & > div:first-of-type {
    height: 100%;
    position: relative;

    @media only screen and (max-width: 980px) {
      height: 80px !important;
    }
  }

  .contentPrev {
    width: 100%;

    @media only screen and (max-width: 980px) {
      width: 100%;
    }
  }

  @media only screen and (max-width: 980px) {
    flex-direction: column;
    gap: 10px;
    height: 100%;
  }
`

export const StyledUpload = styled(Upload)`
  color: ${({ theme }) => theme.palette.primary.main};
`
