import React from 'react'
import PropTypes from 'prop-types'

import { Chip } from './styles'

const Status = ({ status, size, customlabel, customcolor, ...props }) => {
  const statusTypes = {
    inCaptation: {
      label: 'Em captação',
      color: 'status.inCaptation'
    },
    inFormalization: {
      label: 'Em formalização',
      color: 'status.inFormalization'
    },
    concluded: {
      label: 'Concluído',
      color: 'status.concluded'
    },
    waitingList: {
      label: 'Lista de espera',
      color: 'status.waitingList'
    },
    defaulting: {
      label: 'Inadimplente',
      color: 'status.defaulting'
    },
    late: {
      label: 'Atrasado',
      color: 'status.late'
    },
    compliant: {
      label: 'Adimplente',
      color: 'status.compliant'
    },
    completed: {
      label: 'Concluída',
      color: 'status.completed'
    }
  }

  const { label, color } = status ? statusTypes[status] : { label: customlabel, color: customcolor }

  return <Chip size={size} label={label} bgcolor={color} {...props} />
}

Status.defaultProps = {
  size: 'medium'
}

Status.propTypes = {
  size: PropTypes.oneOf(['small', 'medium', 'big']),
  status: PropTypes.oneOf([
    'inCaptation',
    'inFormalization',
    'concluded',
    'waitingList',
    'compliant',
    'late',
    'defaulting',
    'custom',
    'completed'
  ]),
  customlabel: PropTypes.string,
  customcolor: PropTypes.string
}

export default Status
