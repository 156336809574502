import { Container, Span } from './styles'
import Tooltip from '@material-ui/core/Tooltip'
import { withStyles } from '@material-ui/core/styles'

const CustomTooltip = withStyles(() => ({
  arrow: {
    color: '#FD4646'
  },
  tooltip: {
    backgroundColor: '#FD4646',
    color: '#fafafa',
    fontSize: 12,
    fontWeight: 600,
    padding: 10
  }
}))(Tooltip)

const ExecutionFlag = ({ title = 'Execução judicial' }) => {
  return (
    <CustomTooltip title={title} aria-label={title} arrow>
      <Container>
        <Span>E</Span>
      </Container>
    </CustomTooltip>
  )
}

export default ExecutionFlag
