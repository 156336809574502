import styled from 'styled-components'

import { Input, NumberInput, Row, Select } from '../../'

export const StyledNumberInput = styled(NumberInput)`
  margin-top: 40px !important;
  margin-right: 24px !important;
  margin-bottom: 0px !important;
  max-width: 282px !important;
  min-width: 200px !important;

  @media (max-width: 730px) {
    max-width: 100% !important;
  }
`
export const StyledComanyNameInput = styled(Input)`
  margin-top: 40px !important;
  margin-bottom: 0px !important;
  max-width: 486px !important;
  min-width: 200px !important;
  width: 100%;

  @media (max-width: 730px) {
    max-width: 100% !important;
  }
`

export const StyledCompanySiteNameInput = styled(Input)`
  margin-top: 40px !important;
  margin-bottom: 0px !important;
  max-width: 384px !important;
  min-width: 200px !important;
  width: 100%;

  @media (max-width: 730px) {
    max-width: 100% !important;
  }
`

export const StyledInput = styled(Input)`
  margin-top: 40px !important;
  margin-right: 24px !important;
  margin-bottom: 0px !important;
  max-width: 344px !important;
  min-width: 200px !important;
  width: 100%;

  @media (max-width: 730px) {
    max-width: 100% !important;
  }
`
export const StyledNumberStretInput = styled(Input)`
  margin-top: 40px !important;
  margin-bottom: 0px !important;
  max-width: 118px !important;
  min-width: 50px !important;
  width: 100%;

  @media (max-width: 730px) {
    max-width: 100% !important;
  }
`
export const StyledComplementInput = styled(Input)`
  margin-top: 40px !important;
  margin-right: 24px !important;
  margin-bottom: 0px !important;
  max-width: 282px !important;
  min-width: 200px !important;
  width: 100%;

  @media (max-width: 730px) {
    max-width: 100% !important;
  }
`
export const StyledMedianInput = styled(Input)`
  margin-top: 40px !important;
  margin-right: 24px !important;
  margin-bottom: 0px !important;
  max-width: 282px !important;
  min-width: 200px !important;
  width: 100%;

  @media (max-width: 730px) {
    max-width: 100% !important;
  }
`

export const StyledAvarageNumberInput = styled(NumberInput)`
  width: 100%;
  margin-bottom: 0px !important;
  margin-right: 24px !important;

  @media (max-width: 730px) {
    margin-right: 0px !important;

    label {
      font-size: inherit;
    }
  }
`
export const ButtonDiv = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;

  @media (max-width: 730px) {
    justify-content: center;

    button {
      width: 100% !important;
      min-width: 200px !important;
      max-width: 328px !important;
    }
  }
`
export const StyledSelect = styled(Select)`
  max-width: 118px !important;
  width: 100%;

  @media (max-width: 730px) {
    max-width: 100% !important;
  }
`

export const StyledFormRow = styled(Row)`
  @media (max-width: 730px) {
    flex-direction: column;
  }
`
