import { useCallback } from 'react'

import { login as authLogin } from 'services/auth'
import { setToken, clearToken, PUBLIC_ROUTES, PRIVATE_ROUTES } from 'helpers'
import { useUser } from 'context/user-context'
import { useRouter } from 'next/router'

export const useAuth = () => {
  const { setUser } = useUser()
  const router = useRouter()

  const login = useCallback(async data => {
    try {
      const response = await authLogin(data)

      if (!!response.token) {
        if (!!response.token) {
          setToken(response.token)
        }

        if (!!response) {
          setUser(response)
          return response
        }
      }
    } catch (error) {
      return Promise.reject(error)
    }
  }, [])

  const logout = useCallback(() => {
    clearToken()
    setUser(null)
    window.location.reload()
  }, [])

  return { login, logout }
}
