import React from 'react'
import { SvgIcon } from '@material-ui/core'

const Task = props => {
  return (
    <SvgIcon
      xlink='http://www.w3.org/1999/xlink'
      width='20pt'
      height='23pt'
      viewBox='0 0 20 23'
      version='1.1'
      {...props}
    >
      <g id='surface1'>
        <path d='M 12.222656 0.546875 L 3.332031 0.546875 C 2.113281 0.546875 1.121094 1.535156 1.121094 2.738281 L 1.113281 20.261719 C 1.113281 21.46875 2.101562 22.453125 3.324219 22.453125 L 16.667969 22.453125 C 17.890625 22.453125 18.890625 21.46875 18.890625 20.261719 L 18.890625 7.121094 Z M 8.824219 18.074219 L 4.890625 14.195312 L 6.457031 12.652344 L 8.8125 14.972656 L 13.523438 10.328125 L 15.089844 11.875 Z M 11.113281 8.214844 L 11.113281 2.191406 L 17.222656 8.214844 Z M 11.113281 8.214844 ' />
      </g>
    </SvgIcon>
  )
}

export { Task }
