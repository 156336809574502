import styled from 'styled-components'

export const Container = styled.button`
  margin-left: 20px;

  div {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  img {
    margin: 0;
    padding: 0;
  }

  span {
    color: var(--blue);
    font-size: 14px;
    font-weight: 600;
    margin-left: 8px;
  }
`
