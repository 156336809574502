const { makeStyles } = require('@material-ui/core')

export const useStyles = makeStyles(theme => ({
  root: {
    position: 'relative'
  },
  top: {
    color: theme.palette.white
  },
  circle: {
    strokeLinecap: 'round'
  }
}))
