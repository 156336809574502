import styled from 'styled-components'

import { Text, Input, NumberInput, Row } from '../'

export const FormText = styled(Text)`
  font-weight: 600 !important;
  font-size: 20px !important;
  line-height: 32px !important;
  margin-bottom: 32px !important;
`
export const StyledFormLeftInput = styled(Input)`
  margin-top: 40px !important;
  max-width: 384px;
  margin-right: 24px !important;
  margin-bottom: 0px !important;

  @media (max-width: 730px) {
    max-width: 100% !important;
    margin-right: 0px !important;
  }
`

export const StyledFormRigthInput = styled(Input)`
  max-width: 384px;
  margin-right: 0px !important;
  margin-bottom: 0px !important;

  @media (max-width: 730px) {
    max-width: 100% !important;
  }
`

export const StyledNumberInput = styled(NumberInput)`
  margin-top: 40px !important;
  max-width: 384px;
  margin-right: 24px !important;
  margin-bottom: 0px !important;

  @media (max-width: 730px) {
    max-width: 100% !important;
    margin-right: 0px !important;
  }
`

export const StyledPhoneNumberInput = styled(NumberInput)`
  margin-top: 40px !important;
  max-width: 384px;
  margin-right: 0px !important;
  margin-bottom: 0px !important;

  @media (max-width: 730px) {
    max-width: 100% !important;
  }
`

export const StyledFormRow = styled(Row)`
  @media (max-width: 730px) {
    flex-direction: column;
  }
`
