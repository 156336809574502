import React, { createRef } from 'react'
import PropTypes from 'prop-types'
import { Controller } from 'react-hook-form'
import { ThemeProvider } from '@material-ui/styles'

import { Container, Picker, desktopTheme, mobileTheme } from './styles'
import CustomToolbar from './CustomToolbar'
import { IconCalendar } from 'components/icons'

const DatePicker = ({ name, control, defaultValue, ...props }) => {
  const id = props.id || props.name || 'picker'

  const ref = createRef(null)

  return (
    <Container {...props} ref={ref}>
      <ThemeProvider theme={true ? mobileTheme : desktopTheme}>
        <Controller
          as={
            <Picker
              autoOk
              inputVariant='outlined'
              variant='inline'
              format='dd/MM/yyyy'
              id={id}
              cancelLabel='Cancelar'
              okLabel='Confirmar'
              invalidDateMessage='Insira uma data válida'
              ToolbarComponent={CustomToolbar}
              {...props}
              InputLabelProps={{
                shrink: true
              }}
              keyboardIcon={<IconCalendar />}
            />
          }
          defaultValue={defaultValue}
          control={control}
          name={name}
        />
      </ThemeProvider>
    </Container>
  )
}

DatePicker.propTypes = {
  label: PropTypes.string,
  helperText: PropTypes.string,
  placeholder: PropTypes.string,
  disabled: PropTypes.bool,
  error: PropTypes.bool,
  name: PropTypes.string,
  size: PropTypes.string,
  defaultValue: PropTypes.string
}

DatePicker.defaultProps = {
  size: 'small',
  placeholder: 'dd/mm/aaaa',
  defaultValue: null
}

export default DatePicker
