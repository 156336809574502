import styled from 'styled-components'
import { palette, spacing, typography, borders, flexbox, shadows, sizing } from '@material-ui/system'
import { Button as ButtonMaterial } from '@material-ui/core/'

export const ButtonStyled = styled(ButtonMaterial)`
  &&.MuiButton-root {
    padding: 0px;
    min-width: 0% !important;
  }
  width: 40px;
  height: 40px;
  font-size: 12px;
  line-height: 24px;
  font-weight: 600;
  border-radius: 24px !important;
  padding: 0px !important;
  ${palette}
  ${spacing}
  ${typography}
  ${borders}
  ${flexbox}
  ${shadows}
  ${sizing}
`
