import React from 'react'
import PropTypes from 'prop-types'
import { Controller } from 'react-hook-form'
import { FormHelperText } from '@material-ui/core'

import { StyledCheckbox, StyledFormControlLabel, StyledFormControl } from './styles'

const Checkbox = ({
  defaultValue,
  control,
  label,
  color,
  name,
  disabled,
  checked,
  checkedIcon,
  viewonly,
  error,
  options,
  variant,
  column,
  helperText,
  value,
  ...props
}) => {
  return (
    <StyledFormControl variant={variant || 'outlined'} error={Boolean(error)} {...props}>
      <Controller
        render={props => (
          <StyledFormControlLabel
            control={
              <StyledCheckbox
                name={name}
                color={color}
                disabled={disabled || viewonly}
                viewonly={viewonly}
                checked={checked}
                onChange={event => props.onChange(event.target.checked)}
              />
            }
            label={label}
            viewonly={viewonly}
            error={error}
            {...props}
          />
        )}
        name={name}
        control={control}
        defaultValue={defaultValue}
      />
      {(helperText || error) && <FormHelperText>{helperText || error.message}</FormHelperText>}
    </StyledFormControl>
  )
}

Checkbox.defaultProps = {
  color: 'primary',
  defaultValue: false,
  error: {}
}

Checkbox.propTypes = {
  error: PropTypes.object,
  disabled: PropTypes.bool,
  checked: PropTypes.bool,
  viewOnly: PropTypes.bool,
  label: PropTypes.any,
  name: PropTypes.string,
  color: PropTypes.oneOf(['primary', 'secondary']),
  options: PropTypes.arrayOf(
    PropTypes.exact({
      name: PropTypes.string,
      label: PropTypes.string
    })
  )
}

export default Checkbox
