import client from 'providers/fetchClient'
import { formatCPFToNumber } from 'helpers'

export const getUser = () => client.get('/v3/me')

export const login = async ({ document_number, password }) => {
  try {
    const response = await client.post('/v3/users/login', {
      document_number: formatCPFToNumber(document_number),
      password
    })

    if (!!response) {
      const user = response
      let nameUser = 'User'

      if (!!user) {
        if (!!user.investor) {
          nameUser = user.investor.first_name
        } else if (!!user.partner) {
          nameUser = user.partner.first_name
        }
      }

      const rep = {
        ...user,
        name: nameUser
      }

      return rep
    }
  } catch (error) {}
}
export const forgetPassword = ({ document_number }) =>
  client.post('/v3/users/forget', {
    document_number: formatCPFToNumber(document_number)
  })

export const resetPassword = data => client.post('/v3/users/reset', data)
