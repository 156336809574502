import { useState } from 'react'
import PropTypes from 'prop-types'

import { Button, Link } from 'components'
import { IconExpandLess, IconExpandMore } from 'components/icons'
import { MenusTablet, PrivateMenus, PublicMenus } from './styles'

const TabletHeader = ({
  isLogged,
  userName,
  logout,
  publicMenus,
  privateMenus,
  termsOfUseOptions,
  contactOptions,
  registrationOptions,
  userOptions,
  onClick
}) => {
  const [contactMenuVisibility, setContactMenuVisibility] = useState(false)
  const [registerMenuVisibility, setRegisterMenuVisibility] = useState(false)
  const [userMenuVisibility, setUserMenuVisibility] = useState(false)
  const [termsOfUseVisibility, setTermsOfUseVisibility] = useState(false)

  const handleClickShowContactMenu = () => {
    setContactMenuVisibility(!contactMenuVisibility)
  }

  const handleClickShowRegisterMenu = () => {
    setRegisterMenuVisibility(!registerMenuVisibility)
  }

  const handleClickShowTermsOfUseMenu = () => {
    setTermsOfUseVisibility(!termsOfUseVisibility)
  }

  const handleClickShowUserMenu = () => {
    setUserMenuVisibility(!userMenuVisibility)
  }

  return (
    <MenusTablet>
      {!isLogged ? (
        <PublicMenus height='calc(100vh - 72px)'>
          {publicMenus.map(
            ({ label, link }, index) =>
              index <= 0 && (
                <Link
                  key={index}
                  textAlign='center'
                  textDecoration='none'
                  color='gray.800'
                  href={link}
                  onClick={onClick}
                >
                  {label}
                </Link>
              )
          )}

          {/*   <Link textAlign='center' textDecoration='none' color='gray.800' href={publicMenus[2].link} onClick={onClick}>
            {publicMenus[2].label}
          </Link>
          <Link
            textAlign='center'
            textDecoration='none'
            color='gray.800'
            href={publicMenus[3].link}
            onClick={onClick}
            variant={publicMenus[3].variant}
          >
            {publicMenus[3].label}
          </Link> */}
          <Button
            variant='text'
            style={{ color: 'var(--gray)' }}
            marginbottom={termsOfUseVisibility ? '24px' : '32px'}
            fontSize={16}
            onClick={handleClickShowTermsOfUseMenu}
          >
            Termos de uso {termsOfUseVisibility ? <IconExpandLess /> : <IconExpandMore />}
          </Button>
          {termsOfUseVisibility && (
            <>
              {termsOfUseOptions.map(({ label, link, variant }, index) => (
                <Link
                  key={index}
                  textAlign='center'
                  textDecoration='none'
                  color='gray.800'
                  href={link}
                  variant={variant || 'internal'}
                  onClick={onClick}
                >
                  {label}
                </Link>
              ))}
            </>
          )}

          <Link
            style={{ marginBottom: '15px' }}
            textAlign='center'
            textDecoration='none'
            color='gray.800'
            href='/fale-conosco-info'
            onClick={onClick}
          >
            Contato
          </Link>

          {/* <Button
            variant='text'
            style={{ color: 'var(--gray)' }}
            marginbottom={contactMenuVisibility ? '24px' : '32px'}
            margintop={termsOfUseVisibility ? '16px' : null}
            fontSize={16}
            onClick={handleClickShowContactMenu}
          >
            Contato {contactMenuVisibility ? <IconExpandLess /> : <IconExpandMore />}
          </Button>
          {contactMenuVisibility && (
            <>
              {contactOptions.map(({ label, link }, _) => (
                <Link
                  key={label}
                  textAlign='center'
                  textDecoration='none'
                  color='gray.800'
                  href={link}
                  onClick={onClick}
                >
                  {label}
                </Link>
              ))}
            </>
          )} */}
          {/*  <Button
            variant='text'
            style={{ color: 'var(--gray)' }}
            marginbottom={registerMenuVisibility ? '24px' : '32px'}
            margintop={contactMenuVisibility ? '16px' : null}
            fontSize={16}
            onClick={handleClickShowRegisterMenu}
          >
            Cadastre-se {registerMenuVisibility ? <IconExpandLess /> : <IconExpandMore />}
          </Button> */}
          {/*    {registerMenuVisibility && (
            <>
              {registrationOptions.map(({ label, link }, _) => (
                <Link
                  key={label}
                  textAlign='center'
                  textDecoration='none'
                  color='gray.800'
                  href={link}
                  onClick={onClick}
                >
                  {label}
                </Link>
              ))}
            </>
          )} */}
          {/*  <Button
            size='small'
            color='secondary'
            fontSize={14}
            fontWeight={600}
            width={177}
            mb='24px !important'
            style={{ minHeight: '32px' }}
          >
            {isLogged ? (
              <Link textAlign='center' color='white' textDecoration='none' activeLinkColor='white' href='/campanhas'>
                {publicMenus[4].label}
              </Link>
            ) : (
              <Link
                textAlign='center'
                color='white'
                activeLinkColor='white'
                textDecoration='none'
                href={publicMenus[4].link}
              >
                {publicMenus[4].label}
              </Link>
            )}
          </Button>

          <Button
            size='small'
            margintop='16px'
            mb='24px !important'
            fontSize={14}
            fontWeight={600}
            width={177}
            onClick={onClick}
            style={{ minHeight: '32px' }}
          >
            <Link
              textAlign='center'
              activeLinkColor='white'
              color='white'
              textDecoration='none'
              href={publicMenus[5].link}
            >
              {publicMenus[5].label}
            </Link>
          </Button> */}
          <Button
            size='small'
            variant='outlined'
            fontSize={14}
            fontWeight={600}
            width={177}
            onClick={onClick}
            style={{ minHeight: '32px' }}
          >
            <Link
              textAlign='center'
              textDecoration='none'
              color='#076F9F'
              activeLinkColor='#076F9F'
              href={publicMenus[6].link}
            >
              {publicMenus[6].label}
            </Link>
          </Button>
        </PublicMenus>
      ) : (
        <PrivateMenus height='calc(100vh - 72px)'>
          {privateMenus.map(({ label, link, variant }, index) => (
            <Link
              style={{ fontWeight: '600' }}
              key={index}
              textAlign='center'
              textDecoration='none'
              color='gray.800'
              href={link}
              onClick={onClick}
              variant={variant || 'internal'}
            >
              {label}
            </Link>
          ))}
          <Link
            style={{ marginBottom: '15px' }}
            textAlign='center'
            textDecoration='none'
            color='gray.800'
            href='/fale-conosco-info'
            onClick={onClick}
          >
            Contato
          </Link>
          {/*  <Button
            variant='text'
            style={{ color: 'var(--gray)' }}
            marginbottom={contactMenuVisibility ? '24px' : '32px'}
            fontSize={16}
            onClick={handleClickShowContactMenu}
          >
            Contato {contactMenuVisibility ? <IconExpandLess /> : <IconExpandMore />}
          </Button>
          {contactMenuVisibility && (
            <>
              {contactOptions.map(({ label, link }, _) => (
                <Link
                  key={label}
                  textAlign='center'
                  textDecoration='none'
                  color='gray.800'
                  href={link}
                  onClick={onClick}
                >
                  {label}
                </Link>
              ))}
            </>
          )} */}
          <Button
            variant='text'
            style={{ color: 'var(--gray)' }}
            marginbottom={userMenuVisibility ? '24px' : '32px'}
            margintop={contactMenuVisibility ? '16px' : null}
            fontSize={16}
            onClick={handleClickShowUserMenu}
          >
            Olá, {userName} {userMenuVisibility ? <IconExpandLess /> : <IconExpandMore />}
          </Button>
          {userMenuVisibility && (
            <>
              {userOptions.map(({ label, link }, _) => (
                <Link
                  key={label}
                  textAlign='center'
                  textDecoration='none'
                  color='gray.800'
                  href={link}
                  onClick={onClick}
                >
                  {label}
                </Link>
              ))}
              <Button variant='text' style={{ color: 'var(--gray)' }} fontSize={16} fontWeight={400} onClick={logout}>
                Sair
              </Button>
            </>
          )}
        </PrivateMenus>
      )}
    </MenusTablet>
  )
}

TabletHeader.propTypes = {
  isLogged: PropTypes.bool,
  userName: PropTypes.string,
  logout: PropTypes.func,
  publicMenus: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      link: PropTypes.string,
      variant: PropTypes.oneOf(['internal', 'external'])
    })
  ),
  privateMenus: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      link: PropTypes.string,
      variant: PropTypes.oneOf(['internal', 'external'])
    })
  ),
  termsOfUseOptions: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      link: PropTypes.string,
      variant: PropTypes.oneOf(['internal', 'external'])
    })
  ),
  contactOptions: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      link: PropTypes.string,
      variant: PropTypes.oneOf(['internal', 'external'])
    })
  ),
  registrationOptions: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      link: PropTypes.string,
      variant: PropTypes.oneOf(['internal', 'external'])
    })
  ),
  userOptions: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      link: PropTypes.string,
      variant: PropTypes.oneOf(['internal', 'external'])
    })
  )
}

export default TabletHeader
