import styled from 'styled-components'
import { lighten } from '@material-ui/core/styles'
import { display, positions, sizing, spacing } from '@material-ui/system'

import { Autocomplete } from '@material-ui/lab'
import { Chip } from '@material-ui/core'

export const Container = styled.div`
  ${display}
  ${positions}
  ${sizing}
  ${spacing}
`

export const SelectInput = styled(({ className, ...props }) => (
  <Autocomplete {...props} classes={{ paper: className }} />
))`
  div,
  ul {
    &::-webkit-scrollbar {
      width: 4px;
    }

    &::-webkit-scrollbar-thumb {
      background-color: ${props => props.theme.palette.gray.main};
      border-radius: 4px;
    }
  }

  li {
    display: flex;
    justify-content: space-between;
    width: 100%;

    & svg {
      color: transparent;
    }

    &.MuiAutocomplete-option[aria-selected='true'] {
      background-color: transparent;
      & svg {
        color: ${props => props.theme.palette.primary[400]};
      }
    }

    &.MuiAutocomplete-option[data-focus='true'] {
      background-color: ${props => lighten(props.theme.palette.primary[400], 0.9)};
    }
  }
`
export const Tag = styled(Chip)`
  margin-right: 8px;

  &&.MuiChip-root {
    background: ${props => props.theme.palette.primary[400]};
    border-radius: 4px;
    color: ${props => props.theme.palette.white};
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;
  }
`
